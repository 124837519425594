import {
  Badge,
  Button,
  Select,
  BlockStack,
  Text,
  InlineStack,
} from "@shopify/polaris";
import { DeleteIcon } from "@shopify/polaris-icons";
import { MultiSelect } from "~/components";

export const MultiChoiceRule = ({
  id,
  type,
  index,
  options,
  steps,
  removeRule,
  selectedOptions,
  selectedStep,
  setSelectedOptions,
  setSelectedStep,
}) => {
  let label = "";
  let badge = null as any;
  if (type === "exact") {
    label = "If this exact combination of answers is selected";
    badge = <Badge tone="success">Exact</Badge>;
  } else if (type === "open") {
    label = "If all these answers and possibly more are selected";
    badge = <Badge tone="info">Open</Badge>;
  } else if (type === "broad") {
    label = "If at least one of these answers is selected";
    badge = <Badge tone="warning">Broad</Badge>;
  }

  return (
    <BlockStack gap="100" inlineAlign="stretch" align="start">
      <InlineStack gap="100" align="space-between" blockAlign="center">
        <InlineStack gap="200" align="start" blockAlign="center">
          <Text variant="bodyMd" as="h4">
            Rule {index}.
          </Text>
          {badge}
        </InlineStack>
        <Button
          size="slim"
          variant="secondary"
          icon={DeleteIcon as any}
          tone="critical"
          onClick={() => removeRule(id)}
        >
          Remove
        </Button>
      </InlineStack>
      <InlineStack
        align="space-between"
        gap="100"
        blockAlign="stretch"
        wrap={false}
      >
        <div style={{ flex: "1 1 50%" }}>
          <MultiSelect
            options={options.map((option) => ({
              label: option.label,
              value: option.id,
            }))}
            label={label}
            selected={selectedOptions ?? []}
            setSelected={setSelectedOptions}
          />
        </div>
        <div style={{ flex: "1 1 50%" }}>
          <Select
            label="Then jump to this step"
            labelHidden={true}
            options={steps}
            placeholder="Then jump to this step"
            value={selectedStep ?? ""}
            onChange={setSelectedStep}
          />
        </div>
      </InlineStack>
    </BlockStack>
  );
};
