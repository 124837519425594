import { ReactNode } from "react";
import { Banner, Button, List } from "@shopify/polaris";
import { Box } from "@storyofams/react-ui";

interface ErrorBannerProps {
  error: any;
  /** @default 'An error has occured' */
  title?: string;
  /** handles error message list */
  messages?: ReactNode[];
}

export const ErrorBanner = ({
  error,
  title = "An error has occured",
  messages: errorMessage = [],
}: ErrorBannerProps) => {
  if (!error) {
    return null;
  }

  const messages = errorMessage.length
    ? errorMessage
    : error?.response?.errors?.[0]?.extensions?.exception?.response?.message;

  return (
    <Box width="100%" mb={3}>
      <Banner title={title} tone="critical">
        <div style={{ marginBottom: "10px " }}>
          {Array.isArray(messages) && messages?.length > 1 ? (
            <List type="bullet">
              {messages?.map((message, i) => (
                <List.Item key={i}>{message}</List.Item>
              ))}
            </List>
          ) : Array.isArray(messages) ? (
            messages?.[0]
          ) : (
            messages
          )}
        </div>
        <p style={{ marginBottom: "10px " }}>
          To make the required changes please click on the icon next to each
          error (if available) or click the button below to contact support.
        </p>
        <div style={{ marginBottom: "10px " }}>
          <Button
            onClick={() => {
              window?.top?.frames?.["app-iframe"]?.postMessage(
                { action: "openSupport" },
                "*"
              );
            }}
          >
            Contact us to get help
          </Button>
        </div>
      </Banner>
    </Box>
  );
};
