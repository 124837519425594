import React from "react";
import { Button, IconSource, Tooltip } from "@shopify/polaris";
import { XCircleIcon } from "@shopify/polaris-icons";
import { Box, SystemProps } from "@storyofams/react-ui";
import { pick, omit } from "@styled-system/props";
import { rgba } from "polished";
import styled, { css } from "styled-components";
import { isDescendant } from "~/lib";

export interface OptionProps {
  children: any;
  editorRef?: any;
  isActive: boolean;
  onDelete(): void;
  isTransparent?: boolean;
}

const Wrapper = styled(Box).attrs<
  Pick<OptionProps, "isActive" | "isTransparent">
>((attrs) => ({
  flexDirection: "row",
  ...attrs,
}))<Pick<OptionProps, "isActive" | "isTransparent">>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => (p.isTransparent ? "transparent" : "white")};
  border: 1px solid;
  border-color: ${(p) => p.theme.colors.black20};
  border-radius: ${(p) => p.theme.radii.md};
  transition: background-color 0.18s ease-in-out, border 0.18s ease-in-out,
    box-shadow 0.18s ease-in-out;
  cursor: pointer;
  position: relative;

  > .remove {
    display: none;
  }

  .drag-handle {
    opacity: 0;
  }

  &:hover,
  &:focus,
  &:focus-within {
    > .remove {
      display: block;
    }

    .drag-handle {
      opacity: 1;
    }
  }

  ${(p) =>
    !p.isActive &&
    css`
      &:hover,
      &:focus-within {
        &,
        .guided-selling__check {
          border-color: ${(p) => p.theme.colors.black60};
        }
      }

      &:focus,
      &:active,
      &:focus-within {
        box-shadow: 0px 0px 0px 3px ${(p) => rgba(p.theme.colors.primary, 0.4)};
      }
    `}

  ${(p) =>
    p.isActive &&
    css`
      && {
        background-color: ${p.theme.colors.black4};
        border-color: ${p.theme.colors.black90};
        border-width: 2px;
      }
    `}
`;

export const Option = ({
  children,
  onDelete,
  editorRef,
  ...props
}: OptionProps & SystemProps) => {
  return (
    <Wrapper
      isActive={props.isActive}
      isTransparent={props.isTransparent}
      {...pick(props)}
      tabIndex={0}
      className="answer-option-wrapper"
      onClick={(e) => {
        if (
          editorRef?.current &&
          !isDescendant(e.target, "PolarisPortalsContainer") &&
          !isDescendant(e.target, "description-input", "class")
        ) {
          editorRef.current.focus();
        }
      }}
    >
      <Box
        className="remove"
        position="absolute"
        top="-12px"
        right="-22px"
        width="32px"
        height="32px"
        zIndex={2}
        css={{
          ".Polaris-Button--iconOnly": {
            padding: 0,
          },
          ".Polaris-Icon": {
            width: "32px",
            height: "32px",

            svg: {
              backgroundColor: "white",
              borderRadius: "50%",
            },
          },
        }}
      >
        <Tooltip content="Delete option" dismissOnMouseOut>
          <Button
            icon={XCircleIcon as IconSource}
            variant="plain"
            onClick={onDelete}
          />
        </Tooltip>
      </Box>

      {React.cloneElement(children, { ...omit(props), editorRef })}
    </Wrapper>
  );
};
