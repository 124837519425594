import { Layout } from "@shopify/polaris";
import { ProductSearch } from "./ProductSearch";
import { useFlow } from "~/hooks";
import { AnswerWeight } from "./AnswerWeight";

export const FlowNodeCard = () => {
  const { flowNode, updateFlowNode } = useFlow();

  const saveWeight = async ({ idx, weight }) => {
    if (!flowNode) {
      return;
    }

    updateFlowNode(flowNode?.id, {
      options: flowNode?.options?.map((prevOpt, index) => {
        const opt = { ...prevOpt };
        if (idx === index) {
          opt.weight = weight;
        }
        return opt;
      }),
    });
  };

  const save = async ({
    idx,
    productIds,
    variantIds,
    collectionIds,
    isExcluded,
    matchAllExcluded,
    matchAll,
  }) => {
    if (!flowNode) {
      return;
    }

    updateFlowNode(flowNode?.id, {
      options: flowNode?.options?.map((prevOpt, index) => {
        const opt = { ...prevOpt };
        if (index === idx) {
          if (isExcluded) {
            opt.excludedProductIds = productIds;
            opt.excludedVariantIds = variantIds;
            opt.excludedCollectionIds = collectionIds ?? [];
            opt.matchAllExcluded = matchAllExcluded;
          } else {
            opt.productIds = productIds;
            opt.variantIds = variantIds ?? [];
            opt.collectionIds = collectionIds ?? [];
            opt.matchAll = matchAll;
          }
        }
        return opt;
      }),
    });
  };

  return (
    <>
      {flowNode?.options?.map((option, index) => {
        return (
          <div style={{ marginBottom: 18 }} key={index}>
            <h1 style={{ fontSize: 16, marginBottom: 10 }}>{option?.label}</h1>
            <Layout>
              <Layout.Section variant="oneHalf">
                <ProductSearch
                  index={index}
                  productIds={option.productIds}
                  variantIds={option?.variantIds ?? []}
                  collectionIds={option?.collectionIds ?? []}
                  isExcluded={false}
                  matchAllExcluded={option?.matchAllExcluded ?? false}
                  matchAll={option?.matchAll}
                  onSave={({
                    productIds,
                    variantIds,
                    collectionIds,
                    matchAll,
                    matchAllExcluded,
                  }) =>
                    save({
                      idx: index,
                      productIds,
                      variantIds,
                      collectionIds,
                      isExcluded: false,
                      matchAll,
                      matchAllExcluded,
                    })
                  }
                />
              </Layout.Section>
              <Layout.Section variant="oneHalf">
                <ProductSearch
                  index={index}
                  productIds={option.excludedProductIds}
                  variantIds={option.excludedVariantIds}
                  collectionIds={option?.excludedCollectionIds ?? []}
                  isExcluded={true}
                  matchAllExcluded={option?.matchAllExcluded ?? false}
                  matchAll={option?.matchAll}
                  onSave={({
                    productIds,
                    variantIds,
                    collectionIds,
                    matchAll,
                    matchAllExcluded,
                  }) =>
                    save({
                      idx: index,
                      productIds,
                      variantIds,
                      collectionIds,
                      isExcluded: true,
                      matchAll,
                      matchAllExcluded,
                    })
                  }
                />
              </Layout.Section>
            </Layout>
            <Layout>
              <Layout.Section variant="oneHalf">
                <div style={{ marginTop: "15px" }}>
                  <AnswerWeight
                    weight={option?.weight ?? 100}
                    onSave={(weight) => saveWeight({ idx: index, weight })}
                  />
                </div>
              </Layout.Section>
              <Layout.Section variant="oneHalf"></Layout.Section>
            </Layout>
          </div>
        );
      })}
    </>
  );
};
