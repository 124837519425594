import { useCallback, useEffect, useState } from "react";
import { DropZone, Banner, Button, Spinner, Link } from "@shopify/polaris";
import { Flex, Box, css } from "@storyofams/react-ui";
import { useQueryClient } from "react-query";
import { useParams } from "react-router";
import styled from "styled-components";

import { ErrorBanner } from "~/components/ErrorBanner";
import { File } from "~/graphql/sdk";
import { useSdk } from "~/hooks";
import { getFileUrl } from "~/lib";

const Img = styled.img<{ type?: "not-transparent" | "white-bg" }>`
  width: 100%;
  height: ${(p) => (p.type === "white-bg" ? "210px" : "180px")};
  margin: ${(p) =>
    p.type === "white-bg"
      ? `${p.theme.space[3]}px ${p.theme.space[3]}px 0`
      : 0};
  object-fit: contain;
  object-position: center;
`;

interface FileUploadProps {
  flowId: string;
  label: string;
  src?: File;
}

export const FileUpload = ({ flowId, label, src }: FileUploadProps) => {
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const sdk = useSdk();
  const [isBusy, setBusy] = useState(false);
  const [file, setFile] = useState<any>();
  const [error, setError] = useState<any>(null);
  const [rejectedFile, setRejectedFile] = useState<any>();

  const hasError = !!rejectedFile;

  const removeLogo = async () => {
    if (isBusy) {
      return;
    }

    setBusy(true);

    try {
      await sdk.uploadLogoForFlow({
        input: { flowId, logo: null },
      });

      setFile(undefined);

      queryClient.invalidateQueries(["container", { id }]);
    } catch (e) {
      setError(e);
    }

    setBusy(false);
  };

  const uploadFile = async (file) => {
    if (isBusy) {
      return;
    }

    setBusy(true);

    try {
      await sdk.uploadLogoForFlow({
        input: { flowId, logo: file },
      });

      setFile(window.URL.createObjectURL(file));

      queryClient.invalidateQueries(["container", { id }]);
    } catch (e) {
      setError(e);
    }

    setBusy(false);
  };

  const handleDrop = useCallback(
    (_droppedFiles, acceptedFiles, rejectedFiles) => {
      uploadFile(acceptedFiles?.[0]);
      setRejectedFile(rejectedFiles?.[0]);
    },
    []
  );

  useEffect(() => () => {
    if (file) {
      window.URL.revokeObjectURL(file);
    }
  });

  const fileUpload = !file && !src && <DropZone.FileUpload />;

  const uploadedFiles = (
    <>
      <Box
        position="relative"
        css={{
          "&:hover, &:focus": {
            ".change-button": {
              opacity: 1,
            },
          },
        }}
      >
        <Img src={file || getFileUrl(src)} alt={label} />

        <Flex
          position="absolute"
          alignItems="center"
          justifyContent="center"
          top={0}
          right={0}
          left={0}
          bottom={0}
          opacity={0}
          className="change-button"
          transition="opacity 0.2s ease-out"
          bg="black40"
        >
          <Button>Change image</Button>
        </Flex>
      </Box>
    </>
  );

  const errorMessage = hasError && (
    <Banner title="The image couldn’t be uploaded:" tone="critical">
      {`"${rejectedFile.name}" is not supported. File type must be .jpg or .png.`}
    </Banner>
  );

  return (
    <Box
      width="100%"
      height="100%"
      position="relative"
      css={css({
        img: { ml: "auto", mr: "auto" },
      })}
    >
      {errorMessage}
      <ErrorBanner error={error} title="Error uploading image:" />

      <DropZone
        accept="image/*"
        type="image"
        onDrop={handleDrop}
        allowMultiple={false}
        variableHeight
      >
        {(!!src || !!file) && uploadedFiles}
        {fileUpload}
      </DropZone>

      {(!!src || !!file) && (
        <Link onClick={() => removeLogo()}>Remove Logo</Link>
      )}

      {isBusy && (
        <Flex
          position="absolute"
          alignItems="center"
          justifyContent="center"
          top={0}
          right={0}
          left={0}
          bottom={0}
          bg="white60"
        >
          <Spinner />
        </Flex>
      )}
    </Box>
  );
};
