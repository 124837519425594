import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./RichTextEditor.css";
import { useRef, useCallback, useState, useEffect } from "react";
import {
  Button,
  Collapsible,
  FormLayout,
  Icon,
  Modal,
  Select,
  TextField,
  Tooltip,
} from "@shopify/polaris";
import { QuestionCircleIcon } from "@shopify/polaris-icons";

const CustomButtonBlot = Quill.import("blots/block/embed");
class CustomButton extends CustomButtonBlot {
  static create(value) {
    const node = super.create(value);

    const background = value.background?.length ? value.background : "#000";
    const color = value.color?.length ? value.color : "#fff";
    const width = value.width?.length ? value.width : "fit-content";
    const verticalPadding = value.verticalPadding?.length
      ? value.verticalPadding
      : "16px";
    const horizontalPadding = value.horizontalPadding?.length
      ? value.horizontalPadding
      : "24px";
    const fontSize = value.fontSize?.length ? value.fontSize : "16px";
    const display = value.display?.length ? value.display : "block";
    const verticalMargin = value.verticalMargin?.length
      ? value.verticalMargin
      : "10px";
    const horizontalMargin = value.horizontalMargin?.length
      ? value.horizontalMargin
      : "auto";
    const link = value.link?.length ? value.link : "void(0)";

    node.setAttribute(
      "style",
      `background-color: ${background}; color: ${color}; display: ${display}; margin: ${verticalMargin} ${horizontalMargin}; width: ${width};padding: ${verticalPadding} ${horizontalPadding}; font-size: ${fontSize}`
    );

    if (link.startsWith("https://")) {
      node.setAttribute("href", link);
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener noreferrer");
    } else {
      node.setAttribute("onClick", link);
    }

    node.setAttribute("data-background", background);
    node.setAttribute("data-color", color);
    node.setAttribute("data-width", width);
    node.setAttribute("data-verticalpadding", verticalPadding);
    node.setAttribute("data-horizontalpadding", horizontalPadding);
    node.setAttribute("data-fontsize", fontSize);
    node.setAttribute("data-display", display);
    node.setAttribute("data-verticalMargin", verticalMargin);
    node.setAttribute("data-horizontalMargin", horizontalMargin);
    node.setAttribute("data-link", link);

    node.textContent = value.text;
    return node;
  }

  static value(node) {
    return {
      text: node.textContent ?? "",
      background: node.getAttribute("data-background") ?? "#000",
      color: node.getAttribute("data-color") ?? "#fff",
      width: node.getAttribute("data-width") ?? "fit-content",
      verticalPadding: node.getAttribute("data-verticalpadding") ?? "16px",
      horizontalPadding: node.getAttribute("data-horizontalpadding") ?? "24px",
      fontSize: node.getAttribute("data-fontsize") ?? "16px",
      display: node.getAttribute("data-display") ?? "block",
      verticalMargin: node.getAttribute("data-verticalmargin") ?? "10px",
      horizontalMargin: node.getAttribute("data-horizontalmargin") ?? "auto",
      link: node.getAttribute("data-link") ?? "void(0)",
    };
  }
}
CustomButton.blotName = "customButton";
CustomButton.tagName = "a"; // It's a link, so using 'a' tag.
CustomButton.className = "ql-button";
Quill.register(CustomButton);

const fontSizes = [
  "8px",
  "9px",
  "10px",
  "12px",
  "14px",
  "16px",
  "20px",
  "24px",
  "32px",
  "42px",
  "54px",
  "68px",
  "84px",
  "98px",
];

const Size = Quill.import("attributors/style/size");
Size.whitelist = fontSizes;
Quill.register(Size, true);

const StarButton = () => <span>★</span>;

const CustomToolbar = ({ variantId }) => (
  <div id={`toolbar-${variantId}`}>
    <button className="ql-bold" />
    <button className="ql-italic" />
    <button className="ql-underline" />
    <button className="ql-strike" />
    <button className="ql-link" />
    <button className="ql-video" />
    <button className="ql-list" value="ordered" />
    <button className="ql-list" value="bullet" />
    <button className="ql-indent" value="-1" />
    <button className="ql-indent" value="+1" />
    <button className="ql-header" value="1" />
    <button className="ql-header" value="2" />
    <select
      className="ql-header"
      defaultValue={""}
      onChange={(e) => e.persist()}
    >
      <option value="1" />
      <option value="2" />
      <option value="3" />
      <option value="4" />
      <option value="5" />
      <option value="6" />
      <option value="" />
    </select>
    <select className="ql-size" defaultValue="">
      <option value="8px" />
      <option value="9px" />
      <option value="10px" />
      <option value="12px" />
      <option value="14px" />
      <option value="16px" />
      <option value="20px" />
      <option value="24px" />
      <option value="32px" />
      <option value="42px" />
      <option value="54px" />
      <option value="68px" />
      <option value="84px" />
      <option value="98px" />
      <option value="" />
    </select>
    <select className="ql-color" defaultValue={""}>
      <option value="#000000" />
      <option value="#FFFFFF" />
      <option value="#D3D3D3" />
      <option value="#808080" />
      <option value="#000080" />
      <option value="#FF0000" />
      <option value="#00FF00" />
      <option value="#800080" />
      <option value="#FFC0CB" />
      <option value="#556B2F" />
      <option value="#87CEEB" />
      <option value="#F8DE7E" />
      <option value="#FF4500" />
      <option value="#00FFFF" />
      <option value="#4169E1" />
      <option value="#DC143C" />
      <option value="#228B22" />
      <option value="#F08080" />
      <option value="#6A5ACD" />
      <option value="#DAA520" />
      <option value="#48D1CC" />
    </select>
    <select className="ql-background" defaultValue={""}>
      <option value="#000000" />
      <option value="#FFFFFF" />
      <option value="#D3D3D3" />
      <option value="#808080" />
      <option value="#000080" />
      <option value="#FF0000" />
      <option value="#00FF00" />
      <option value="#800080" />
      <option value="#FFC0CB" />
      <option value="#556B2F" />
      <option value="#87CEEB" />
      <option value="#F8DE7E" />
      <option value="#FF4500" />
      <option value="#00FFFF" />
      <option value="#4169E1" />
      <option value="#DC143C" />
      <option value="#228B22" />
      <option value="#F08080" />
      <option value="#6A5ACD" />
      <option value="#DAA520" />
      <option value="#48D1CC" />
    </select>
    <button className="ql-insertStar">
      <StarButton />
    </button>
    <button className="ql-insertCustomButton">
      <svg
        fill="#1fb266"
        width="16px"
        height="16px"
        viewBox="0 0 52.00 52.00"
        xmlns="http://www.w3.org/2000/svg"
        stroke="#1fb266"
        transform="rotate(0)"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#CCCCCC"
          strokeWidth="0.20800000000000002"
        ></g>
        <g id="SVGRepo_iconCarrier">
          {" "}
          <path d="M31.9981689,11.9995104 C33.4659424,11.9985117 34.998291,13.1328 34.998291,16.1348 L34.998291,16.1348 L34.998291,26 C34.998291,27.5134277 36.3779053,28.1114014 36.9779053,28.3114014 L36.9779053,28.3114014 L43.8,30.8 C46.7,31.9 48.5,35 47.7,38.2 L47.7,38.2 L44.5,48.5995 C44.3,49.3995 43.6,49.9995 42.7,49.9995 L42.7,49.9995 L26.6,49.9995 C25.8,49.9995 25.1,49.5995 24.8,48.8995 C20.9318685,39.9190553 18.7869873,34.9395752 18.3653564,33.9610596 C17.9437256,32.9825439 18.2219401,32.1955241 19.2,31.6 C21,30.3 23.7,31.6395508 24.8,33.5395508 L24.8,33.5395508 L26.4157715,35.7431828 C27.0515137,36.9508 29,36.9508 29,35.1508 L29,35.1508 L29,16.1348 C29,13.1328 30.5303955,12.0005117 31.9981689,11.9995104 Z M46,2 C48.2,2 50,3.8 50,6 L50,6 L50,21 C50,22.882323 48.1813389,25.0030348 46,25 L46,25 L40.010437,25 C39,25 39,24.1881157 39,24.059082 L39,15.5 C39,11.6547018 37.0187988,8 32,8 C26.9812012,8 25,11.1879783 25,15.5 L25,15.5 L25,24.059082 C25,24.4078007 24.7352295,25 23.987793,25 L23.987793,25 L6,25 C3.8,25 2,23.2 2,21 L2,21 L2,6 C2,3.8 3.8,2 6,2 L6,2 Z"></path>{" "}
        </g>
      </svg>
    </button>
    <button className="ql-align" value="" />
    <button className="ql-align" value="center" />
    <button className="ql-align" value="right" />
    <button className="ql-align" value="justify" />
    <button className="ql-clean" />
  </div>
);

const RichTextEditor = ({ value, setValue, variantId }) => {
  const quillRef = useRef<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [showOptionalSettings, setShowOptionalSettings] = useState(false);
  const [text, setText] = useState("");
  const [background, setBackground] = useState("");
  const [color, setColor] = useState("");
  const [link, setLink] = useState("");
  const [width, setWidth] = useState("");
  const [verticalPadding, setVerticalPadding] = useState("");
  const [horizontalPadding, setHorizontalPadding] = useState("");
  const [fontSize, setFontSize] = useState("");
  const [display, setDisplay] = useState("");
  const [verticalMargin, setVerticalMargin] = useState("");
  const [horizontalMargin, setHorizontalMargin] = useState("");

  const insertStar = useCallback(() => {
    const quillInstance = quillRef.current?.getEditor();
    if (quillInstance) {
      const range = quillInstance.getSelection();
      if (range) {
        quillInstance.insertText(range.index, "★");
        setTimeout(() => quillInstance.setSelection(range.index + 1, 0), 1);
      }
    }
  }, [quillRef]);

  const createCustomButton = () => {
    const quillInstance = quillRef.current?.getEditor();

    if (quillInstance) {
      quillInstance.focus();
      const range = quillInstance.getSelection();
      if (text) {
        quillInstance.insertEmbed(range?.index ?? 0, "customButton", {
          text,
          background,
          color,
          link,
          width,
          verticalPadding,
          horizontalPadding,
          fontSize,
          display,
          verticalMargin,
          horizontalMargin,
        });
        setTimeout(
          () => quillInstance.setSelection((range?.index ?? 0) + 1, 0),
          1
        );
      }
    }
  };

  const insertCustomButton = useCallback(() => {
    setShowModal(true);
  }, [quillRef]);

  const [modules, setModules] = useState({
    toolbar: {
      container: `#toolbar-${variantId}`,
      handlers: {
        insertStar: insertStar,
        insertCustomButton: insertCustomButton,
      },
    },
    clipboard: {
      matchVisual: false,
    },
  });

  useEffect(() => {
    if (!quillRef.current) return;
    setModules({
      toolbar: {
        container: `#toolbar-${variantId}`,
        handlers: {
          insertStar: insertStar,
          insertCustomButton: insertCustomButton,
        },
      },
      clipboard: {
        matchVisual: false,
      },
    });
  }, [quillRef, insertStar, insertCustomButton]);

  useEffect(() => {
    // Get the Quill instance once the component is mounted
    const quillInstance = quillRef.current?.getEditor();

    if (quillInstance) {
      // Override the default paste handler
      quillInstance.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        // Check if the delta contains any image operations
        const ops = delta.ops || [];
        const filteredOps = ops.filter(
          (op) =>
            !(op.insert && typeof op.insert === "object" && op.insert.image)
        );

        // Return delta with images removed
        delta.ops = filteredOps;
        return delta;
      });

      // Prevent drop events
      const quillContainer = quillInstance.container;

      const preventImageDrop = (e: DragEvent) => {
        const hasFiles =
          e.dataTransfer &&
          e.dataTransfer.files &&
          e.dataTransfer.files.length > 0;

        if (hasFiles) {
          const containsImages = Array.from(e?.dataTransfer?.files ?? []).some(
            (file: File) => file.type.startsWith("image/")
          );

          if (containsImages) {
            e.preventDefault();
            e.stopPropagation();
          }
        }
      };

      quillContainer.addEventListener(
        "drop",
        preventImageDrop as EventListener,
        true
      );

      // Cleanup listener on component unmount
      return () => {
        quillContainer.removeEventListener(
          "drop",
          preventImageDrop as EventListener,
          true
        );
      };
    }

    // Add a return statement for when quillInstance is undefined
    return undefined;
  }, [quillRef]);

  return (
    <div className={`text-editor-${variantId}`}>
      <CustomToolbar variantId={variantId} />
      <ReactQuill
        ref={quillRef}
        value={value}
        onChange={setValue}
        theme="snow"
        placeholder="Type your message for the quiz takers here..."
        modules={modules}
        formats={[
          "header",
          "font",
          "size",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "indent",
          "link",
          // "image",
          "video",
          "color",
          "background",
          "align",
          "customButton",
        ]}
        bounds={`#Editor-Container-Variant-${variantId}`}
      />
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        title="Insert Custom Button"
        primaryAction={{
          content: "Insert",
          disabled: !text.length || !link.length,
          onAction: () => {
            createCustomButton();
            setShowModal(false);
          },
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => setShowModal(false),
          },
        ]}
      >
        <Modal.Section>
          <FormLayout>
            <FormLayout.Group>
              <TextField
                label={
                  <div style={{ display: "flex", gap: "5px" }}>
                    <span>Button Text</span>
                    <Tooltip content="This is the text that will appear on the button.">
                      <Icon source={QuestionCircleIcon as any} tone="base" />
                    </Tooltip>
                  </div>
                }
                value={text}
                onChange={setText}
                placeholder="e.g. View All Products"
                type="text"
                autoComplete="off"
              />
              <TextField
                label={
                  <div style={{ display: "flex", gap: "5px" }}>
                    <span>Button Link</span>
                    <Tooltip content="URL (or Javascript function) that will be triggered when the button is clicked.">
                      <Icon source={QuestionCircleIcon as any} tone="base" />
                    </Tooltip>
                  </div>
                }
                value={link}
                onChange={setLink}
                placeholder="e.g. https://store.com/collections/all"
                type="text"
                autoComplete="off"
              />
            </FormLayout.Group>
            <br />
            <Button
              onClick={() => {
                setShowOptionalSettings((prev) => !prev);
              }}
              variant="plain"
            >
              Optional Style Settings
            </Button>
            <Collapsible
              open={showOptionalSettings}
              id="optionalSettings"
              transition={{ duration: "150ms", timingFunction: "ease-in-out" }}
            >
              <FormLayout.Group>
                <TextField
                  label="Button Background Color"
                  value={background}
                  onChange={setBackground}
                  placeholder="e.g. #000000"
                  type="text"
                  autoComplete="off"
                />
                <TextField
                  label="Button Text Color"
                  value={color}
                  onChange={setColor}
                  placeholder="e.g. #FFFFFF"
                  type="text"
                  autoComplete="off"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  label="Button Width"
                  value={width}
                  onChange={setWidth}
                  placeholder="e.g. 100px"
                  type="text"
                  autoComplete="off"
                />
                <TextField
                  label="Font Size"
                  value={fontSize}
                  onChange={setFontSize}
                  placeholder="e.g. 16px"
                  type="text"
                  autoComplete="off"
                />
                <TextField
                  label="Vertical Padding"
                  value={verticalPadding}
                  onChange={setVerticalPadding}
                  placeholder="e.g. 16px"
                  type="text"
                  autoComplete="off"
                />
                <TextField
                  label="Horizontal Padding"
                  value={horizontalPadding}
                  onChange={setHorizontalPadding}
                  placeholder="e.g. 24px"
                  type="text"
                  autoComplete="off"
                />
                <TextField
                  label="Vertical Margin"
                  value={verticalMargin}
                  onChange={setVerticalMargin}
                  placeholder="e.g. 10px"
                  type="text"
                  autoComplete="off"
                />
                <TextField
                  label={
                    <div style={{ display: "flex", gap: "5px" }}>
                      <span>Horizontal Margin</span>
                      <Tooltip content="Set to 'auto' to center the button or choose a value such as '10px' to splace out multiple buttons">
                        <Icon source={QuestionCircleIcon as any} tone="base" />
                      </Tooltip>
                    </div>
                  }
                  value={horizontalMargin}
                  onChange={setHorizontalMargin}
                  placeholder="e.g. auto"
                  type="text"
                  autoComplete="off"
                />
                <Select
                  label={
                    <div style={{ display: "flex", gap: "5px" }}>
                      <span>Button Display</span>
                      <Tooltip content="Choose Inline to display multiple buttons on the same line. Choose Block to display each button on its own line.">
                        <Icon source={QuestionCircleIcon as any} tone="base" />
                      </Tooltip>
                    </div>
                  }
                  options={[
                    { label: "Block", value: "block" },
                    { label: "Inline", value: "inline" },
                  ]}
                  value={display}
                  onChange={setDisplay}
                />
              </FormLayout.Group>
            </Collapsible>
          </FormLayout>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default RichTextEditor;
