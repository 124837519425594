import { useEffect, useState } from "react";
import styled from "styled-components";
import { BlockStack, Button, IconSource } from "@shopify/polaris";
import {
  CircleDownIcon,
  CircleUpIcon,
  DeleteIcon,
  EditIcon,
} from "@shopify/polaris-icons";

interface ContentBlockContainerProps {
  desktopWidth?: string;
  mobileWidth?: string;
}

interface ContentContainerProps {
  imagePosition?: string;
}

interface ImageContainerProps {
  imageWidth?: string;
}

function copyToClipboard(text) {
  return new Promise((resolve) => {
    // Try the Clipboard API first (modern approach)
    navigator.clipboard
      .writeText(text)
      .then(() => {
        resolve(true);
      })
      .catch(() => {
        // Fall back to execCommand
        try {
          // Create temporary element
          const textArea = document.createElement("textarea");
          textArea.value = text;

          // Make it invisible
          textArea.style.position = "fixed";
          textArea.style.left = "-999999px";
          textArea.style.top = "-999999px";

          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();

          // Execute copy command
          const success = document.execCommand("copy");
          document.body.removeChild(textArea);

          resolve(success);
        } catch (err) {
          console.warn("Copy failed:", err);
          resolve(false);
        }
      });
  });
}

const DiscountComponent = ({ discount }) => {
  const [isClicked, setIsClicked] = useState(false);

  // after 2 seconds, reset the isClicked state
  useEffect(() => {
    if (isClicked) {
      const timer = setTimeout(() => {
        setIsClicked(false);
      }, 2000);
      return () => clearTimeout(timer);
    } else {
      return;
    }
  }, [isClicked]);

  return (
    <DiscountBadge
      className="discount-code-badge"
      backgroundColor={discount?.backgroundColor}
      borderColor={discount?.borderColor}
      textColor={discount?.textColor}
      fontSize={discount?.fontSize}
      isClicked={isClicked}
      onClick={() => {
        setIsClicked(true);
        copyToClipboard(discount?.code);
      }}
    >
      {discount?.code}
    </DiscountBadge>
  );
};

const QuestionContentBlocksList = ({
  contentBlocks,
  update,
  setContentBlockId,
  location,
  openContentBlockModal,
}) => {
  const [loading, setLoading] = useState(false);

  if (!contentBlocks?.[location]?.length) {
    return null;
  }

  const edit = (id) => {
    setContentBlockId(id);
    openContentBlockModal(location);
  };

  const moveUp = async (id) => {
    if (id && contentBlocks?.[location]?.length) {
      setLoading(true);
      const blockIndex = contentBlocks?.[location]?.findIndex(
        (cb) => cb?.id && cb.id === id
      );
      if (blockIndex > 0) {
        const newContentBlocks = [...contentBlocks?.[location]];
        const block = newContentBlocks[blockIndex];
        newContentBlocks[blockIndex] = newContentBlocks[blockIndex - 1];
        newContentBlocks[blockIndex - 1] = block;
        await update({
          ...contentBlocks,
          [location]: newContentBlocks,
        });
      }
      setLoading(false);
    }
  };

  const moveDown = async (id) => {
    if (id && contentBlocks?.[location]?.length) {
      setLoading(true);
      const blockIndex = contentBlocks?.[location]?.findIndex(
        (cb) => cb?.id && cb.id === id
      );
      if (blockIndex < contentBlocks?.[location]?.length - 1) {
        const newContentBlocks = [...contentBlocks?.[location]];
        const block = newContentBlocks[blockIndex];
        newContentBlocks[blockIndex] = newContentBlocks[blockIndex + 1];
        newContentBlocks[blockIndex + 1] = block;
        await update({
          ...contentBlocks,
          [location]: newContentBlocks,
        });
      }
      setLoading(false);
    }
  };

  const deleteBlock = async (id) => {
    if (id && contentBlocks?.[location]?.length) {
      setLoading(true);
      if (confirm("Are you sure you want to delete this content block?")) {
        await update({
          ...contentBlocks,
          [location]: contentBlocks?.[location]?.filter(
            (cb) => cb?.id && cb.id !== id
          ),
        });
      }
      setLoading(false);
    }
  };

  return (
    <ListContainer>
      {contentBlocks?.[location]
        ?.filter((cb) => cb?.id)
        ?.map((contentBlock, idx) => {
          const image = contentBlock?.block?.image;
          const discount = contentBlock?.block?.discount;
          const upload = contentBlock?.block?.upload;

          return (
            <ContentBlockContainer
              key={contentBlock.id}
              desktopWidth={contentBlock?.settings?.desktopWidth}
              mobileWidth={contentBlock?.settings?.mobileWidth}
            >
              <BlockStack gap="200">
                {discount?.active &&
                  discount?.display !== false &&
                  discount?.code &&
                  discount?.position === "top" && (
                    <DiscountComponent discount={discount} />
                  )}
                <ContentContainer imagePosition={image?.position}>
                  {image?.showImage && image?.url && (
                    <ImageContainer imageWidth={image?.width}>
                      <img src={contentBlock?.block?.image?.url} />
                    </ImageContainer>
                  )}
                  <ContentBlockContent
                    dangerouslySetInnerHTML={{
                      __html: `${contentBlock?.block?.content ?? ""}${
                        contentBlock?.rawHTML ?? ""
                      }`,
                    }}
                  />
                </ContentContainer>
                {discount?.active &&
                  discount?.display !== false &&
                  discount?.code &&
                  discount?.position !== "top" && (
                    <DiscountComponent discount={discount} />
                  )}
                {upload?.active && (
                  <iframe
                    frameBorder="0"
                    scrolling="no"
                    style={{
                      border: "none",
                      padding: "0",
                      margin: "20px auto",
                      display: "block",
                      width: "600px",
                      height: "400px",
                    }}
                    width="600"
                    height="400"
                    src={`https://traian.dev/lantern/multi-upload/${
                      upload?.language ?? "en"
                    }/?maxFiles=${upload?.maxFiles ?? 20}`}
                  />
                )}
              </BlockStack>
              <ContentBlockActions>
                {idx !== 0 && (
                  <Button
                    size="slim"
                    onClick={() => moveUp(contentBlock.id)}
                    icon={CircleUpIcon as IconSource}
                    loading={loading}
                  />
                )}
                {idx !== contentBlocks?.[location]?.length - 1 && (
                  <Button
                    size="slim"
                    onClick={() => moveDown(contentBlock.id)}
                    icon={CircleDownIcon as IconSource}
                    loading={loading}
                  />
                )}
                <Button
                  icon={DeleteIcon as IconSource}
                  variant="plain"
                  size="slim"
                  onClick={() => deleteBlock(contentBlock.id)}
                  tone="critical"
                  loading={loading}
                />
                <Button
                  icon={EditIcon as IconSource}
                  size="slim"
                  onClick={() => edit(contentBlock.id)}
                  variant="primary"
                  loading={loading}
                />
              </ContentBlockActions>
            </ContentBlockContainer>
          );
        })}
    </ListContainer>
  );
};

export default QuestionContentBlocksList;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 40px auto 24px auto;
`;

const ContentBlockContainer = styled.div<ContentBlockContainerProps>`
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.3;
  padding: 10px;
  margin: 16px auto;
  width: ${(props) => props.desktopWidth ?? "900px"};
  max-width: 100%;
  @media (max-width: 768px) {
    width: ${(props) => props.mobileWidth ?? "100%"};
  }

  & * {
    text-align: left;
  }

  & ul,
  & ol {
    list-style-position: inside;
  }

  & .ql-align-right {
    text-align: right;
  }
  & .ql-align-center {
    text-align: center;
  }
  & .ql-align-left {
    text-align: left;
  }

  & h1 {
    font-size: 32px;
  }

  & h2 {
    font-size: 24px;
  }

  & h3 {
    font-size: 18px;
  }

  & h4 {
    font-size: 16px;
  }

  & h5 {
    font-size: 13px;
  }

  & h6 {
    font-size: 10px;
  }

  & a {
    color: #007ace;
    text-decoration: underline;
  }

  & .ql-indent-1 {
    padding-left: 3em;
  }

  & .ql-indent-2 {
    padding-left: 6em;
  }

  & .ql-indent-3 {
    padding-left: 9em;
  }

  & .ql-indent-4 {
    padding-left: 12em;
  }

  & .ql-indent-5 {
    padding-left: 15em;
  }

  & .ql-indent-6 {
    padding-left: 18em;
  }
`;

const ContentBlockContent = styled.div`
  padding: 16px;
  flex: 1 1 0;
  width: 100%;
`;

const ContentBlockActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px;
  gap: 8px;
  border-top: 1px solid #ccc;
  width: 100%;
  font-size: 12px;

  & span {
    flex-grow: 1;
  }
`;

const ContentContainer = styled.div<ContentContainerProps>`
  display: flex;
  flex-direction: ${(props) =>
    props?.imagePosition === "left"
      ? "row"
      : props?.imagePosition === "right"
      ? "row-reverse"
      : props?.imagePosition === "top"
      ? "column"
      : "column-reverse"};
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: ${(props) =>
      props?.imagePosition === "top" ? "column" : "column-reverse"};
  }
`;

const ImageContainer = styled.div<ImageContainerProps>`
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props?.imageWidth ?? "100%"};
  & img {
    width: 100%;
  }
`;

const checkIcon = encodeURIComponent(
  `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M192 0c-41.8 0-77.4 26.7-90.5 64L64 64C28.7 64 0 92.7 0 128L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64l-37.5 0C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM305 273L177 401c-9.4 9.4-24.6 9.4-33.9 0L79 337c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L271 239c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>`
);

const copyIcon = encodeURIComponent(
  "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='currentColor' d='M208 0L332.1 0c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9L448 336c0 26.5-21.5 48-48 48l-192 0c-26.5 0-48-21.5-48-48l0-288c0-26.5 21.5-48 48-48zM48 128l80 0 0 64-64 0 0 256 192 0 0-32 64 0 0 48c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 176c0-26.5 21.5-48 48-48z'/></svg>"
);

const DiscountBadge = styled.div<any>`
  width: fit-content;
  background-color: ${(props) => props.backgroundColor ?? "#c0fee0"};
  border: 3px dashed ${(props) => props.borderColor ?? "#2b794b"};
  color: ${(props) => props.textColor ?? "#2b794b"};
  padding: 8px 16px;
  border-radius: 8px;
  font-size: ${(props) => (props.fontSize ? props.fontSize + "px" : "30px")};
  font-weight: bold;
  margin: 1rem auto;
  cursor: pointer;

  &::after {
    content: "";
    display: inline-block;
    width: ${(props) =>
      props.fontSize ? props.fontSize * 0.65 + "px" : "20px"};
    height: ${(props) =>
      props.fontSize ? props.fontSize * 0.65 + "px" : "20px"};
    background-color: currentColor;
    mask-image: ${(props) =>
      props.isClicked
        ? `url("data:image/svg+xml,${checkIcon}")`
        : `url("data:image/svg+xml,${copyIcon}")`};
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    margin-left: 8px;
  }

  &:hover::after {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transform: ${(props) => (props.isClicked ? "" : `translateY(-2px)`)};
  }
`;
