import { useRef, useState, useEffect } from "react";
import { Box, Flex, css, SystemProps } from "@storyofams/react-ui";
import { pick } from "@styled-system/props";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { Appear, MediaInput } from "~/components";
import { Button, Subtext, Title, EditorController } from "~/components/preview";
import config from "~/config";
import { FlowNodeLayout } from "~/graphql/sdk";
import { useFlow, useIsRtl, useNavParams, useSdk } from "~/hooks";

import { messages } from "./messages";
import { useFormSync } from "./useFormSync";

import QuestionContentBlockModal from "~/components/preview/QuestionContentBlockModal";
import QuestionContentBlocksList from "~/components/preview/QuestionContentBlocksList";
import { useBillingWrapper } from "~/lib";
import { useQueryClient } from "react-query";

interface WelcomeFormProps extends SystemProps {
  primaryColor?: string;
  total: number;
}

const MotionFlex = motion(Flex);

const getDefaultValues = (flowNode) => ({
  title: flowNode?.title || "",
  description: flowNode?.description || "",
});

const ContentBlockButton = ({ onClick }) => {
  return (
    <Box my={3} mx="auto" width="fit-content">
      <Button
        className="button-secondary"
        size="small"
        variant="secondary"
        primaryColor="#a79efe"
        plus={true}
        onClick={onClick}
        style={{
          border: "2px dashed #a79efe",
          backgroundColor: "transparent",
          borderRadius: "15px",
        }}
      >
        Add a Content Block
      </Button>
    </Box>
  );
};

export const WelcomeForm = ({
  primaryColor,
  total,
  ...props
}: WelcomeFormProps) => {
  const intl = useIntl();
  const { flowNode } = useFlow();
  const isRtl = useIsRtl();
  const [{ question: current }, setNavParams] = useNavParams();
  const flowNodeId = useRef(flowNode?.id || "");

  const [showContentBlockModal, setShowContentBlockModal] = useState(false);
  const [contentBlockLocation, setContentBlockLocation] = useState("top");
  const [contentBlockId, setContentBlockId] = useState(null);
  const [resultsContentBlocks, setResultsContentBlocks] = useState({});

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const queryClient = useQueryClient();

  const totalQuestions = total;

  const { control } = useForm({
    defaultValues: getDefaultValues(flowNode),
  });

  useFormSync({ control, flowNodeId: flowNodeId.current });

  const billingWrapper = useBillingWrapper({});

  const sdk = useSdk();

  useEffect(() => {
    if (flowNode?.contentBlocks) {
      setResultsContentBlocks(JSON.parse(flowNode?.contentBlocks || "{}"));
    }
  }, [flowNode?.contentBlocks]);

  const pageSettings = JSON.parse(flowNode?.pageSettings ?? "{}");

  /* general design settings */
  const topWidthDesktopType = pageSettings?.topWidthDesktopType ?? "100%";
  const topWidth = pageSettings?.topWidth ?? "1200";
  const bottomWidthDesktopType = pageSettings?.bottomWidthDesktopType ?? "100%";
  const bottomWidth = pageSettings?.bottomWidth ?? "1200";
  const topWidthMobileType = pageSettings?.topWidthMobileType ?? "100%";
  const topWidthMobile = pageSettings?.topWidthMobile ?? "1200";
  const bottomWidthMobileType = pageSettings?.bottomWidthMobileType ?? "100%";
  const bottomWidthMobile = pageSettings?.bottomWidthMobile ?? "1200";
  /* question settings */
  const questionAlignment = pageSettings?.questionAlignment ?? "left";
  const questionFontSize = pageSettings?.questionFontSize ?? "32";
  /* description settings */
  const descriptionAlignment = pageSettings?.descriptionAlignment ?? "left";
  const descriptionFontSize = pageSettings?.descriptionFontSize ?? "16";
  /* button settings */
  const useDefaultColorForButton =
    pageSettings?.useDefaultColorForButton ?? "yes";
  const buttonColor = pageSettings?.buttonColor ?? "#000";
  const useDefaultTextColorForButton =
    pageSettings?.useDefaultTextColorForButton ?? "yes";
  const buttonTextColor = pageSettings?.buttonTextColor ?? "#fff";
  const buttonTextOverride = pageSettings?.buttonTextOverride ?? "";
  const buttonFontSize = pageSettings?.buttonFontSize ?? "18";
  const buttonBorderRadius = pageSettings?.buttonBorderRadius ?? "8";
  const buttonHorizontalPadding = pageSettings?.buttonHorizontalPadding ?? "56";
  const buttonVerticalPadding = pageSettings?.buttonVerticalPadding ?? "20";
  const buttonFontSizeMobile = pageSettings?.buttonFontSizeMobile ?? "18";
  const buttonBorderRadiusMobile =
    pageSettings?.buttonBorderRadiusMobile ?? "8";
  const buttonHorizontalPaddingMobile =
    pageSettings?.buttonHorizontalPaddingMobile ?? "56";
  const buttonVerticalPaddingMobile =
    pageSettings?.buttonVerticalPaddingMobile ?? "20";
  const buttonWidthMobileType = pageSettings?.buttonWidthMobileType ?? "100%";
  const buttonWidthMobile = pageSettings?.buttonWidthMobile ?? "345";
  const buttonWidthType = pageSettings?.buttonWidthType ?? "custom";
  const buttonWidth = pageSettings?.buttonWidth ?? "370";
  const buttonAlignment = pageSettings?.buttonAlignment ?? "left";

  const openContentBlockModal = (location) => {
    setContentBlockLocation(location);
    billingWrapper(setShowContentBlockModal)(true);
  };

  const updateContentBlocks = async (contentBlocks) => {
    if (flowNodeId.current && sdk) {
      const result = await sdk.updateOneFlowNode({
        input: {
          id: flowNodeId.current,
          update: {
            contentBlocks: JSON.stringify(contentBlocks),
          },
        },
      });

      queryClient.invalidateQueries(["container"]);

      if (result?.updateOneFlowNode?.contentBlocks) {
        setResultsContentBlocks(
          JSON.parse(result?.updateOneFlowNode?.contentBlocks)
        );
      }
    }
  };

  const saveContentBlock = async (location, id, contentBlock) => {
    if (flowNodeId.current && sdk && contentBlock && location) {
      const existingContentBlocks = resultsContentBlocks?.[location] ?? [];

      const blockIndex = existingContentBlocks.findIndex(
        (cb) => cb?.id && cb.id === id
      );

      if (blockIndex >= 0) {
        existingContentBlocks[blockIndex] = contentBlock;
      } else {
        existingContentBlocks.push(contentBlock);
      }

      const result = await sdk.updateOneFlowNode({
        input: {
          id: flowNodeId.current,
          update: {
            contentBlocks: JSON.stringify({
              ...(resultsContentBlocks ?? {}),
              [location]: existingContentBlocks,
            }),
          },
        },
      });

      queryClient.invalidateQueries(["container"]);

      if (result?.updateOneFlowNode?.contentBlocks) {
        setResultsContentBlocks(
          JSON.parse(result?.updateOneFlowNode?.contentBlocks)
        );
      }
    }
  };

  if (!flowNode?.id) {
    return null;
  }

  const getProps = (layout: FlowNodeLayout | FlowNodeLayout[], props) =>
    (
      Array.isArray(layout)
        ? layout.includes(flowNode.layout)
        : flowNode.layout === layout
    )
      ? props
      : {};

  return (
    <Flex
      height="100%"
      className="welcome-container"
      flexDirection="column"
      justifyContent="flex-start"
      {...getProps(
        [FlowNodeLayout.MediaCoverRight, FlowNodeLayout.MediaCoverLeft],
        {
          justifyContent: "center",
        }
      )}
      {...pick(props)}
    >
      <MotionFlex
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={config.transition}
        flexDirection="column"
        {...getProps(FlowNodeLayout.MediaFloatTop, {
          textAlign: ["left", "center"],
        })}
        {...getProps(FlowNodeLayout.MediaFloatRight, {
          flexDirection: ["column", isRtl ? "row" : "row-reverse"],
          alignItems: "center",
        })}
        {...getProps(FlowNodeLayout.MediaFloatLeft, {
          flexDirection: ["column", isRtl ? "row-reverse" : "row"],
          alignItems: "center",
        })}
      >
        {flowNode.layout === FlowNodeLayout.MediaFloatTop && (
          <Box mb={[4, 7]} mx="auto" width="100%">
            <MediaInput
              image={flowNode.image}
              video={flowNode.video}
              alt="Media"
              flowNodeId={flowNodeId.current}
              imageProps={{
                minHeight: "164px",
                maxHeight: "400px",
              }}
              minEmptyHeight="164px"
              minHeight="164px"
              maxHeight="400px"
              maxWidth={["530px", "790px"]}
              mx="auto"
              sizes="790px"
            />
          </Box>
        )}

        {[
          FlowNodeLayout.MediaFloatRight,
          FlowNodeLayout.MediaFloatLeft,
        ].includes(flowNode.layout) && (
          <Box flex="1">
            <MediaInput
              image={flowNode.image}
              video={flowNode.video}
              alt="Media"
              flowNodeId={flowNodeId.current}
              imageProps={{
                minHeight: "164px",
                maxHeight: ["none", "640px"],
              }}
              minEmptyHeight="520px"
              minHeight="164px"
              maxHeight={["none", "640px"]}
              sizes="570px"
            />
          </Box>
        )}

        <Box
          {...getProps(
            [FlowNodeLayout.MediaFloatRight, FlowNodeLayout.MediaFloatLeft],
            {
              flex: 1,
            }
          )}
        >
          <Box
            maxWidth={["530px", "790px"]}
            className="welcome-content"
            css={css({
              ...getProps(FlowNodeLayout.MediaFloatTop, {
                mx: "auto",
              }),
              ...getProps(FlowNodeLayout.MediaFloatRight, {
                pr: [0, "40px", "40px", "100px"],
                textAlign: "start",
              }),
              ...getProps(FlowNodeLayout.MediaFloatLeft, {
                pl: [0, "40px", "40px", "100px"],
                textAlign: "start",
              }),
            })}
          >
            <Box
              className="welcome-top-section"
              style={{
                margin: "0 auto",
                width: isMobile
                  ? topWidthMobileType === "100%"
                    ? "100%"
                    : `${topWidthMobile}px`
                  : topWidthDesktopType === "100%"
                  ? "100%"
                  : `${topWidth}px`,
              }}
            >
              <Appear>
                <QuestionContentBlocksList
                  contentBlocks={resultsContentBlocks}
                  update={updateContentBlocks}
                  setContentBlockId={setContentBlockId}
                  location="top"
                  openContentBlockModal={openContentBlockModal}
                />

                <ContentBlockButton
                  onClick={() => {
                    setContentBlockId(null);
                    openContentBlockModal("top");
                  }}
                />

                <EditorController
                  name="title"
                  control={control}
                  element={Title}
                  className="welcome-heading"
                  placeholder="Welcome text"
                  {...getProps(FlowNodeLayout.MediaFloatTop, {
                    dir: "auto",
                  })}
                  style={{
                    fontSize: `${questionFontSize}px`,
                    lineHeight: 1.2,
                    textAlign: questionAlignment,
                  }}
                />

                <Box mt={2} mb={5}>
                  <EditorController
                    name="description"
                    className="welcome-subheading"
                    control={control}
                    element={Subtext}
                    placeholder="Subtext with intructions regarding the question (optional)"
                    {...getProps(FlowNodeLayout.MediaFloatTop, {
                      dir: "auto",
                    })}
                    style={{
                      fontSize: `${descriptionFontSize}px`,
                      lineHeight: 1.2,
                      textAlign: descriptionAlignment,
                    }}
                  />
                </Box>

                <QuestionContentBlocksList
                  contentBlocks={resultsContentBlocks}
                  update={updateContentBlocks}
                  setContentBlockId={setContentBlockId}
                  location="middle"
                  openContentBlockModal={openContentBlockModal}
                />
              </Appear>

              <ContentBlockButton
                onClick={() => {
                  setContentBlockId(null);
                  openContentBlockModal("middle");
                }}
              />
            </Box>
            <Box
              className="welcome-bottom-section"
              style={{
                margin: "0 auto",
                width: isMobile
                  ? bottomWidthMobileType === "100%"
                    ? "100%"
                    : `${bottomWidthMobile}px`
                  : bottomWidthDesktopType === "100%"
                  ? "100%"
                  : `${bottomWidth}px`,
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3, ...config.transition }}
              >
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    justifyContent:
                      buttonAlignment === "center"
                        ? "center"
                        : buttonAlignment === "right"
                        ? "flex-end"
                        : "flex-start",
                  }}
                >
                  <Button
                    arrow
                    mt={flowNode.layout === FlowNodeLayout.Simple ? 7 : 0}
                    width={
                      isMobile
                        ? buttonWidthMobileType === "100%"
                          ? "100%"
                          : buttonWidthMobileType === "auto"
                          ? "auto"
                          : `${buttonWidthMobile}px`
                        : buttonWidthType === "100%"
                        ? "100%"
                        : buttonWidthType === "auto"
                        ? "auto"
                        : `${buttonWidth}px`
                    }
                    onClick={() => {
                      if (current < totalQuestions) {
                        setNavParams({ question: `${current + 1}` });
                      }
                    }}
                    primaryColor={primaryColor}
                    className="button start-button"
                    disabled={current >= totalQuestions}
                    style={
                      isMobile
                        ? {
                            ...(useDefaultColorForButton === "no"
                              ? { backgroundColor: buttonColor }
                              : {}),
                            ...(useDefaultTextColorForButton === "no"
                              ? { color: buttonTextColor }
                              : {}),
                            ...(buttonFontSizeMobile >= 0
                              ? {
                                  fontSize: `${buttonFontSizeMobile}px`,
                                }
                              : {}),
                            ...(buttonBorderRadiusMobile >= 0
                              ? {
                                  borderRadius: `${buttonBorderRadiusMobile}px`,
                                }
                              : {}),
                            ...(buttonHorizontalPaddingMobile >= 0
                              ? {
                                  paddingLeft: `${buttonHorizontalPaddingMobile}px`,
                                  paddingRight: `${buttonHorizontalPaddingMobile}px`,
                                }
                              : {}),
                            ...(buttonVerticalPaddingMobile >= 0
                              ? {
                                  paddingTop: `${buttonVerticalPaddingMobile}px`,
                                  paddingBottom: `${buttonVerticalPaddingMobile}px`,
                                }
                              : {}),
                          }
                        : {
                            ...(useDefaultColorForButton === "no"
                              ? { backgroundColor: buttonColor }
                              : {}),
                            ...(useDefaultTextColorForButton === "no"
                              ? { color: buttonTextColor }
                              : {}),
                            ...(buttonFontSize >= 0
                              ? { fontSize: `${buttonFontSize}px` }
                              : {}),
                            ...(buttonBorderRadius >= 0
                              ? {
                                  borderRadius: `${buttonBorderRadius}px`,
                                }
                              : {}),
                            ...(buttonHorizontalPadding >= 0
                              ? {
                                  paddingLeft: `${buttonHorizontalPadding}px`,
                                  paddingRight: `${buttonHorizontalPadding}px`,
                                }
                              : {}),
                            ...(buttonVerticalPadding >= 0
                              ? {
                                  paddingTop: `${buttonVerticalPadding}px`,
                                  paddingBottom: `${buttonVerticalPadding}px`,
                                }
                              : {}),
                          }
                    }
                  >
                    {buttonTextOverride?.length > 0
                      ? buttonTextOverride
                      : intl.formatMessage(messages.start)}
                  </Button>
                </div>

                <QuestionContentBlocksList
                  contentBlocks={resultsContentBlocks}
                  update={updateContentBlocks}
                  setContentBlockId={setContentBlockId}
                  location="bottom"
                  openContentBlockModal={openContentBlockModal}
                />

                <ContentBlockButton
                  onClick={() => {
                    setContentBlockId(null);
                    openContentBlockModal("bottom");
                  }}
                />
              </motion.div>
            </Box>
          </Box>
        </Box>
      </MotionFlex>
      <QuestionContentBlockModal
        open={showContentBlockModal}
        onClose={() => setShowContentBlockModal(false)}
        onOpen={() => setShowContentBlockModal(true)}
        contentBlocks={resultsContentBlocks}
        location={contentBlockLocation}
        id={contentBlockId}
        save={saveContentBlock}
      />
    </Flex>
  );
};
