import { useEffect, useState } from "react";
import {
  Modal,
  BlockStack,
  Button,
  TextField,
  Checkbox,
  Select,
  Thumbnail,
  Text,
  InlineStack,
} from "@shopify/polaris";
import { Box } from "@storyofams/react-ui";
import styled from "styled-components";
import RichTextEditor from "~/components/preview/RichTextEditor";
import { v4 as uuidv4 } from "uuid";
import { MediaModal } from "~/components/MediaInput/MediaModal";
import Editor from "@monaco-editor/react";
import { DeleteIcon, StarFilledIcon, UploadIcon } from "@shopify/polaris-icons";
import { ColorInput } from "~/components/ColorInput";
import { useStore } from "~/hooks";

const UPLOAD_API_KEY = "public_FW25bYGAgB45TKGbWWuMMhPy32FL";

const locales = {
  ar: "Arabic",
  cs: "Czech",
  da: "Danish",
  de: "German",
  el: "Greek",
  en: "English",
  es: "Spanish",
  fa: "Persian",
  fi: "Finnish",
  fr: "French",
  he: "Hebrew",
  hr: "Croatian",
  hu: "Hungarian",
  id: "Indonesian",
  is: "Icelandic",
  it: "Italian",
  ja: "Japanese",
  ko: "Korean",
  nl: "Dutch",
  pl: "Polish",
  pt: "Portuguese",
  ro: "Romanian",
  ru: "Russian",
  sr: "Serbian",
  sv: "Swedish",
  th: "Thai",
  tr: "Turkish",
  vi: "Vietnamese",
  zh: "Chinese",
};

// @ts-ignore
const upload = Upload({ apiKey: UPLOAD_API_KEY });

const QuestionContentBlockModal = ({
  open,
  onClose,
  onOpen,
  location,
  id,
  save,
  contentBlocks,
}) => {
  const [block, setBlock] = useState({
    content: "",
    image: {} as any,
    discount: {} as any,
    upload: {} as any,
  });
  const [desktopWidth, setDesktopWidth] = useState("900px");
  const [mobileWidth, setMobileWidth] = useState("100%");
  const [showFilePicker, setShowFilePicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [rawHTML, setRawHTML] = useState("");
  const store = useStore();

  useEffect(() => {
    if (id && contentBlocks?.[location]?.length) {
      const contentBlock = contentBlocks?.[location]?.find(
        (cb) => cb?.id === id
      );
      if (contentBlock?.block) {
        setBlock(contentBlock.block);
      }
      if (contentBlock?.settings?.desktopWidth) {
        setDesktopWidth(contentBlock?.settings?.desktopWidth);
      }
      if (contentBlock?.settings?.mobileWidth) {
        setMobileWidth(contentBlock?.settings?.mobileWidth);
      }
      if (contentBlock?.rawHTML) {
        setRawHTML(contentBlock.rawHTML);
      }
    } else {
      setBlock({
        content: "",
        image: {},
        discount: {},
        upload: {},
      });
      setDesktopWidth("900px");
      setMobileWidth("100%");
    }
  }, [contentBlocks, id]);

  const openFilePicker = () => {
    setShowFilePicker(true);
  };

  const closeFilePicker = () => {
    setShowFilePicker(false);
  };

  const uploadFile = async (file) => {
    try {
      const fileName = file?.name ?? "image";

      const { fileUrl } = await upload.uploadFile(file, {
        path: {
          folderPath: "/uploads/ppf",
          fileName: "{UNIQUE_DIGITS_8}{ORIGINAL_FILE_EXT}",
        },
        onProgress: () => {},
      });
      setBlock({
        ...block,
        image: {
          ...block?.image,
          name: fileName,
          url: fileUrl,
        },
      });
    } catch (error) {
      console.log({ error });
    }
  };

  const setFile = (name, url) => {
    setBlock({
      ...block,
      image: {
        ...block?.image,
        name,
        url,
      },
    });
    closeFilePicker();
  };

  const handleDropZoneDrop = async (
    _dropFiles,
    acceptedFiles,
    _rejectedFiles
  ) => {
    const file = acceptedFiles?.[0];
    if (file) {
      setUploading(true);
      await uploadFile(file);
      setUploading(false);
    }
    closeFilePicker();
  };

  const saveContentBlock = async () => {
    setLoading(true);
    if (!id) {
      id = uuidv4();
    }
    await save(location, id, {
      id,
      block,
      settings: {
        desktopWidth,
        mobileWidth,
      },
      rawHTML,
    });
    onClose();
    setLoading(false);
  };

  const updateBlockContent = (content) => {
    setBlock({
      ...block,
      content,
    });
  };

  return (
    <>
      <Modal
        open={open && !showFilePicker}
        onClose={onClose}
        title="Add a Content Block"
        primaryAction={{
          content: "Save Content Block",
          onAction: saveContentBlock,
          loading,
          disabled:
            block?.content === "" &&
            !rawHTML?.length &&
            !block?.discount?.active &&
            !block?.upload?.active &&
            !block?.image?.showImage,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: onClose,
          },
        ]}
        size="large"
      >
        <Modal.Section>
          <SettingsContainer>
            <TextField
              label="Desktop Width"
              value={desktopWidth}
              onChange={(v) => setDesktopWidth(v)}
              autoComplete="off"
            />
            <TextField
              label="Mobile Width"
              value={mobileWidth}
              onChange={(v) => setMobileWidth(v)}
              autoComplete="off"
            />
          </SettingsContainer>
          <BlockStack gap="200">
            <BlockStack gap="600">
              <InlineStack gap="400" align="start" blockAlign="end">
                <Checkbox
                  label={
                    store?.tier?.name !== "Enterprise"
                      ? "Include a file upload widget (Enterprise-only feature)"
                      : "Include a file upload widget"
                  }
                  checked={block?.upload?.active}
                  disabled={store?.tier?.name !== "Enterprise"}
                  onChange={() => {
                    setBlock({
                      ...block,
                      upload: {
                        ...block?.upload,
                        active: block?.upload?.active ? false : true,
                      },
                    });
                  }}
                />
                {store?.tier?.name !== "Enterprise" && (
                  <Button
                    icon={StarFilledIcon}
                    variant="primary"
                    onClick={() => {
                      window?.top?.frames?.["app-iframe"]?.postMessage(
                        {
                          action: "openBilling",
                        },
                        "*"
                      );
                    }}
                  >
                    Upgrade to Enterprise
                  </Button>
                )}
              </InlineStack>
              {store?.tier?.name === "Enterprise" && block?.upload?.active && (
                <InlineStack gap="400" align="start" blockAlign="center">
                  <Select
                    label="Language"
                    value={block?.upload?.language ?? "en"}
                    onChange={(language) =>
                      setBlock({
                        ...block,
                        upload: {
                          ...block?.upload,
                          language,
                        },
                      })
                    }
                    options={Object.entries(locales).map(([key, value]) => ({
                      label: value,
                      value: key,
                    }))}
                  />
                  <TextField
                    label="Max. Files"
                    type="number"
                    value={block?.upload?.maxFiles ?? 20}
                    onChange={(maxFiles) =>
                      setBlock({
                        ...block,
                        upload: {
                          ...block?.upload,
                          maxFiles,
                        },
                      })
                    }
                    autoComplete="off"
                  />
                </InlineStack>
              )}
            </BlockStack>
          </BlockStack>
          <BlockStack gap="200">
            <Box mt={2} mb={2}>
              <BlockStack gap="600">
                <DiscountSection>
                  <Checkbox
                    label="Include a discount"
                    checked={block?.discount?.active}
                    onChange={() => {
                      setBlock({
                        ...block,
                        discount: {
                          ...block?.discount,
                          active: block?.discount?.active ? false : true,
                        },
                      });
                    }}
                  />
                  {!!block?.discount?.active && (
                    <DiscountSettingsContainer>
                      <HorizontalSettingsContainer>
                        <TextField
                          label="Discount Code"
                          helpText="This must be a valid discount code you've generated in Shopify"
                          value={block?.discount?.code ?? ""}
                          onChange={(code) => {
                            setBlock({
                              ...block,
                              discount: {
                                ...block?.discount,
                                code,
                              },
                            });
                          }}
                          placeholder="e.g. QUIZ-15OFF"
                          autoComplete="off"
                        />
                      </HorizontalSettingsContainer>
                      <Checkbox
                        label="Display the code"
                        helpText="Check this option to visually display the discount code on the page, otherwise it will be hidden"
                        checked={block?.discount?.display ?? true}
                        onChange={() => {
                          setBlock({
                            ...block,
                            discount: {
                              ...block?.discount,
                              display: block?.discount?.display ? false : true,
                            },
                          });
                        }}
                      />
                      <Checkbox
                        label="Apply discount automatically"
                        helpText="Check this option to automatically apply the discount code to the cart, without the customer needing to enter it"
                        checked={block?.discount?.autoApply}
                        onChange={() => {
                          setBlock({
                            ...block,
                            discount: {
                              ...block?.discount,
                              autoApply: block?.discount?.autoApply
                                ? false
                                : true,
                            },
                          });
                        }}
                      />
                      <Checkbox
                        label="Optin reward"
                        helpText="Only activate if the customer has provided an email address or phone number"
                        checked={block?.discount?.optinOnly}
                        onChange={() => {
                          setBlock({
                            ...block,
                            discount: {
                              ...block?.discount,
                              optinOnly: block?.discount?.optinOnly
                                ? false
                                : true,
                            },
                          });
                        }}
                      />
                      <HorizontalSettingsContainer>
                        <ColorInputContainer>
                          <Select
                            label="Position"
                            options={[
                              { label: "Top", value: "top" },
                              { label: "Bottom", value: "bottom" },
                            ]}
                            value={block?.discount?.position ?? "bottom"}
                            onChange={(position) =>
                              setBlock({
                                ...block,
                                discount: {
                                  ...block?.discount,
                                  position,
                                },
                              })
                            }
                          />
                        </ColorInputContainer>
                        <ColorInputContainer>
                          <TextField
                            label="Font Size"
                            type="number"
                            suffix="px"
                            value={block?.discount?.fontSize ?? "30"}
                            autoComplete="off"
                            onChange={(fontSize) =>
                              setBlock({
                                ...block,
                                discount: {
                                  ...block?.discount,
                                  fontSize,
                                },
                              })
                            }
                          />
                        </ColorInputContainer>
                        <ColorInputContainer>
                          <BlockStack
                            gap="100"
                            align="start"
                            inlineAlign="stretch"
                          >
                            <Text variant="bodyLg" as="span">
                              Background Color
                            </Text>
                            <ColorInput
                              value={
                                block?.discount?.backgroundColor ?? "#c0fee0"
                              }
                              onChange={(backgroundColor) =>
                                setBlock({
                                  ...block,
                                  discount: {
                                    ...block?.discount,
                                    backgroundColor,
                                  },
                                })
                              }
                            />
                          </BlockStack>
                        </ColorInputContainer>
                        <ColorInputContainer>
                          <BlockStack
                            gap="100"
                            align="start"
                            inlineAlign="stretch"
                          >
                            <Text variant="bodyLg" as="span">
                              Border Color
                            </Text>
                            <ColorInput
                              value={block?.discount?.borderColor ?? "#2b794b"}
                              onChange={(borderColor) =>
                                setBlock({
                                  ...block,
                                  discount: {
                                    ...block?.discount,
                                    borderColor,
                                  },
                                })
                              }
                            />
                          </BlockStack>
                        </ColorInputContainer>
                        <ColorInputContainer>
                          <BlockStack
                            gap="100"
                            align="start"
                            inlineAlign="stretch"
                          >
                            <Text variant="bodyLg" as="span">
                              Text Color
                            </Text>
                            <ColorInput
                              value={block?.discount?.textColor ?? "#2b794b"}
                              onChange={(textColor) =>
                                setBlock({
                                  ...block,
                                  discount: {
                                    ...block?.discount,
                                    textColor,
                                  },
                                })
                              }
                            />
                          </BlockStack>
                        </ColorInputContainer>
                      </HorizontalSettingsContainer>
                      <Text as="p" variant="bodySm">
                        You can further customize the style of the discount
                        code, by adding custom CSS to{" "}
                        <code>.discount-code-badge</code>
                      </Text>
                    </DiscountSettingsContainer>
                  )}
                </DiscountSection>
                <ImageSection>
                  <Checkbox
                    label="Include an image"
                    checked={block?.image?.showImage}
                    onChange={() => {
                      setBlock({
                        ...block,
                        image: {
                          ...block?.image,
                          showImage: block?.image?.showImage ? false : true,
                        },
                      });
                    }}
                  />
                  {!!block?.image?.showImage && (
                    <ImageSettingsContainer>
                      <HorizontalSettingsContainer>
                        {!block?.image?.name && (
                          <Button
                            onClick={openFilePicker}
                            variant="primary"
                            icon={UploadIcon}
                            size="slim"
                          >
                            Upload Image
                          </Button>
                        )}
                        {!!block?.image?.name && !!block?.image?.url && (
                          <ImageNameContainer>
                            <Thumbnail
                              source={block?.image?.url}
                              alt={block?.image?.name}
                              size="small"
                            />
                            {!block?.image?.url?.includes("unsplash.com") && (
                              <ImageName>{block?.image?.name}</ImageName>
                            )}
                            <Button
                              variant="primary"
                              icon={DeleteIcon}
                              size="slim"
                              tone="critical"
                              onClick={() =>
                                setBlock({
                                  ...block,
                                  image: {
                                    ...block?.image,
                                    name: null,
                                    url: null,
                                  },
                                })
                              }
                            >
                              Remove
                            </Button>
                          </ImageNameContainer>
                        )}
                      </HorizontalSettingsContainer>
                      <HorizontalSettingsContainer>
                        <TextField
                          label="Desktop Width"
                          value={block?.image?.width ?? "100%"}
                          onChange={(width) =>
                            setBlock({
                              ...block,
                              image: {
                                ...block?.image,
                                width,
                              },
                            })
                          }
                          placeholder="100%"
                          autoComplete="off"
                        />
                        <TextField
                          label="Mobile Width"
                          value={block?.image?.widthMobile ?? "100%"}
                          onChange={(widthMobile) =>
                            setBlock({
                              ...block,
                              image: {
                                ...block?.image,
                                widthMobile,
                              },
                            })
                          }
                          placeholder="100%"
                          autoComplete="off"
                        />
                        <Select
                          label="Position"
                          options={[
                            { label: "Top", value: "top" },
                            { label: "Bottom", value: "bottom" },
                            { label: "Left", value: "left" },
                            { label: "Right", value: "right" },
                          ]}
                          value={block?.image?.position ?? "bottom"}
                          onChange={(position) =>
                            setBlock({
                              ...block,
                              image: {
                                ...block?.image,
                                position,
                              },
                            })
                          }
                        />
                      </HorizontalSettingsContainer>
                    </ImageSettingsContainer>
                  )}
                </ImageSection>
                <RichTextEditor
                  variantId={id}
                  value={block?.content}
                  setValue={(v) => updateBlockContent(v)}
                />
                <BlockStack gap="050">
                  <Text as="h3" variant="headingMd">
                    Raw HTML
                  </Text>
                  <Text as="p" variant="bodyMd">
                    WARNING! Raw HTML gets rendered as is and can break the quiz
                    code. Please use with caution.
                  </Text>
                  <Editor
                    height="20vh"
                    defaultLanguage="html"
                    value={rawHTML}
                    onChange={(v) => setRawHTML(v ?? "")}
                  />
                </BlockStack>
              </BlockStack>
            </Box>
          </BlockStack>
        </Modal.Section>
      </Modal>

      <MediaModal
        isOpen={showFilePicker}
        setOpen={setShowFilePicker}
        onDrop={async (v) => {
          await handleDropZoneDrop(null, v, null);
        }}
        onSelectImage={(newImage) => {
          setFile(newImage?.blurhash ?? uuidv4(), newImage?.url);
        }}
        onSelectVideo={(newVideo) => {
          setFile(newVideo?.blurhash ?? uuidv4(), newVideo?.url);
        }}
        onSelectGif={(newGif) => {
          setFile(newGif?.blurhash ?? uuidv4(), newGif?.url);
        }}
        isBusy={uploading}
        types={["image", "gif"]}
      />
    </>
  );
};

export default QuestionContentBlockModal;

const SettingsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
  margin: 16px 0;
`;

const ImageSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
`;

const ImageSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
  padding-left: 4rem;
  border-top: 1px solid #dfe3e8;
  border-bottom: 1px solid #dfe3e8;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const DiscountSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
`;

const DiscountSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
  padding-left: 4rem;
  border-top: 1px solid #dfe3e8;
  border-bottom: 1px solid #dfe3e8;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const HorizontalSettingsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;

  & > label {
    min-width: 150px;
  }
`;

const ImageNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
`;

const ImageName = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: #637381;
`;

const ColorInputContainer = styled.div`
  min-width: 150px;

  & > div {
    width: 100%;
  }

  & button {
    width: 100%;
  }
`;
