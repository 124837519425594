import { Card, EmptyState, Page, BlockStack } from "@shopify/polaris";

interface ErrorPageProps {
  onReset?(): void;
  text?: string;
}

export const ErrorPage = ({ onReset, text }: ErrorPageProps) => (
  <Page>
    <Card>
      <EmptyState
        heading="An error has occurred"
        image="/illustrations/error.svg"
        action={
          onReset
            ? {
                content: "Refresh the page",
                onAction: onReset,
              }
            : undefined
        }
      >
        <BlockStack gap="100">
          <p>
            {text ||
              "Please send us a message if you continue to see this error."}
          </p>
        </BlockStack>
      </EmptyState>
    </Card>
  </Page>
);
