import { Icon, IconSource } from "@shopify/polaris";
import { ImageIcon } from "@shopify/polaris-icons";
import {
  Box,
  Flex,
  Icon as IconUI,
  SystemProps,
  Text,
} from "@storyofams/react-ui";
import { range } from "lodash";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { useFlow, useIsRtl } from "~/hooks";
import { Button } from "../Button";
import { ReactComponent as star } from "../Icon/library/star.svg";
import { messages } from "./messages";
import { useEffect, useState } from "react";

interface ProductCardProps {
  enableAddToCart?: boolean;
  noCart?: boolean;
  openInNewTab?: boolean;
  enableReviews?: boolean;
  reviewsSource?: string;
  cartPage?: string;
  showProductDescription?: boolean;
  showShortDescription?: boolean;
  customDescriptionMetafield?: string;
  showVisualMatchBlock?: boolean;
  image?: string;
  link?: string;
  price?: string;
  primaryColor?: string | null;
  title: string;
  /** @default white-bg */
  type?: "not-transparent" | "white-bg";
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: white;
  border: 1px solid;
  border-color: ${(p) => p.theme.colors.black10};
  border-radius: ${(p) => p.theme.radii.md};
  transition: background-color 0.18s ease-in-out, border 0.18s ease-in-out,
    box-shadow 0.18s ease-in-out;
  cursor: pointer;
  user-select: none;

  &:hover {
    border-color: ${(p) => p.theme.colors.black60};
  }
`;

const Img = styled(Flex)<Pick<ProductCardProps, "type">>`
  background-color: ${(p) => p.theme.colors.border};
  width: calc(100% - 32px);
  height: ${(p) => (p.type !== "not-transparent" ? "184px" : "200px")};
  margin: ${(p) =>
    p.type !== "not-transparent"
      ? `${p.theme.space[2]}px ${p.theme.space[2]}px 0 ${p.theme.space[2]}px`
      : 0};

  > .Polaris-Icon {
    width: 32px;
    height: 32px;
  }
`;

export const ProductCard = ({
  enableAddToCart,
  noCart,
  openInNewTab,
  enableReviews,
  reviewsSource,
  cartPage,
  showProductDescription,
  showShortDescription,
  customDescriptionMetafield,
  showVisualMatchBlock,
  price,
  primaryColor,
  title,
  ...props
}: ProductCardProps & SystemProps) => {
  const isRtl = useIsRtl();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { data } = useFlow();
  const pageSettings = JSON.parse(data?.flows?.[0]?.pageSettings ?? "{}");

  /* add-to-cart button settings */
  const atc_useDefaultColorForButton =
    pageSettings?.atc_useDefaultColorForButton ?? "yes";
  const atc_buttonColor = pageSettings?.atc_buttonColor ?? "#000";
  const atc_useDefaultTextColorForButton =
    pageSettings?.atc_useDefaultTextColorForButton ?? "yes";
  const atc_buttonTextColor = pageSettings?.atc_buttonTextColor ?? "#fff";
  const atc_buttonTextOverride = pageSettings?.atc_buttonTextOverride ?? "";
  const atc_buttonFontSize = pageSettings?.atc_buttonFontSize ?? "18";
  const atc_buttonBorderRadius = pageSettings?.atc_buttonBorderRadius ?? "8";
  const atc_buttonHorizontalPadding =
    pageSettings?.atc_buttonHorizontalPadding ?? "56";
  const atc_buttonVerticalPadding =
    pageSettings?.atc_buttonVerticalPadding ?? "20";
  const atc_buttonFontSizeMobile =
    pageSettings?.atc_buttonFontSizeMobile ?? "18";
  const atc_buttonBorderRadiusMobile =
    pageSettings?.atc_buttonBorderRadiusMobile ?? "8";
  const atc_buttonHorizontalPaddingMobile =
    pageSettings?.atc_buttonHorizontalPaddingMobile ?? "56";
  const atc_buttonVerticalPaddingMobile =
    pageSettings?.atc_buttonVerticalPaddingMobile ?? "20";
  const atc_buttonWidthMobileType =
    pageSettings?.atc_buttonWidthMobileType ?? "100%";
  const atc_buttonWidthMobile = pageSettings?.atc_buttonWidthMobile ?? "345";
  const atc_buttonWidthType = pageSettings?.atc_buttonWidthType ?? "100%";
  const atc_buttonWidth = pageSettings?.atc_buttonWidth ?? "370";
  const atc_buttonAlignment = pageSettings?.atc_buttonAlignment ?? "center";

  return (
    <Wrapper className="product-card" {...props}>
      <Box className="product-image-container" position="relative" width="100%">
        <Img
          className="product-image"
          justifyContent="center"
          alignItems="center"
        >
          <Icon source={ImageIcon as IconSource} tone="subdued" />
        </Img>
      </Box>

      <Box
        className="product-details"
        p={3}
        pt={enableReviews ? 2.5 : 3}
        textAlign="start"
        width="100%"
      >
        {enableReviews && (
          <Flex className="reviews-container" mb={1.5} alignItems="center">
            {range(5).map((idx) => (
              <IconUI key={idx} icon={star as any} mr={0.5} />
            ))}

            <Text fontSize={1.5} lineHeight="15px" color="black60" ml={0.5}>
              23 <FormattedMessage {...messages["reviews"]} />
            </Text>
          </Flex>
        )}

        <Text
          className="product-title"
          fontSize={3}
          fontWeight="bold"
          lineHeight="29px"
          color="black80"
          letterSpacing={isRtl ? undefined : "-0.01em"}
        >
          {title}
        </Text>
        {!!price && (
          <Text
            className="price"
            mt={0.75}
            fontSize={2}
            lineHeight="140%"
            color="black60"
          >
            {price}
          </Text>
        )}

        {showProductDescription && (
          <Text
            className="product-description"
            mt={1.5}
            fontSize={2}
            lineHeight="140%"
            color="black60"
          >
            Product Description
          </Text>
        )}

        {showShortDescription && (
          <Text
            className="product-short-description"
            mt={1.5}
            fontSize={2}
            lineHeight="140%"
            color="black60"
          >
            Short Description
          </Text>
        )}

        {showVisualMatchBlock && (
          <Text
            className="visual-match-block"
            mt={1.5}
            fontSize={2}
            lineHeight="140%"
            color="black60"
          >
            Product Matches
          </Text>
        )}

        <div
          className="atc-button-container"
          style={{
            display: "flex",
            marginTop: "20px",
            width: "100%",
            justifyContent:
              atc_buttonAlignment === "center"
                ? "center"
                : atc_buttonAlignment === "right"
                ? "flex-end"
                : "flex-start",
          }}
        >
          <Button
            mt={4}
            width={
              isMobile
                ? atc_buttonWidthMobileType === "100%"
                  ? "100%"
                  : atc_buttonWidthMobileType === "auto"
                  ? "auto"
                  : `${atc_buttonWidthMobile}px`
                : atc_buttonWidthType === "100%"
                ? "100%"
                : atc_buttonWidthType === "auto"
                ? "auto"
                : `${atc_buttonWidth}px`
            }
            borderRadius="xs"
            disabled
            primaryColor={primaryColor || undefined}
            className="button add-to-cart-button"
            style={
              isMobile
                ? {
                    ...(atc_useDefaultColorForButton === "no"
                      ? { backgroundColor: atc_buttonColor }
                      : {}),
                    ...(atc_useDefaultTextColorForButton === "no"
                      ? { color: atc_buttonTextColor }
                      : {}),
                    ...(atc_buttonFontSizeMobile >= 0
                      ? {
                          fontSize: `${atc_buttonFontSizeMobile}px`,
                        }
                      : {}),
                    ...(atc_buttonBorderRadiusMobile >= 0
                      ? {
                          borderRadius: `${atc_buttonBorderRadiusMobile}px`,
                        }
                      : {}),
                    ...(atc_buttonHorizontalPaddingMobile >= 0
                      ? {
                          paddingLeft: `${atc_buttonHorizontalPaddingMobile}px`,
                          paddingRight: `${atc_buttonHorizontalPaddingMobile}px`,
                        }
                      : {}),
                    ...(atc_buttonVerticalPaddingMobile >= 0
                      ? {
                          paddingTop: `${atc_buttonVerticalPaddingMobile}px`,
                          paddingBottom: `${atc_buttonVerticalPaddingMobile}px`,
                        }
                      : {}),
                  }
                : {
                    ...(atc_useDefaultColorForButton === "no"
                      ? { backgroundColor: atc_buttonColor }
                      : {}),
                    ...(atc_useDefaultTextColorForButton === "no"
                      ? { color: atc_buttonTextColor }
                      : {}),
                    ...(atc_buttonFontSize >= 0
                      ? { fontSize: `${atc_buttonFontSize}px` }
                      : {}),
                    ...(atc_buttonBorderRadius >= 0
                      ? {
                          borderRadius: `${atc_buttonBorderRadius}px`,
                        }
                      : {}),
                    ...(atc_buttonHorizontalPadding >= 0
                      ? {
                          paddingLeft: `${atc_buttonHorizontalPadding}px`,
                          paddingRight: `${atc_buttonHorizontalPadding}px`,
                        }
                      : {}),
                    ...(atc_buttonVerticalPadding >= 0
                      ? {
                          paddingTop: `${atc_buttonVerticalPadding}px`,
                          paddingBottom: `${atc_buttonVerticalPadding}px`,
                        }
                      : {}),
                  }
            }
          >
            {atc_buttonTextOverride?.length > 0 ? (
              atc_buttonTextOverride
            ) : (
              <FormattedMessage
                {...messages[enableAddToCart ? "addToCart" : "view"]}
              />
            )}
          </Button>
        </div>
      </Box>
    </Wrapper>
  );
};
