import { ReactNode, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Text,
  InlineStack,
  BlockStack,
} from "@shopify/polaris";
import { IntegrationType } from "~/graphql/sdk";
import { Klaviyo } from "../Connect/Klaviyo";
import { Mailchimp } from "../Connect/Mailchimp";
import { useQuery } from "react-query";
import { useSdk } from "~/hooks";
import { useParams } from "react-router-dom";
import { useBillingWrapper } from "~/lib";
import { FlowStatus } from "~/graphql/sdk";
interface IntegrationCardProps {
  icon?: ReactNode;
  title: string;
  description: string | ReactNode;
  isLoading?: boolean;
  isConnected?: boolean;
  onClick(): void;
  type: IntegrationType;
  requiresConnection?: boolean;
}

export const IntegrationCard = ({
  icon,
  title,
  description,
  isLoading,
  isConnected,
  requiresConnection,
  onClick,
  type,
}: IntegrationCardProps) => {
  const sdk = useSdk();
  const { id } = useParams<{ id: string }>();
  const { data: flowData } = useQuery(
    ["flowContainer", { id }],
    () =>
      sdk
        .container({
          id,
          flowFilter: {
            status: {
              in: [FlowStatus.Active],
            },
          },
        })
        .then((res) => res.flowContainer),
    {
      suspense: true,
    }
  );

  const billingWrapper = useBillingWrapper({ freeForExistingV1Users: true });

  const [klaviyoActivated, setKlaviyoActivated] = useState(
    flowData?.integrationMetadata?.klaviyo?.listId
  );
  const [mailChimpActivated, setMailChimpActivated] = useState(
    flowData?.integrationMetadata?.mailchimp?.listId
  );

  const [showActiveForm, setShowActiveForm] = useState(false);

  return (
    <>
      {type === IntegrationType.Klaviyo &&
      (klaviyoActivated || showActiveForm) ? (
        <Klaviyo
          deactivate={() => {
            setKlaviyoActivated("");
            setShowActiveForm(false);
          }}
          data={flowData}
          id={id}
        />
      ) : null}
      {type === IntegrationType.Mailchimp &&
      (mailChimpActivated || showActiveForm) ? (
        <Mailchimp
          deactivate={() => {
            setMailChimpActivated("");
            setShowActiveForm(false);
          }}
          data={flowData}
          id={id}
        />
      ) : null}
      <br></br>
      {(type === IntegrationType.Klaviyo &&
        !(klaviyoActivated || showActiveForm)) ||
      (type === IntegrationType.Mailchimp &&
        !(mailChimpActivated || showActiveForm)) ? (
        <Card>
          <InlineStack align="space-between" block-align="center" wrap={false}>
            <BlockStack gap="100" inlineAlign="start" align="start">
              <InlineStack align="start" blockAlign="center" gap="200">
                {icon}
                <Text as="h3" variant="headingMd">
                  {title}
                </Text>
                {requiresConnection && isConnected && (
                  <Badge tone="success">Connected</Badge>
                )}
              </InlineStack>
              <Text as="p" variant="bodySm">
                {description}
              </Text>
            </BlockStack>
            <InlineStack align="end" blockAlign="center" gap="200">
              {isConnected || !requiresConnection ? (
                <Button
                  loading={isLoading}
                  variant="primary"
                  onClick={() => setShowActiveForm(true)}
                >
                  {"Activate"}
                </Button>
              ) : null}
              {requiresConnection && (
                <Button
                  loading={isLoading}
                  variant={!isConnected ? "primary" : "plain"}
                  onClick={() => billingWrapper(onClick)()}
                >
                  {isConnected ? "Edit" : "Connect"}
                </Button>
              )}
            </InlineStack>
          </InlineStack>
        </Card>
      ) : null}
    </>
  );
};
