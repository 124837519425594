import { useState, useEffect } from "react";
import {
  Button,
  Collapsible,
  Icon,
  RangeSlider,
  Select,
  InlineStack,
  BlockStack,
  Link,
  Text,
  Checkbox,
  RadioButton,
} from "@shopify/polaris";
import { Controller, useWatch, useController } from "react-hook-form";
import { TextField } from "@shopify/polaris";
import { FlowNodeType } from "~/graphql/sdk";
import { Section } from "~/components/Section";
import { useOptionsForm } from "~/hooks";
import {
  AppsIcon,
  ButtonIcon,
  ConnectIcon,
  DeleteIcon,
  DesktopIcon,
  MobileIcon,
  PaintBrushFlatIcon,
  PhoneIcon,
  PlusIcon,
  TextAlignCenterIcon,
  TextAlignLeftIcon,
  TextAlignRightIcon,
  TextFontListIcon,
  ThemeTemplateIcon,
} from "@shopify/polaris-icons";
import styled from "styled-components";
import { ColorInput } from "~/components/ColorInput";
import { v4 as uuidv4 } from "uuid";

const getDefaultValues = (values) => {
  const pageSettingsJSON = values?.pageSettings;
  const pageSettings = pageSettingsJSON ? JSON.parse(pageSettingsJSON) : {};

  return {
    hideNoThanks:
      typeof values?.hideNoThanks !== "undefined"
        ? !!values.hideNoThanks
        : false,
    askForName: values?.askForName || "no",
    gdprCheckbox: values?.gdprCheckbox || "no",
    gdprText:
      values?.gdprText ||
      "I agree to receive marketing communications and understand my personal data will be processed in accordance with the <a>Privacy Policy</a>.",
    gdprLink: values?.gdprLink || "https://mystore.com/privacy-policy",
    emailPage_displayEmailIcon:
      pageSettings?.emailPage_displayEmailIcon ?? "yes",
    emailPage_displayHeadlineSubheadline:
      pageSettings?.emailPage_displayHeadlineSubheadline ?? "yes",
    emailPage_buttonColor: pageSettings?.emailPage_buttonColor ?? "#000",
    emailPage_useDefaultColorForButton:
      pageSettings?.emailPage_useDefaultColorForButton ?? "yes",
    emailPage_buttonTextColor:
      pageSettings?.emailPage_buttonTextColor ?? "#fff",
    emailPage_useDefaultTextColorForButton:
      pageSettings?.emailPage_useDefaultTextColorForButton ?? "yes",
    emailPage_buttonTextOverride:
      pageSettings?.emailPage_buttonTextOverride ?? "",
    emailPage_buttonFontSize: pageSettings?.emailPage_buttonFontSize ?? "18",
    emailPage_buttonHorizontalPadding:
      pageSettings?.emailPage_buttonHorizontalPadding ?? "56",
    emailPage_buttonVerticalPadding:
      pageSettings?.emailPage_buttonVerticalPadding ?? "20",
    emailPage_buttonBorderRadius:
      pageSettings?.emailPage_buttonBorderRadius ?? "8",
    emailPage_buttonWidthType:
      pageSettings?.emailPage_buttonWidthType ?? "100%",
    emailPage_buttonWidth: pageSettings?.emailPage_buttonWidth ?? "370",

    emailPage_TopWidthType: pageSettings?.emailPage_TopWidthType ?? "100%",
    emailPage_TopWidth: pageSettings?.emailPage_TopWidth ?? "480",
    emailPage_BottomWidthType:
      pageSettings?.emailPage_BottomWidthType ?? "custom",
    emailPage_BottomWidth: pageSettings?.emailPage_BottomWidth ?? "370",
    emailPage_TopWidthMobileType:
      pageSettings?.emailPage_TopWidthMobileType ?? "100%",
    emailPage_TopWidthMobile: pageSettings?.emailPage_TopWidthMobile ?? "345",
    emailPage_BottomWidthMobileType:
      pageSettings?.emailPage_BottomWidthMobileType ?? "100%",
    emailPage_BottomWidthMobile:
      pageSettings?.emailPage_BottomWidthMobile ?? "345",

    emailPage_buttonFontSizeMobile:
      pageSettings?.emailPage_buttonFontSizeMobile ?? "18",
    emailPage_buttonBorderRadiusMobile:
      pageSettings?.emailPage_buttonBorderRadiusMobile ?? "8",
    emailPage_buttonHorizontalPaddingMobile:
      pageSettings?.emailPage_buttonHorizontalPaddingMobile ?? "56",
    emailPage_buttonVerticalPaddingMobile:
      pageSettings?.emailPage_buttonVerticalPaddingMobile ?? "20",
    emailPage_buttonWidthMobileType:
      pageSettings?.emailPage_buttonWidthMobileType ?? "100%",
    emailPage_buttonWidthMobile:
      pageSettings?.emailPage_buttonWidthMobile ?? "345",
    emailPage_displayEmailInput:
      pageSettings?.emailPage_displayEmailInput ?? "yes",
    emailPage_displayPhoneInput:
      pageSettings?.emailPage_displayPhoneInput ?? "no",
    emailPage_phoneLabel: pageSettings?.emailPage_phoneLabel ?? "Phone Number",
    emailPage_phonePlaceholder: pageSettings?.emailPage_phonePlaceholder ?? "",
    emailPage_phoneDefaultCountry:
      pageSettings?.emailPage_phoneDefaultCountry ?? "us",
    emailPage_phoneOnlyCountries:
      pageSettings?.emailPage_phoneOnlyCountries ?? "",
    emailPage_phoneExcludedCountries:
      pageSettings?.emailPage_phoneExcludedCountries ?? "",
    emailPage_customMasks: pageSettings?.emailPage_customMasks ?? "",
    emailPage_phonePreferredCountries:
      pageSettings?.emailPage_phonePreferredCountries ?? "",
    emailPage_klaviyoEmailMarketing:
      pageSettings?.emailPage_klaviyoEmailMarketing ?? true,
    emailPage_klaviyoSMSMarketing:
      pageSettings?.emailPage_klaviyoSMSMarketing ?? true,
    emailPage_klaviyoSMSTransactional:
      pageSettings?.emailPage_klaviyoSMSTransactional ?? true,
    emailPage_headlineAlignment:
      pageSettings?.emailPage_headlineAlignment ?? "center",
    emailPage_headlineFontSize:
      pageSettings?.emailPage_headlineFontSize ?? "24",
    emailPage_subheadlineAlignment:
      pageSettings?.emailPage_subheadlineAlignment ?? "center",
    emailPage_subheadlineFontSize:
      pageSettings?.emailPage_subheadlineFontSize ?? "16",
  };
};

const getDefaultValues2 = (values) => {
  const pageSettingsJSON = values?.pageSettings;
  const pageSettings = pageSettingsJSON ? JSON.parse(pageSettingsJSON) : {};

  return {
    type: values?.type || FlowNodeType.Simple,
    conditions: pageSettings?.conditions || "{}",
  };
};

const HeadlineSettings = ({ control }) => {
  return (
    <>
      <Section>
        <Controller
          control={control}
          name="emailPage_headlineAlignment"
          render={({ field: { value, onChange, ...field } }) => (
            <InlineStack
              align="space-between"
              blockAlign="center"
              wrap={false}
              gap="100"
            >
              <RadioButton
                label={<Icon source={TextAlignLeftIcon} tone="base" />}
                checked={value === "left"}
                onChange={() => onChange("left")}
                {...field}
              />
              <RadioButton
                label={<Icon source={TextAlignCenterIcon} tone="base" />}
                checked={value === "center"}
                onChange={() => onChange("center")}
                {...field}
              />
              <RadioButton
                label={<Icon source={TextAlignRightIcon} tone="base" />}
                checked={value === "right"}
                onChange={() => onChange("right")}
                {...field}
              />
            </InlineStack>
          )}
        />
        <Controller
          control={control}
          name="emailPage_headlineFontSize"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Font Size"
              value={value}
              onChange={onChange}
              min={1}
              max={100}
              step={1}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {value}px
                </p>
              }
              {...field}
            />
          )}
        />
      </Section>
    </>
  );
};

const SubheadlineSettings = ({ control }) => {
  return (
    <>
      <Section>
        <Controller
          control={control}
          name="emailPage_subheadlineAlignment"
          render={({ field: { value, onChange, ...field } }) => (
            <InlineStack
              align="space-between"
              blockAlign="center"
              wrap={false}
              gap="100"
            >
              <RadioButton
                label={<Icon source={TextAlignLeftIcon} tone="base" />}
                checked={value === "left"}
                onChange={() => onChange("left")}
                {...field}
              />
              <RadioButton
                label={<Icon source={TextAlignCenterIcon} tone="base" />}
                checked={value === "center"}
                onChange={() => onChange("center")}
                {...field}
              />
              <RadioButton
                label={<Icon source={TextAlignRightIcon} tone="base" />}
                checked={value === "right"}
                onChange={() => onChange("right")}
                {...field}
              />
            </InlineStack>
          )}
        />
        <Controller
          control={control}
          name="emailPage_subheadlineFontSize"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Font Size"
              value={value}
              onChange={onChange}
              min={1}
              max={100}
              step={1}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {value}px
                </p>
              }
              {...field}
            />
          )}
        />
      </Section>
    </>
  );
};

const ConditionsSettings = ({ control }) => {
  const { field } = useController({
    name: "conditions",
    control,
    defaultValue: JSON.stringify({}),
  });

  const [conditions, setConditions] = useState(() => {
    try {
      const val = JSON.parse(field.value);
      if (val?.conditions) {
        return val.conditions;
      }
      return [];
    } catch (error) {
      return [];
    }
  });

  const [showSkip, setShowSkip] = useState(() => {
    try {
      const val = JSON.parse(field.value);
      if (val?.showSkip) {
        return val.showSkip;
      }
      return "skip";
    } catch (error) {
      return "skip";
    }
  });

  const [anyAll, setAnyAll] = useState(() => {
    try {
      const val = JSON.parse(field.value);
      if (val?.anyAll) {
        return val.anyAll;
      }
      return "any";
    } catch (error) {
      return "any";
    }
  });

  useEffect(() => {
    try {
      const parsed = JSON.parse(field.value);
      setConditions(parsed?.conditions || []);
      setShowSkip(parsed?.showSkip || "skip");
      setAnyAll(parsed?.anyAll || "any");
    } catch (error) {
      setConditions([]);
      setShowSkip("skip");
      setAnyAll("any");
    }
  }, [field.value]);

  useEffect(() => {
    field.onChange(
      JSON.stringify({
        conditions,
        showSkip,
        anyAll,
      })
    );
  }, [conditions, showSkip, anyAll]);

  const addCondition = () => {
    setConditions((prev) => {
      return [
        ...prev,
        {
          id: uuidv4(),
          propertyId: "",
          verb: "is",
          match: "exactly",
          propertyValue: "",
        },
      ];
    });
  };

  const removeCondition = (id) => {
    setConditions((prev) => {
      return prev.filter((condition) => condition.id !== id);
    });
  };

  return (
    <Section>
      <Text as="p" variant="bodyMd">
        By default, the page is always shown. You can choose to show/skip it
        based on certain conditions.
      </Text>
      <InlineStack gap="100" align="start" blockAlign="center" wrap={true}>
        <Select
          label=""
          labelHidden
          options={[
            { label: "Skip", value: "skip" },
            { label: "Show", value: "show" },
          ]}
          value={showSkip}
          onChange={(v) => setShowSkip(v)}
        />{" "}
        the page if
        <Select
          label=""
          labelHidden
          options={[
            { label: "any", value: "any" },
            { label: "all", value: "all" },
          ]}
          value={anyAll}
          onChange={(v) => setAnyAll(v)}
        />{" "}
        of the following conditions are met:
      </InlineStack>
      <Button variant="secondary" onClick={addCondition} icon={PlusIcon}>
        Add Condition
      </Button>
      {conditions.map((condition) => {
        return (
          <BlockStack key={condition.id} gap="200">
            <TextField
              label="Property ID"
              labelHidden
              autoComplete=""
              value={condition.propertyId}
              onChange={(v) => {
                setConditions((prev) => {
                  return prev.map((c) => {
                    if (c.id === condition.id) {
                      return {
                        ...c,
                        propertyId: v,
                      };
                    }
                    return c;
                  });
                });
              }}
              placeholder="Property ID"
            />
            <InlineStack
              gap="100"
              align="start"
              blockAlign="center"
              wrap={true}
            >
              <Select
                label="verb"
                labelHidden
                options={[
                  { label: "is", value: "is" },
                  { label: "is not", value: "is_not" },
                ]}
                value={condition.verb}
                onChange={(v) => {
                  setConditions((prev) => {
                    return prev.map((c) => {
                      if (c.id === condition.id) {
                        return {
                          ...c,
                          verb: v,
                        };
                      }
                      return c;
                    });
                  });
                }}
              />
              <Select
                label="match"
                labelHidden
                options={[
                  { label: "exactly", value: "exactly" },
                  { label: "at least", value: "at_least" },
                  { label: "one of", value: "one_of" },
                  { label: "provided", value: "provided" },
                ]}
                value={condition.match}
                onChange={(v) => {
                  setConditions((prev) => {
                    return prev.map((c) => {
                      if (c.id === condition.id) {
                        return {
                          ...c,
                          match: v,
                        };
                      }
                      return c;
                    });
                  });
                }}
              />
            </InlineStack>
            {condition.match !== "provided" && (
              <TextField
                label="Property Value(s)"
                autoComplete=""
                labelHidden
                value={condition.propertyValue}
                onChange={(v) => {
                  setConditions((prev) => {
                    return prev.map((c) => {
                      if (c.id === condition.id) {
                        return {
                          ...c,
                          propertyValue: v,
                        };
                      }
                      return c;
                    });
                  });
                }}
                placeholder="Property Value(s)"
                helpText="If you want to include multiple values, separate them with a comma."
              />
            )}
            <Button
              variant="secondary"
              tone="critical"
              icon={DeleteIcon}
              onClick={() => removeCondition(condition.id)}
            >
              Remove Condition
            </Button>
          </BlockStack>
        );
      })}
    </Section>
  );
};

const GeneralDesignSettings = ({ control }) => {
  const topWidthDesktopType = useWatch({
    name: "emailPage_TopWidthType",
    control,
  });
  const bottomWidthDesktopType = useWatch({
    name: "emailPage_BottomWidthType",
    control,
  });
  const topWidthMobileType = useWatch({
    name: "emailPage_TopWidthMobileType",
    control,
  });
  const bottomWidthMobileType = useWatch({
    name: "emailPage_BottomWidthMobileType",
    control,
  });

  return (
    <>
      <Section
        title={
          (
            <InlineStack
              gap="100"
              align="center"
              blockAlign="center"
              wrap={false}
            >
              <Icon source={DesktopIcon as any} />
              <span style={{ flex: "1" }}>Desktop</span>
            </InlineStack>
          ) as any
        }
      >
        <Controller
          control={control}
          name="emailPage_TopWidthType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Top Section Width"
              value={value}
              {...field}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {topWidthDesktopType === "custom" && (
          <Controller
            control={control}
            name="emailPage_TopWidth"
            render={({ field: { value, onChange, ...field } }) => (
              <TextField
                label="Top Section Width"
                value={value}
                onChange={onChange}
                type="text"
                autoComplete="off"
                connectedLeft={
                  <span
                    style={{
                      position: "relative",
                      top: "10px",
                      display: "inline-block",
                      marginRight: "5px",
                    }}
                  >
                    <RangeSlider
                      label=""
                      value={value}
                      onChange={onChange}
                      min={0}
                      max={1500}
                      step={5}
                      output
                    />
                  </span>
                }
                suffix="px"
                {...field}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="emailPage_BottomWidthType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Bottom Section Width"
              value={value}
              {...field}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {bottomWidthDesktopType === "custom" && (
          <Controller
            control={control}
            name="emailPage_BottomWidth"
            render={({ field: { value, onChange, ...field } }) => (
              <TextField
                label="Bottom Section Width"
                value={value}
                onChange={onChange}
                type="text"
                autoComplete="off"
                connectedLeft={
                  <span
                    style={{
                      position: "relative",
                      top: "10px",
                      display: "inline-block",
                      marginRight: "5px",
                    }}
                  >
                    <RangeSlider
                      label=""
                      value={value}
                      onChange={onChange}
                      min={0}
                      max={1500}
                      step={5}
                      output
                    />
                  </span>
                }
                suffix="px"
                {...field}
              />
            )}
          />
        )}
      </Section>
      <Section
        title={
          (
            <InlineStack
              gap="100"
              align="center"
              blockAlign="center"
              wrap={false}
            >
              <Icon source={MobileIcon as any} />
              <span style={{ flex: "1" }}>Mobile</span>
            </InlineStack>
          ) as any
        }
      >
        <Controller
          control={control}
          name="emailPage_TopWidthMobileType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Top Section Width"
              value={value}
              {...field}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {topWidthMobileType === "custom" && (
          <Controller
            control={control}
            name="emailPage_TopWidthMobile"
            render={({ field: { value, onChange, ...field } }) => (
              <RangeSlider
                value={value}
                onChange={onChange}
                label=""
                min={0}
                max={1500}
                step={5}
                output
                suffix={
                  <p
                    style={{
                      minWidth: "24px",
                      textAlign: "right",
                    }}
                  >
                    {`${value}px`}
                  </p>
                }
                {...field}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="emailPage_BottomWidthMobileType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Bottom Section Width"
              value={value}
              {...field}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {bottomWidthMobileType === "custom" && (
          <Controller
            control={control}
            name="emailPage_BottomWidthMobile"
            render={({ field: { value, onChange, ...field } }) => (
              <RangeSlider
                value={value}
                onChange={onChange}
                label=""
                min={0}
                max={1500}
                step={5}
                output
                suffix={
                  <p
                    style={{
                      minWidth: "24px",
                      textAlign: "right",
                    }}
                  >
                    {`${value}px`}
                  </p>
                }
                {...field}
              />
            )}
          />
        )}
      </Section>
    </>
  );
};

const IntegrationSettings = ({ control }) => {
  return (
    <Section>
      <Text as="h4" variant="headingSm">
        Klaviyo Options
      </Text>
      <Controller
        control={control}
        name="emailPage_klaviyoEmailMarketing"
        render={({ field: { value, onChange, ...field } }) => (
          <Checkbox
            label="Subscribe to email marketing"
            checked={value}
            onChange={onChange}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="emailPage_klaviyoSMSMarketing"
        render={({ field: { value, onChange, ...field } }) => (
          <Checkbox
            label="Subscribe to SMS marketing"
            checked={value}
            onChange={onChange}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="emailPage_klaviyoSMSTransactional"
        render={({ field: { value, onChange, ...field } }) => (
          <Checkbox
            label="Subscribe to SMS transactional"
            checked={value}
            onChange={onChange}
            {...field}
          />
        )}
      />
    </Section>
  );
};

const PhoneNumberSettings = ({ control }) => {
  return (
    <Section>
      <Controller
        control={control}
        name="emailPage_phoneLabel"
        render={({ field: { value, onChange, ...field } }) => (
          <TextField
            label="Label"
            value={value}
            onChange={onChange}
            autoComplete="off"
            placeholder="e.g. Phone Number"
            helpText="Leave empty to hide the label"
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="emailPage_phonePlaceholder"
        render={({ field: { value, onChange, ...field } }) => (
          <TextField
            label="Placeholder"
            value={value}
            onChange={onChange}
            autoComplete="off"
            placeholder=""
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="emailPage_phoneDefaultCountry"
        render={({ field: { value, onChange, ...field } }) => (
          <TextField
            label="Default Country"
            value={value}
            onChange={onChange}
            autoComplete="off"
            placeholder="e.g. us"
            helpText={
              <>
                Use the 2-letter{" "}
                <Link url="https://www.iban.com/country-codes" target="_blank">
                  ISO 3166 ALPHA-2
                </Link>{" "}
                country code.
              </>
            }
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="emailPage_phoneOnlyCountries"
        render={({ field: { value, onChange, ...field } }) => (
          <TextField
            label="Only allow these countries"
            value={value}
            onChange={onChange}
            autoComplete="off"
            placeholder="e.g. us,ca"
            helpText={
              <>
                Use the 2-letter{" "}
                <Link url="https://www.iban.com/country-codes" target="_blank">
                  ISO 3166 ALPHA-2
                </Link>{" "}
                country codes, separated by commas.
              </>
            }
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="emailPage_phonePreferredCountries"
        render={({ field: { value, onChange, ...field } }) => (
          <TextField
            label="Countries to show first"
            value={value}
            onChange={onChange}
            autoComplete="off"
            placeholder="e.g. us,ca"
            helpText={
              <>
                Use the 2-letter{" "}
                <Link url="https://www.iban.com/country-codes" target="_blank">
                  ISO 3166 ALPHA-2
                </Link>{" "}
                country codes, separated by commas.
              </>
            }
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="emailPage_phoneExcludedCountries"
        render={({ field: { value, onChange, ...field } }) => (
          <TextField
            label="Forbidden countries"
            value={value}
            onChange={onChange}
            autoComplete="off"
            placeholder="e.g. kp,iq"
            helpText={
              <>
                Use the 2-letter{" "}
                <Link url="https://www.iban.com/country-codes" target="_blank">
                  ISO 3166 ALPHA-2
                </Link>{" "}
                country codes, separated by commas.
              </>
            }
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="emailPage_customMasks"
        render={({ field: { value, onChange, ...field } }) => (
          <TextField
            label="Custom masks"
            value={value}
            onChange={onChange}
            autoComplete="off"
            multiline={3}
            placeholder={`e.g. {"fr": "(...) ..-..-..", "at": "(....) ...-...."}`}
            helpText={
              <>
                This is an advanced setting for customizing the formatting of
                the phone number for various countries. Please provide the
                custom masks as a{" "}
                <Link
                  url="https://www.npmjs.com/package/react-phone-input-2#custom-masks"
                  target="_blank"
                >
                  valid JSON string
                </Link>
                .
              </>
            }
            {...field}
          />
        )}
      />
    </Section>
  );
};

const ButtonSettings = ({ control }) => {
  const useDefaultColorForButton = useWatch({
    name: "emailPage_useDefaultColorForButton",
    control,
  });
  const useDefaultTextColorForButton = useWatch({
    name: "emailPage_useDefaultTextColorForButton",
    control,
  });
  const emailPage_buttonWidthType = useWatch({
    name: "emailPage_buttonWidthType",
    control,
  });

  return (
    <Section>
      <Controller
        control={control}
        name="emailPage_buttonTextOverride"
        render={({ field: { value, onChange, ...field } }) => (
          <TextField
            label="Button Text Override"
            value={value}
            onChange={onChange}
            autoComplete="off"
            placeholder="leave empty to use default text"
            {...field}
          />
        )}
      />
      <InlineStack
        gap="200"
        align="space-between"
        blockAlign="end"
        wrap={false}
      >
        <Controller
          control={control}
          name="emailPage_useDefaultColorForButton"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Background Color"
              value={value}
              {...field}
              options={[
                { label: "Use default color", value: "yes" },
                { label: "Use custom color", value: "no" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {useDefaultColorForButton === "no" && (
          <Controller
            control={control}
            name="emailPage_buttonColor"
            render={({ field: { value, onChange, ...field } }) => (
              <ColorInput value={value} onChange={onChange} {...field} />
            )}
          />
        )}
      </InlineStack>
      <InlineStack
        gap="200"
        align="space-between"
        blockAlign="end"
        wrap={false}
      >
        <Controller
          control={control}
          name="emailPage_useDefaultTextColorForButton"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Text Color"
              value={value}
              {...field}
              options={[
                { label: "Use default color", value: "yes" },
                { label: "Use custom color", value: "no" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {useDefaultTextColorForButton === "no" && (
          <Controller
            control={control}
            name="emailPage_buttonTextColor"
            render={({ field: { value, onChange, ...field } }) => (
              <ColorInput value={value} onChange={onChange} {...field} />
            )}
          />
        )}
      </InlineStack>
      <Section
        title={
          (
            <InlineStack
              gap="100"
              align="center"
              blockAlign="center"
              wrap={false}
            >
              <Icon source={DesktopIcon as any} />
              <span style={{ flex: "1" }}>Desktop</span>
            </InlineStack>
          ) as any
        }
      >
        <Controller
          control={control}
          name="emailPage_buttonFontSize"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Font Size"
              value={value}
              onChange={onChange}
              min={8}
              max={50}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="emailPage_buttonBorderRadius"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Border Radius"
              value={value}
              onChange={onChange}
              min={0}
              max={60}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="emailPage_buttonWidthType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Width"
              value={value}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
                { label: "Auto Width", value: "auto" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {emailPage_buttonWidthType === "custom" && (
          <Controller
            control={control}
            name="emailPage_buttonWidth"
            render={({ field: { value, onChange, ...field } }) => (
              <RangeSlider
                value={value}
                onChange={onChange}
                label=""
                min={0}
                max={1500}
                step={5}
                output
                suffix={
                  <p
                    style={{
                      minWidth: "24px",
                      textAlign: "right",
                    }}
                  >
                    {`${value}px`}
                  </p>
                }
                {...field}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="emailPage_buttonHorizontalPadding"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Horizontal Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={500}
              step={5}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="emailPage_buttonVerticalPadding"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Vertical Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={150}
              step={1}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
      </Section>
      <Section
        title={
          (
            <InlineStack
              gap="100"
              align="center"
              blockAlign="center"
              wrap={false}
            >
              <Icon source={MobileIcon as any} />
              <span style={{ flex: "1" }}>Mobile</span>
            </InlineStack>
          ) as any
        }
      >
        <Controller
          control={control}
          name="emailPage_buttonFontSizeMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Font Size"
              value={value}
              onChange={onChange}
              min={8}
              max={50}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="emailPage_buttonBorderRadiusMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Border Radius"
              value={value}
              onChange={onChange}
              min={0}
              max={60}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="emailPage_buttonWidthMobileType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Width"
              value={value}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
                { label: "Auto Width", value: "auto" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {emailPage_buttonWidthType === "custom" && (
          <Controller
            control={control}
            name="emailPage_buttonWidthMobile"
            render={({ field: { value, onChange, ...field } }) => (
              <RangeSlider
                value={value}
                onChange={onChange}
                label=""
                min={0}
                max={780}
                step={5}
                output
                suffix={
                  <p
                    style={{
                      minWidth: "24px",
                      textAlign: "right",
                    }}
                  >
                    {`${value}px`}
                  </p>
                }
                {...field}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="emailPage_buttonHorizontalPaddingMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Horizontal Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={500}
              step={5}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="emailPage_buttonVerticalPaddingMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Vertical Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={150}
              step={1}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
      </Section>
    </Section>
  );
};

const ElementSettings = ({ control }) => {
  const gdprCheckbox = useWatch({ name: "gdprCheckbox", control });

  return (
    <Section>
      <Controller
        control={control}
        name="emailPage_displayEmailInput"
        render={({ field: { value, onChange, ...field } }) => (
          <Select
            label="Email Address Input"
            value={value}
            {...field}
            options={[
              { label: "Hide", value: "no" },
              { label: "Show (required)", value: "yes" },
              { label: "Show (optional)", value: "optional" },
            ]}
            onChange={onChange}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="emailPage_displayPhoneInput"
        render={({ field: { value, onChange, ...field } }) => (
          <Select
            label="Phone Number Input"
            value={value}
            {...field}
            options={[
              { label: "Hide", value: "no" },
              { label: "Show (required)", value: "yes" },
              { label: "Show (optional)", value: "optional" },
            ]}
            onChange={onChange}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="emailPage_displayEmailIcon"
        render={({ field: { value, onChange, ...field } }) => (
          <Select
            label="Email Icon"
            value={value}
            {...field}
            options={[
              { label: "Hide", value: "no" },
              { label: "Show", value: "yes" },
            ]}
            onChange={onChange}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="emailPage_displayHeadlineSubheadline"
        render={({ field: { value, onChange, ...field } }) => (
          <Select
            label="Headline & Subheadline"
            value={value}
            {...field}
            options={[
              { label: "Hide", value: "no" },
              { label: "Show", value: "yes" },
            ]}
            onChange={onChange}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="askForName"
        render={({ field: { value, ...field } }) => (
          <Select
            label="Name"
            value={value}
            {...field}
            options={[
              { label: "Hide", value: "no" },
              { label: "Show (required)", value: "required" },
              { label: "Show (optional)", value: "optional" },
            ]}
          />
        )}
      />
      <Controller
        control={control}
        name="gdprCheckbox"
        render={({ field: { value, ...field } }) => (
          <Select
            label="GDPR Checkbox"
            value={value}
            {...field}
            options={[
              { label: "Hide", value: "no" },
              { label: "Show - Mandatory (below the button)", value: "below" },
              { label: "Show - Mandatory (above the button)", value: "above" },
              {
                label: "Show - Optional (below the button)",
                value: "below-optional",
              },
              {
                label: "Show - Optional (above the button)",
                value: "above-optional",
              },
            ]}
          />
        )}
      />
      {gdprCheckbox !== "no" && (
        <Controller
          control={control}
          name="gdprText"
          render={({ field: { value, ...field } }) => (
            <TextField
              multiline={3}
              autoComplete="false"
              label="GDPR Text"
              value={value}
              {...field}
            />
          )}
        />
      )}
      {gdprCheckbox !== "no" && (
        <Controller
          control={control}
          name="gdprLink"
          render={({ field: { value, ...field } }) => (
            <TextField
              autoComplete="false"
              label="GDPR Link"
              value={value}
              {...field}
            />
          )}
        />
      )}
      <Controller
        control={control}
        name="hideNoThanks"
        render={({ field: { value, onChange, ...field } }) => (
          <Select
            label="No Thanks / Opt-In Skip Link"
            value={value === false ? "show" : "hide"}
            onChange={(v) => {
              onChange(v === "hide");
            }}
            options={[
              { label: "Show", value: "show" },
              { label: "Hide", value: "hide" },
            ]}
            {...field}
          />
        )}
      />
    </Section>
  );
};

export const EmailOptions = () => {
  const [settings, setSettings] = useState(0);

  const { control } = useOptionsForm({
    getDefaultValues,
    formatValues: (values) => ({
      ...values,
    }),
    pageSettingsKeys: [
      "emailPage_displayEmailInput",
      "emailPage_displayPhoneInput",
      "emailPage_buttonColor",
      "emailPage_useDefaultColorForButton",
      "emailPage_displayEmailIcon",
      "emailPage_displayHeadlineSubheadline",
      "emailPage_useDefaultTextColorForButton",
      "emailPage_buttonTextColor",
      "emailPage_buttonTextOverride",
      "emailPage_buttonFontSize",
      "emailPage_buttonHorizontalPadding",
      "emailPage_buttonVerticalPadding",
      "emailPage_buttonBorderRadius",
      "emailPage_buttonWidthType",
      "emailPage_buttonWidth",
      "emailPage_TopWidthType",
      "emailPage_TopWidth",
      "emailPage_BottomWidthType",
      "emailPage_BottomWidth",
      "emailPage_TopWidthMobileType",
      "emailPage_TopWidthMobile",
      "emailPage_BottomWidthMobileType",
      "emailPage_BottomWidthMobile",
      "emailPage_buttonFontSizeMobile",
      "emailPage_buttonBorderRadiusMobile",
      "emailPage_buttonHorizontalPaddingMobile",
      "emailPage_buttonVerticalPaddingMobile",
      "emailPage_buttonWidthMobileType",
      "emailPage_buttonWidthMobile",
      "emailPage_phoneLabel",
      "emailPage_phonePlaceholder",
      "emailPage_phoneDefaultCountry",
      "emailPage_phoneOnlyCountries",
      "emailPage_phoneExcludedCountries",
      "emailPage_customMasks",
      "emailPage_phonePreferredCountries",
      "emailPage_klaviyoEmailMarketing",
      "emailPage_klaviyoSMSMarketing",
      "emailPage_klaviyoSMSTransactional",
      "emailPage_headlineAlignment",
      "emailPage_headlineFontSize",
      "emailPage_subheadlineAlignment",
      "emailPage_subheadlineFontSize",
    ],
    type: "flow",
  });

  const { control: control2 } = useOptionsForm({
    getDefaultValues: getDefaultValues2,
    formatValues: (values) => ({
      ...values,
    }),
    pageSettingsKeys: ["conditions"],
    type: "flowNode",
  });

  return (
    <SettingsContainer>
      <BlockStack align="start" inlineAlign="stretch" gap="200">
        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 1 ? "critical" : undefined}
          disclosure={settings === 1 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 1 ? 0 : 1))}
          icon={ThemeTemplateIcon as any}
        >
          Show/Hide Page Elements
        </Button>
        <Collapsible
          open={settings === 1}
          id="collapsible-1"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <ElementSettings control={control} />
        </Collapsible>
        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 2 ? "critical" : undefined}
          disclosure={settings === 2 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 2 ? 0 : 2))}
          icon={PaintBrushFlatIcon}
        >
          General Design
        </Button>
        <Collapsible
          open={settings === 2}
          id="collapsible-2"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <GeneralDesignSettings control={control} />
        </Collapsible>
        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 3 ? "critical" : undefined}
          disclosure={settings === 3 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 3 ? 0 : 3))}
          icon={TextFontListIcon}
        >
          Headline Design
        </Button>
        <Collapsible
          open={settings === 3}
          id="collapsible-3"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <HeadlineSettings control={control} />
        </Collapsible>
        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 4 ? "critical" : undefined}
          disclosure={settings === 4 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 4 ? 0 : 4))}
          icon={TextFontListIcon}
        >
          Sub-headline Design
        </Button>
        <Collapsible
          open={settings === 4}
          id="collapsible-4"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <SubheadlineSettings control={control} />
        </Collapsible>
        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 5 ? "critical" : undefined}
          disclosure={settings === 5 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 5 ? 0 : 5))}
          icon={ButtonIcon}
        >
          Button Design
        </Button>
        <Collapsible
          open={settings === 5}
          id="collapsible-5"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <ButtonSettings control={control} />
        </Collapsible>
        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 6 ? "critical" : undefined}
          disclosure={settings === 6 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 6 ? 0 : 6))}
          icon={PhoneIcon as any}
        >
          Phone Number Settings
        </Button>
        <Collapsible
          open={settings === 6}
          id="collapsible-6"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <PhoneNumberSettings control={control} />
        </Collapsible>
        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 7 ? "critical" : undefined}
          disclosure={settings === 7 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 7 ? 0 : 7))}
          icon={AppsIcon as any}
        >
          Integration Settings
        </Button>
        <Collapsible
          open={settings === 7}
          id="collapsible-7"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <IntegrationSettings control={control} />
        </Collapsible>
        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 8 ? "critical" : undefined}
          disclosure={settings === 8 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 8 ? 0 : 8))}
          icon={ConnectIcon as any}
        >
          Skip/Show Conditions
        </Button>
        <Collapsible
          open={settings === 8}
          id="collapsible-8"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <ConditionsSettings control={control2} />
        </Collapsible>
      </BlockStack>
    </SettingsContainer>
  );
};

const SettingsContainer = styled.div`
  padding: 15px 5px;

  & .Polaris-Button__Content {
    width: 100%;
    justify-content: space-between !important;
  }
`;
