import { Controller, useWatch } from "react-hook-form";
import { LayoutInput } from "~/components/LayoutInput";
import { Section } from "~/components/Section";
import { FlowNodeLayout } from "~/graphql/sdk";
import { FlowNodeType } from "~/graphql/sdk";
import { useOptionsForm } from "~/hooks";
import styled from "styled-components";
import {
  BlockStack,
  Button,
  Collapsible,
  Icon,
  InlineStack,
  RadioButton,
  RangeSlider,
  Select,
  TextField,
} from "@shopify/polaris";
import {
  ButtonIcon,
  DesktopIcon,
  MobileIcon,
  PaintBrushFlatIcon,
  TextAlignCenterIcon,
  TextAlignLeftIcon,
  TextAlignRightIcon,
  TextFontListIcon,
} from "@shopify/polaris-icons";
import { ColorInput } from "~/components/ColorInput";
import { useState } from "react";

const getDefaultValues = (values) => {
  const pageSettingsJSON = values?.pageSettings;
  const pageSettings = pageSettingsJSON ? JSON.parse(pageSettingsJSON) : {};

  return {
    layout: values?.layout || FlowNodeLayout.Simple,
    type: values?.type || FlowNodeType.InputOneLineText,
    isRequired:
      typeof values?.isRequired !== "undefined" ? !!values.isRequired : false,
    maxLength: values?.maxLength ?? "3",
    inputWidth: values?.inputWidth ?? "",
    inputHeight: values?.inputHeight ?? "",
    conditions: pageSettings?.conditions ?? "{}",
    questionAlignment: pageSettings?.questionAlignment ?? "left",
    questionFontSize: pageSettings?.questionFontSize ?? "32",
    descriptionAlignment: pageSettings?.descriptionAlignment ?? "left",
    descriptionFontSize: pageSettings?.descriptionFontSize ?? "16",
    buttonColor: pageSettings?.buttonColor ?? "#000",
    useDefaultColorForButton: pageSettings?.useDefaultColorForButton ?? "yes",
    buttonTextColor: pageSettings?.buttonTextColor ?? "#fff",
    useDefaultTextColorForButton:
      pageSettings?.useDefaultTextColorForButton ?? "yes",
    buttonTextOverride: pageSettings?.buttonTextOverride ?? "",
    buttonFontSize: pageSettings?.buttonFontSize ?? "18",
    buttonHorizontalPadding: pageSettings?.buttonHorizontalPadding ?? "56",
    buttonVerticalPadding: pageSettings?.buttonVerticalPadding ?? "20",
    buttonBorderRadius: pageSettings?.buttonBorderRadius ?? "8",
    buttonWidthType: pageSettings?.buttonWidthType ?? "custom",
    buttonWidth: pageSettings?.buttonWidth ?? "370",
    buttonFontSizeMobile: pageSettings?.buttonFontSizeMobile ?? "18",
    buttonBorderRadiusMobile: pageSettings?.buttonBorderRadiusMobile ?? "8",
    buttonHorizontalPaddingMobile:
      pageSettings?.buttonHorizontalPaddingMobile ?? "56",
    buttonVerticalPaddingMobile:
      pageSettings?.buttonVerticalPaddingMobile ?? "20",
    buttonWidthMobileType: pageSettings?.buttonWidthMobileType ?? "100%",
    buttonWidthMobile: pageSettings?.buttonWidthMobile ?? "345",
    buttonAlignment: pageSettings?.buttonAlignment ?? "left",
    topWidthDesktopType: pageSettings?.topWidthDesktopType ?? "100%",
    topWidth: pageSettings?.topWidth ?? "1200",
    bottomWidthDesktopType: pageSettings?.bottomWidthDesktopType ?? "100%",
    bottomWidth: pageSettings?.bottomWidth ?? "1200",
    topWidthMobileType: pageSettings?.topWidthMobileType ?? "100%",
    topWidthMobile: pageSettings?.topWidthMobile ?? "1200",
    bottomWidthMobileType: pageSettings?.bottomWidthMobileType ?? "100%",
    bottomWidthMobile: pageSettings?.bottomWidthMobile ?? "1200",
  };
};

const ButtonSettings = ({ control }) => {
  const useDefaultColorForButton = useWatch({
    name: "useDefaultColorForButton",
    control,
  });
  const useDefaultTextColorForButton = useWatch({
    name: "useDefaultTextColorForButton",
    control,
  });
  const buttonWidthType = useWatch({
    name: "buttonWidthType",
    control,
  });

  return (
    <Section>
      <Controller
        control={control}
        name="buttonTextOverride"
        render={({ field: { value, onChange, ...field } }) => (
          <TextField
            label="Button Text Override"
            value={value}
            onChange={onChange}
            autoComplete="off"
            placeholder="leave empty to use default text"
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="buttonAlignment"
        render={({ field: { value, onChange, ...field } }) => (
          <InlineStack
            align="space-between"
            blockAlign="center"
            wrap={false}
            gap="100"
          >
            <RadioButton
              label={<Icon source={TextAlignLeftIcon as any} tone="base" />}
              checked={value === "left"}
              onChange={() => onChange("left")}
              {...field}
            />
            <RadioButton
              label={<Icon source={TextAlignCenterIcon as any} tone="base" />}
              checked={value === "center"}
              onChange={() => onChange("center")}
              {...field}
            />
            <RadioButton
              label={<Icon source={TextAlignRightIcon as any} tone="base" />}
              checked={value === "right"}
              onChange={() => onChange("right")}
              {...field}
            />
          </InlineStack>
        )}
      />
      <InlineStack
        gap="200"
        align="space-between"
        blockAlign="end"
        wrap={false}
      >
        <Controller
          control={control}
          name="useDefaultColorForButton"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Background Color"
              value={value}
              {...field}
              options={[
                { label: "Use default color", value: "yes" },
                { label: "Use custom color", value: "no" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />

        {useDefaultColorForButton === "no" && (
          <Controller
            control={control}
            name="buttonColor"
            render={({ field: { value, onChange, ...field } }) => (
              <ColorInput value={value} onChange={onChange} {...field} />
            )}
          />
        )}
      </InlineStack>
      <InlineStack
        gap="200"
        align="space-between"
        blockAlign="end"
        wrap={false}
      >
        <Controller
          control={control}
          name="useDefaultTextColorForButton"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Text Color"
              value={value}
              {...field}
              options={[
                { label: "Use default color", value: "yes" },
                { label: "Use custom color", value: "no" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {useDefaultTextColorForButton === "no" && (
          <Controller
            control={control}
            name="buttonTextColor"
            render={({ field: { value, onChange, ...field } }) => (
              <ColorInput value={value} onChange={onChange} {...field} />
            )}
          />
        )}
      </InlineStack>
      <Section
        title={
          (
            <InlineStack
              align="center"
              blockAlign="center"
              gap="100"
              wrap={false}
            >
              <Icon source={DesktopIcon as any} />
              <span style={{ flex: "1" }}>Desktop</span>
            </InlineStack>
          ) as any
        }
      >
        <Controller
          control={control}
          name="buttonFontSize"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Font Size"
              value={value}
              onChange={onChange}
              min={8}
              max={50}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="buttonBorderRadius"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Border Radius"
              value={value}
              onChange={onChange}
              min={0}
              max={60}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="buttonWidthType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Width"
              value={value}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
                { label: "Auto Width", value: "auto" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {buttonWidthType === "custom" && (
          <Controller
            control={control}
            name="buttonWidth"
            render={({ field: { value, onChange, ...field } }) => (
              <RangeSlider
                value={value}
                onChange={onChange}
                label=""
                min={0}
                max={1500}
                step={5}
                output
                suffix={
                  <p
                    style={{
                      minWidth: "24px",
                      textAlign: "right",
                    }}
                  >
                    {`${value}px`}
                  </p>
                }
                {...field}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="buttonHorizontalPadding"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Horizontal Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={500}
              step={5}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="buttonVerticalPadding"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Vertical Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={150}
              step={1}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
      </Section>
      <Section
        title={
          (
            <InlineStack
              gap="100"
              align="center"
              blockAlign="center"
              wrap={false}
            >
              <Icon source={MobileIcon as any} />
              <span style={{ flex: "1" }}>Mobile</span>
            </InlineStack>
          ) as any
        }
      >
        <Controller
          control={control}
          name="buttonFontSizeMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Font Size"
              value={value}
              onChange={onChange}
              min={8}
              max={50}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="buttonBorderRadiusMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Border Radius"
              value={value}
              onChange={onChange}
              min={0}
              max={60}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="buttonWidthMobileType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Width"
              value={value}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
                { label: "Auto Width", value: "auto" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {buttonWidthType === "custom" && (
          <Controller
            control={control}
            name="buttonWidthMobile"
            render={({ field: { value, onChange, ...field } }) => (
              <RangeSlider
                value={value}
                onChange={onChange}
                label=""
                min={0}
                max={780}
                step={5}
                output
                suffix={
                  <p
                    style={{
                      minWidth: "24px",
                      textAlign: "right",
                    }}
                  >
                    {`${value}px`}
                  </p>
                }
                {...field}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="buttonHorizontalPaddingMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Horizontal Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={500}
              step={5}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="buttonVerticalPaddingMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Vertical Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={150}
              step={1}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
      </Section>
    </Section>
  );
};

const GeneralDesignSettings = ({ control }) => {
  const topWidthMobileType = useWatch({
    name: "topWidthMobileType",
    control,
  });
  const bottomWidthMobileType = useWatch({
    name: "bottomWidthMobileType",
    control,
  });
  const topWidthDesktopType = useWatch({
    name: "topWidthDesktopType",
    control,
  });
  const bottomWidthDesktopType = useWatch({
    name: "bottomWidthDesktopType",
    control,
  });

  return (
    <>
      <Section>
        <Controller
          control={control}
          name="layout"
          render={({ field: { ref, ...field } }) => <LayoutInput {...field} />}
        />
      </Section>
      <Section
        title={
          (
            <InlineStack
              gap="100"
              align="center"
              blockAlign="center"
              wrap={false}
            >
              <Icon source={DesktopIcon} />
              <span style={{ flex: "1" }}>Desktop</span>
            </InlineStack>
          ) as any
        }
      >
        <Controller
          control={control}
          name="topWidthDesktopType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Top Section Width"
              value={value}
              {...field}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {topWidthDesktopType === "custom" && (
          <Controller
            control={control}
            name="topWidth"
            render={({ field: { value, onChange, ...field } }) => (
              <TextField
                label=""
                value={value}
                onChange={onChange}
                type="text"
                autoComplete="off"
                connectedLeft={
                  <span
                    style={{
                      position: "relative",
                      top: "10px",
                      display: "inline-block",
                      marginRight: "5px",
                    }}
                  >
                    <RangeSlider
                      label=""
                      value={value}
                      onChange={onChange}
                      min={0}
                      max={1500}
                      step={5}
                      output
                    />
                  </span>
                }
                suffix="px"
                {...field}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="bottomWidthDesktopType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Bottom Section Width"
              value={value}
              {...field}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {bottomWidthDesktopType === "custom" && (
          <Controller
            control={control}
            name="bottomWidth"
            render={({ field: { value, onChange, ...field } }) => (
              <TextField
                label=""
                value={value}
                onChange={onChange}
                type="text"
                autoComplete="off"
                connectedLeft={
                  <span
                    style={{
                      position: "relative",
                      top: "10px",
                      display: "inline-block",
                      marginRight: "5px",
                    }}
                  >
                    <RangeSlider
                      label=""
                      value={value}
                      onChange={onChange}
                      min={0}
                      max={1500}
                      step={5}
                      output
                    />
                  </span>
                }
                suffix="px"
                {...field}
              />
            )}
          />
        )}
      </Section>
      <Section
        title={
          (
            <InlineStack
              gap="100"
              align="center"
              blockAlign="center"
              wrap={false}
            >
              <Icon source={MobileIcon} />
              <span style={{ flex: "1" }}>Mobile</span>
            </InlineStack>
          ) as any
        }
      >
        <Controller
          control={control}
          name="topWidthMobileType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Top Section Width"
              value={value}
              {...field}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {topWidthMobileType === "custom" && (
          <Controller
            control={control}
            name="topWidthMobile"
            render={({ field: { value, onChange, ...field } }) => (
              <RangeSlider
                value={value}
                onChange={onChange}
                label=""
                min={0}
                max={1500}
                step={5}
                output
                suffix={
                  <p
                    style={{
                      minWidth: "24px",
                      textAlign: "right",
                    }}
                  >
                    {`${value}px`}
                  </p>
                }
                {...field}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="bottomWidthMobileType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Bottom Section Width"
              value={value}
              {...field}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {bottomWidthMobileType === "custom" && (
          <Controller
            control={control}
            name="bottomWidthMobile"
            render={({ field: { value, onChange, ...field } }) => (
              <RangeSlider
                value={value}
                onChange={onChange}
                label=""
                min={0}
                max={1500}
                step={5}
                output
                suffix={
                  <p
                    style={{
                      minWidth: "24px",
                      textAlign: "right",
                    }}
                  >
                    {`${value}px`}
                  </p>
                }
                {...field}
              />
            )}
          />
        )}
      </Section>
    </>
  );
};

const SubheadlineSettings = ({ control }) => {
  return (
    <>
      <Section>
        <Controller
          control={control}
          name="descriptionAlignment"
          render={({ field: { value, onChange, ...field } }) => (
            <InlineStack
              align="space-between"
              blockAlign="center"
              wrap={false}
              gap="100"
            >
              <RadioButton
                label={<Icon source={TextAlignLeftIcon} tone="base" />}
                checked={value === "left"}
                onChange={() => onChange("left")}
                {...field}
              />
              <RadioButton
                label={<Icon source={TextAlignCenterIcon} tone="base" />}
                checked={value === "center"}
                onChange={() => onChange("center")}
                {...field}
              />
              <RadioButton
                label={<Icon source={TextAlignRightIcon} tone="base" />}
                checked={value === "right"}
                onChange={() => onChange("right")}
                {...field}
              />
            </InlineStack>
          )}
        />
        <Controller
          control={control}
          name="descriptionFontSize"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Font Size"
              value={value}
              onChange={onChange}
              min={1}
              max={100}
              step={1}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {value}px
                </p>
              }
              {...field}
            />
          )}
        />
      </Section>
    </>
  );
};

const HeadlineSettings = ({ control }) => {
  return (
    <>
      <Section>
        <Controller
          control={control}
          name="questionAlignment"
          render={({ field: { value, onChange, ...field } }) => (
            <InlineStack
              align="space-between"
              blockAlign="center"
              wrap={false}
              gap="100"
            >
              <RadioButton
                label={<Icon source={TextAlignLeftIcon as any} tone="base" />}
                checked={value === "left"}
                onChange={() => onChange("left")}
                {...field}
              />
              <RadioButton
                label={<Icon source={TextAlignCenterIcon as any} tone="base" />}
                checked={value === "center"}
                onChange={() => onChange("center")}
                {...field}
              />
              <RadioButton
                label={<Icon source={TextAlignRightIcon as any} tone="base" />}
                checked={value === "right"}
                onChange={() => onChange("right")}
                {...field}
              />
            </InlineStack>
          )}
        />
        <Controller
          control={control}
          name="questionFontSize"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Font Size"
              value={value}
              onChange={onChange}
              min={1}
              max={100}
              step={1}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {value}px
                </p>
              }
              {...field}
            />
          )}
        />
      </Section>
    </>
  );
};

export const WelcomeOptions = () => {
  const { control } = useOptionsForm({
    getDefaultValues,
    pageSettingsKeys: [
      "conditions",
      "questionAlignment",
      "questionFontSize",
      "descriptionAlignment",
      "descriptionFontSize",
      "buttonColor",
      "useDefaultColorForButton",
      "useDefaultTextColorForButton",
      "buttonTextColor",
      "buttonTextOverride",
      "buttonFontSize",
      "buttonHorizontalPadding",
      "buttonVerticalPadding",
      "buttonBorderRadius",
      "buttonWidthType",
      "buttonWidth",
      "buttonFontSizeMobile",
      "buttonBorderRadiusMobile",
      "buttonHorizontalPaddingMobile",
      "buttonVerticalPaddingMobile",
      "buttonWidthMobileType",
      "buttonWidthMobile",
      "buttonAlignment",
      "topWidth",
      "topWidthDesktopType",
      "bottomWidth",
      "bottomWidthDesktopType",
      "topWidthMobileType",
      "topWidthMobile",
      "bottomWidthMobileType",
      "bottomWidthMobile",
    ],
    type: "flowNode",
  });
  const [settings, setSettings] = useState(2);

  return (
    <SettingsContainer>
      <BlockStack align="start" inlineAlign="stretch" gap="200">
        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 2 ? "critical" : undefined}
          disclosure={settings === 2 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 2 ? 0 : 2))}
          icon={PaintBrushFlatIcon}
        >
          General Design
        </Button>
        <Collapsible
          open={settings === 2}
          id="collapsible-2"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <GeneralDesignSettings control={control} />
        </Collapsible>
        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 3 ? "critical" : undefined}
          disclosure={settings === 3 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 3 ? 0 : 3))}
          icon={TextFontListIcon}
        >
          Headline Design
        </Button>
        <Collapsible
          open={settings === 3}
          id="collapsible-3"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <HeadlineSettings control={control} />
        </Collapsible>
        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 4 ? "critical" : undefined}
          disclosure={settings === 4 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 4 ? 0 : 4))}
          icon={TextFontListIcon}
        >
          Sub-headline Design
        </Button>
        <Collapsible
          open={settings === 4}
          id="collapsible-4"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <SubheadlineSettings control={control} />
        </Collapsible>
        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 5 ? "critical" : undefined}
          disclosure={settings === 5 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 5 ? 0 : 5))}
          icon={ButtonIcon}
        >
          Button Design
        </Button>
        <Collapsible
          open={settings === 5}
          id="collapsible-5"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <ButtonSettings control={control} />
        </Collapsible>
      </BlockStack>
    </SettingsContainer>
  );
};

const SettingsContainer = styled.div`
  padding: 15px 5px;

  & .Polaris-Button__Content {
    width: 100%;
    justify-content: space-between !important;
  }
`;
