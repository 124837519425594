import React from "react";
import { Banner, Modal, BlockStack } from "@shopify/polaris";
import {
  PaintBrushFlatIcon,
  QuestionCircleIcon,
  AppsIcon,
  ChartFunnelIcon,
  ContentIcon,
  MergeIcon,
} from "@shopify/polaris-icons";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router";
import DescriptionCard from "~/components/BillingAlertModal/DescriptionCard";

const ModalWrapper = styled.div`
  [class~="Polaris-Modal-Section"] {
    padding-bottom: 0;
  }
`;

const CardLine = styled.div`
  margin-bottom: 1.8rem;
  display: flex;

  [class~="Polaris-Card"] + [class~="Polaris-Card"] {
    margin-top: 0;
    margin-left: 1.8rem;
  }

  & > * {
    flex-basis: 50%;
  }
`;

const PurpleCardLine = styled(CardLine)`
  & * {
    fill: #6138fe;
  }
`;

interface BillingModalProps {
  open: boolean;
  setClose: () => void;
  isInitial: boolean;
  setIsInitial: React.Dispatch<boolean>;
  customMessage?: string | null;
}

const BillingModal: React.FC<BillingModalProps> = ({
  open,
  setClose,
  isInitial,
  setIsInitial,
  customMessage = null,
}) => {
  const history = useHistory();
  const location = useLocation();

  const onClose = () => {
    if (isInitial) {
      const homeUrl =
        location.pathname + location.search.replace("main=1", "main=0");

      setIsInitial(false);
      history.push(homeUrl);
    }
    setClose();
  };

  const closeOnAction = () => {
    isInitial && setIsInitial(false);
    setClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={
        "Please upgrade to a paid plan to access these advanced features. Start with a 14-day free trial."
      }
      primaryAction={{
        content: "Start your 14-day free trial",
        onAction() {
          window?.top?.frames?.["app-iframe"]?.postMessage(
            {
              action: "openBilling",
            },
            "*"
          );
          closeOnAction();
        },
      }}
    >
      <ModalWrapper>
        <Modal.Section>
          <BlockStack gap="100">
            {customMessage && (
              <Banner
                title={"Feature only available on paid plans"}
                tone="critical"
              >
                {customMessage}
              </Banner>
            )}
            <PurpleCardLine>
              <DescriptionCard
                icon={QuestionCircleIcon}
                title={"Unlimited Questions"}
                content={
                  "Unlimited questions and answers for your quizzes, including multi-choice, open-ended, and transition screens."
                }
              />
              <DescriptionCard
                icon={PaintBrushFlatIcon}
                title={"CSS Editing"}
                content={
                  "Fully customize your quiz to perfectly match your store design and theme using the full CSS editor."
                }
              />
            </PurpleCardLine>
            <PurpleCardLine>
              <DescriptionCard
                icon={MergeIcon}
                title={"Conditional Logic"}
                content={
                  "Use conditional logic jumps to create complex quizzes. Only show questions that are relevant to each customer."
                }
              />
              <DescriptionCard
                icon={ContentIcon}
                title={"HTML Content Blocks"}
                content={
                  "Display more than just product recommendations and questions - include images, custom text and more on each screen."
                }
              />
            </PurpleCardLine>
            <PurpleCardLine>
              <DescriptionCard
                icon={ChartFunnelIcon}
                title={"Advanced Analytics"}
                content={
                  "Track how your quizzes are performing with advanced analytics, including completion rates, and question engagement."
                }
              />
              <DescriptionCard
                icon={AppsIcon}
                title={"30+ App Integrations"}
                content={
                  "Lantern integrates with your favorite apps, including email marketing, susbcriptions, reviews and more."
                }
              />
            </PurpleCardLine>
          </BlockStack>
        </Modal.Section>
      </ModalWrapper>
    </Modal>
  );
};

export default BillingModal;
