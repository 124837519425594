// import { ComponentProps } from "react";
import { TextField } from "@shopify/polaris";
import { Controller } from "react-hook-form";

// type TextFieldProps = Omit<ComponentProps<typeof TextField>, "onChange">;

// interface InputProps extends TextFieldProps {
//   control: any;
//   name: string;
// }

export const Input = ({ name, control, ...props }: any) => (
  <Controller
    name={name}
    control={control}
    render={({ field }: any) => {
      const { ref, ...fieldProps } = field;
      return (
        <TextField
          {...fieldProps}
          onChange={(value) => field.onChange(value)}
          {...props}
          autoComplete={props.autoComplete || "off"}
        />
      );
    }}
  />
);
