import { useEffect, useRef, useState } from "react";
import { Box, Flex, Icon as IconUI, Text, css } from "@storyofams/react-ui";
import equal from "fast-deep-equal";
import { motion } from "framer-motion";
import { find } from "lodash";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import {
  SortableContainer,
  SortableElement,
  SortableElementProps,
} from "react-sortable-hoc";

import QuestionContentBlockModal from "~/components/preview/QuestionContentBlockModal";
import QuestionContentBlocksList from "~/components/preview/QuestionContentBlocksList";

import { Appear, MediaInput } from "~/components";
import {
  Button,
  Input,
  MultiLineInput,
  Link,
  OptionChoice,
  OptionImage,
  Subtext,
  Title,
  CenteredTitle,
  EditorController,
  CenteredSubtext,
} from "~/components/preview";
import { ReactComponent as email } from "~/components/preview/Icon/library/email.svg";
import config from "~/config";
import { File, FlowFragmentFragment, FlowNodeType } from "~/graphql/sdk";
import { useNavParams, useSdk } from "~/hooks";

import { messages } from "./messages";
import { OptionWrapper } from "./OptionWrapper";
import { useFormSync } from "./useFormSync";
import { useBillingWrapper } from "~/lib";
import { useQueryClient } from "react-query";

import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

interface QuestionFormProps {
  data: FlowFragmentFragment["nodes"][0];
  hasWelcome?: boolean;
  primaryColor?: string;
  total: number;
  hideNoThanks?: boolean;
  askForName?: string;
  gdprCheckbox?: string;
  gdprText?: string;
  gdprLink?: string;
  flowPageSettings?: any;
}

interface ExtendedSortableProps extends SortableElementProps {
  answersPerRowDesktop?: number;
  answersPerRowMobile?: number;
  pageSettings?: any;
}

const MotionBox = motion(Box) as any;

const SortableItem = SortableElement<ExtendedSortableProps>(
  ({ value, answersPerRowMobile, answersPerRowDesktop, pageSettings }: any) => {
    return (
      <OptionWrapper
        className="answer"
        answersPerRowMobile={answersPerRowMobile}
        answersPerRowDesktop={answersPerRowDesktop}
      >
        {value.type === "radio" ? (
          <OptionChoice {...value} />
        ) : (
          <OptionImage
            style={{ marginTop: 200 }}
            pageSettings={pageSettings}
            {...value}
          />
        )}
      </OptionWrapper>
    );
  }
);

const SortableList = SortableContainer(({ children, alignment }) => {
  return (
    <Flex
      className="all-answers"
      flexWrap="wrap"
      mx={-1}
      width="100%"
      css={{
        "&:hover .drag-handle": {
          opacity: 1,
        },
        justifyContent:
          alignment === "center"
            ? "center"
            : alignment === "right"
            ? "flex-end"
            : "flex-start",
      }}
    >
      {children}
    </Flex>
  );
});

const withoutImages = (options: any) =>
  options?.map(({ image, nextNode, nextAction, ...option }) => option);

const getDefaultValues = (flowNode) => ({
  title: flowNode?.title || "",
  description: flowNode?.description || "",
  options: withoutImages(flowNode?.options) || [],
});

const ContentBlockButton = ({ onClick }) => {
  return (
    <Box my={3} mx="auto" width="fit-content">
      <Button
        className="button-secondary"
        size="small"
        variant="secondary"
        primaryColor="#a79efe"
        plus={true}
        onClick={onClick}
        style={{
          border: "2px dashed #a79efe",
          backgroundColor: "transparent",
          borderRadius: "15px",
        }}
      >
        Add a Content Block
      </Button>
    </Box>
  );
};

export const QuestionForm = ({
  data: flowNode,
  hasWelcome,
  primaryColor,
  total,
  hideNoThanks,
  askForName,
  gdprCheckbox,
  gdprText,
  gdprLink,
  flowPageSettings = {},
}: QuestionFormProps) => {
  const intl = useIntl();
  const [{ question: current }, setNavParams] = useNavParams();
  const flowNodeId = useRef(flowNode?.id || "");

  const queryClient = useQueryClient();

  const [showContentBlockModal, setShowContentBlockModal] = useState(false);
  const [contentBlockLocation, setContentBlockLocation] = useState("top");
  const [contentBlockId, setContentBlockId] = useState(null);
  const [resultsContentBlocks, setResultsContentBlocks] = useState({});
  const [phoneNumber, setPhoneNumber] = useState(null as any);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const totalQuestions = total;

  const {
    control,
    register,
    getValues,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues: getDefaultValues(flowNode),
  });
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "options",
    keyName: "fieldKey",
  });

  const isEmail = flowNode?.type === FlowNodeType.Email;
  const isTransition = flowNode?.type === FlowNodeType.Transition;
  const isOpenEnded =
    flowNode?.type === FlowNodeType.InputOneLineText ||
    flowNode?.type === FlowNodeType.InputMultiLineText;

  const emailPage_useDefaultColorForButton =
    flowPageSettings?.emailPage_useDefaultColorForButton ?? "yes";
  const emailPage_buttonColor = flowPageSettings?.emailPage_buttonColor;
  const emailPage_useDefaultTextColorForButton =
    flowPageSettings?.emailPage_useDefaultTextColorForButton ?? "yes";
  const emailPage_buttonTextColor = flowPageSettings?.emailPage_buttonTextColor;
  const emailPage_displayHeadlineSubheadline =
    flowPageSettings?.emailPage_displayHeadlineSubheadline ?? "yes";
  const emailPage_displayEmailIcon =
    flowPageSettings?.emailPage_displayEmailIcon ?? "yes";
  const emailPage_buttonTextOverride =
    flowPageSettings?.emailPage_buttonTextOverride;
  const emailPage_buttonFontSize = flowPageSettings?.emailPage_buttonFontSize;
  const emailPage_TopWidthType =
    flowPageSettings?.emailPage_TopWidthType ?? "100%";
  const emailPage_BottomWidthType =
    flowPageSettings?.emailPage_BottomWidthType ?? "custom";
  const emailPage_TopWidth = flowPageSettings?.emailPage_TopWidth ?? 480;
  const emailPage_BottomWidth = flowPageSettings?.emailPage_BottomWidth ?? 370;
  const emailPage_BottomWidthMobileType =
    flowPageSettings?.emailPage_BottomWidthMobileType ?? "100%";
  const emailPage_BottomWidthMobile =
    flowPageSettings?.emailPage_BottomWidthMobile ?? "100%";
  const emailPage_TopWidthMobileType =
    flowPageSettings?.emailPage_TopWidthMobileType ?? "100%";
  const emailPage_TopWidthMobile =
    flowPageSettings?.emailPage_TopWidthMobile ?? "100%";
  const emailPage_buttonBorderRadius =
    flowPageSettings?.emailPage_buttonBorderRadius;
  const emailPage_buttonWidthType =
    flowPageSettings?.emailPage_buttonWidthType ?? "100%";
  const emailPage_buttonWidth =
    flowPageSettings?.emailPage_buttonWidth ?? "100%";
  const emailPage_buttonHorizontalPadding =
    flowPageSettings?.emailPage_buttonHorizontalPadding;
  const emailPage_buttonVerticalPadding =
    flowPageSettings?.emailPage_buttonVerticalPadding;
  const emailPage_buttonFontSizeMobile =
    flowPageSettings?.emailPage_buttonFontSizeMobile;
  const emailPage_buttonBorderRadiusMobile =
    flowPageSettings?.emailPage_buttonBorderRadiusMobile;
  const emailPage_buttonHorizontalPaddingMobile =
    flowPageSettings?.emailPage_buttonHorizontalPaddingMobile;
  const emailPage_buttonVerticalPaddingMobile =
    flowPageSettings?.emailPage_buttonVerticalPaddingMobile;
  const emailPage_buttonWidthMobileType =
    flowPageSettings?.emailPage_buttonWidthMobileType ?? "100%";
  const emailPage_buttonWidthMobile =
    flowPageSettings?.emailPage_buttonWidthMobile ?? "100%";
  const emailPage_displayEmailInput =
    flowPageSettings?.emailPage_displayEmailInput ?? "yes";
  const emailPage_displayPhoneInput =
    flowPageSettings?.emailPage_displayPhoneInput ?? "no";
  const emailPage_phoneLabel = flowPageSettings?.emailPage_phoneLabel;
  const emailPage_phonePlaceholder =
    flowPageSettings?.emailPage_phonePlaceholder;
  const emailPage_phoneDefaultCountry =
    flowPageSettings?.emailPage_phoneDefaultCountry;
  const emailPage_phoneOnlyCountries =
    flowPageSettings?.emailPage_phoneOnlyCountries;
  const emailPage_phoneExcludedCountries =
    flowPageSettings?.emailPage_phoneExcludedCountries;
  const emailPage_phonePreferredCountries =
    flowPageSettings?.emailPage_phonePreferredCountries;
  const emailPage_headlineFontSize =
    flowPageSettings?.emailPage_headlineFontSize ?? 24;
  const emailPage_subheadlineFontSize =
    flowPageSettings?.emailPage_subheadlineFontSize ?? 16;
  const emailPage_headlineAlignment =
    flowPageSettings?.emailPage_headlineAlignment ?? "center";
  const emailPage_subheadlineAlignment =
    flowPageSettings?.emailPage_subheadlineAlignment ?? "center";

  const inputFieldType = flowNode?.inputFieldType ?? "text";
  const maxLength = flowNode?.maxLength
    ? parseInt(flowNode?.maxLength)
    : undefined;
  const minLength = flowNode?.minLength
    ? parseInt(flowNode?.minLength)
    : undefined;

  const pageSettings = JSON.parse(flowNode?.pageSettings ?? "{}");

  const inputOne_buttonTextOverride = pageSettings?.inputOne_buttonTextOverride;
  const inputMulti_buttonTextOverride =
    pageSettings?.inputMulti_buttonTextOverride;

  /* button settings */
  const useDefaultColorForButton =
    pageSettings?.useDefaultColorForButton ?? "yes";
  const buttonColor = pageSettings?.buttonColor ?? "#000";
  const useDefaultTextColorForButton =
    pageSettings?.useDefaultTextColorForButton ?? "yes";
  const buttonTextColor = pageSettings?.buttonTextColor ?? "#fff";
  const buttonTextOverride = pageSettings?.buttonTextOverride ?? "";
  const buttonFontSize = pageSettings?.buttonFontSize ?? "18";
  const buttonBorderRadius = pageSettings?.buttonBorderRadius ?? "8";
  const buttonHorizontalPadding = pageSettings?.buttonHorizontalPadding ?? "56";
  const buttonVerticalPadding = pageSettings?.buttonVerticalPadding ?? "20";
  const buttonFontSizeMobile = pageSettings?.buttonFontSizeMobile ?? "18";
  const buttonBorderRadiusMobile =
    pageSettings?.buttonBorderRadiusMobile ?? "8";
  const buttonHorizontalPaddingMobile =
    pageSettings?.buttonHorizontalPaddingMobile ?? "56";
  const buttonVerticalPaddingMobile =
    pageSettings?.buttonVerticalPaddingMobile ?? "20";
  const buttonWidthMobileType = pageSettings?.buttonWidthMobileType ?? "100%";
  const buttonWidthMobile = pageSettings?.buttonWidthMobile ?? "345";
  const buttonWidthType = pageSettings?.buttonWidthType ?? "custom";
  const buttonWidth = pageSettings?.buttonWidth ?? "370";
  const alignment = pageSettings?.alignment ?? "left";
  const buttonAlignment =
    pageSettings?.buttonAlignment ??
    (isTransition || isOpenEnded ? "center" : "left");
  /* answers section settings */
  const answersAlignment = pageSettings?.answersAlignment ?? "left";
  const answersPerRowDesktop = pageSettings?.answersPerRowDesktop ?? "3";
  const answersPerRowMobile = pageSettings?.answersPerRowMobile ?? "1";
  /* general design settings */
  const topWidthDesktopType = pageSettings?.topWidthDesktopType ?? "100%";
  const topWidth = pageSettings?.topWidth ?? "1200";
  const bottomWidthDesktopType = pageSettings?.bottomWidthDesktopType ?? "100%";
  const bottomWidth = pageSettings?.bottomWidth ?? "1200";
  const topWidthMobileType = pageSettings?.topWidthMobileType ?? "100%";
  const topWidthMobile = pageSettings?.topWidthMobile ?? "1200";
  const bottomWidthMobileType = pageSettings?.bottomWidthMobileType ?? "100%";
  const bottomWidthMobile = pageSettings?.bottomWidthMobile ?? "1200";
  /* question settings */
  const questionAlignment = isEmail
    ? emailPage_headlineAlignment
    : pageSettings?.questionAlignment ??
      (isTransition || isOpenEnded ? "center" : "left");
  const questionFontSize = isEmail
    ? emailPage_headlineFontSize
    : pageSettings?.questionFontSize ?? "32";
  /* description settings */
  const descriptionAlignment = isEmail
    ? emailPage_subheadlineAlignment
    : pageSettings?.descriptionAlignment ?? (isTransition ? "center" : "left");
  const descriptionFontSize = isEmail
    ? emailPage_subheadlineFontSize
    : pageSettings?.descriptionFontSize ?? "16";

  useFormSync({ control, flowNodeId: flowNodeId.current });

  useEffect(() => {
    if (isDirty && !(flowNode as any)?.isModified) {
      const currentOptions = getValues("options");
      if (!equal(withoutImages(flowNode?.options), currentOptions)) {
        const newOptions = getDefaultValues(flowNode).options;

        setValue(
          "options",
          newOptions.map((newOption, idx) => ({
            ...newOption,
            label:
              currentOptions?.find(({ id }) => id === newOption.id)?.label ||
              (currentOptions?.length === newOptions?.length &&
              currentOptions?.[idx]?.id === "new" &&
              !newOption.label
                ? currentOptions?.[idx]?.label
                : "") ||
              newOption.label,
            description:
              currentOptions?.find(({ id }) => id === newOption.id)
                ?.description ||
              (currentOptions?.length === newOptions?.length &&
              currentOptions?.[idx]?.id === "new" &&
              !newOption.description
                ? currentOptions?.[idx]?.description
                : "") ||
              newOption.description,
            ...(fields?.[idx]?.fieldKey
              ? { fieldKey: fields[idx].fieldKey }
              : {}),
          }))
        );
      }
    }
  }, [flowNode]);

  const inputHeight = flowNode?.inputHeight;
  const inputWidth = flowNode?.inputWidth;

  const billingWrapper = useBillingWrapper({});

  const sdk = useSdk();

  const updateContentBlocks = async (contentBlocks) => {
    if (flowNodeId.current && sdk) {
      const result = await sdk.updateOneFlowNode({
        input: {
          id: flowNodeId.current,
          update: {
            contentBlocks: JSON.stringify(contentBlocks),
          },
        },
      });

      queryClient.invalidateQueries(["container"]);

      if (result?.updateOneFlowNode?.contentBlocks) {
        setResultsContentBlocks(
          JSON.parse(result?.updateOneFlowNode?.contentBlocks)
        );
      }
    }
  };

  const saveContentBlock = async (location, id, contentBlock) => {
    if (flowNodeId.current && sdk && contentBlock && location) {
      const existingContentBlocks = resultsContentBlocks?.[location] ?? [];

      const blockIndex = existingContentBlocks.findIndex(
        (cb) => cb?.id && cb.id === id
      );

      if (blockIndex >= 0) {
        existingContentBlocks[blockIndex] = contentBlock;
      } else {
        existingContentBlocks.push(contentBlock);
      }

      const result = await sdk.updateOneFlowNode({
        input: {
          id: flowNodeId.current,
          update: {
            contentBlocks: JSON.stringify({
              ...(resultsContentBlocks ?? {}),
              [location]: existingContentBlocks,
            }),
          },
        },
      });

      queryClient.invalidateQueries(["container"]);

      if (result?.updateOneFlowNode?.contentBlocks) {
        setResultsContentBlocks(
          JSON.parse(result?.updateOneFlowNode?.contentBlocks)
        );
      }
    }
  };

  useEffect(() => {
    if (flowNode?.contentBlocks) {
      setResultsContentBlocks(JSON.parse(flowNode?.contentBlocks || "{}"));
    }
  }, [flowNode?.contentBlocks]);

  const openContentBlockModal = (location) => {
    setContentBlockLocation(location);
    billingWrapper(setShowContentBlockModal)(true);
  };

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    move(oldIndex, newIndex);
  };

  if (!flowNode?.id) {
    return null;
  }

  const gdprTextParts = gdprText?.split(/<a>(.*?)<\/a>/);
  const gdprText1 = gdprTextParts?.[0] ?? "";
  const gdprLink1 = (
    <a href="#" style={{ textDecoration: "underline", cursor: "pointer" }}>
      {gdprTextParts?.[1] ?? ""}
    </a>
  );
  const gdprText2 = gdprTextParts?.[2] ?? "";
  const gdprBlock = (
    <Flex
      className="gdpr-container"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      mt={2}
      mb={2}
    >
      <label
        htmlFor="gdpr"
        className="gdpr-wrapper"
        style={{ fontSize: "12px", cursor: "pointer" }}
      >
        <input
          id="gdpr"
          type="checkbox"
          style={{
            borderRadius: "3px",
            verticalAlign: "-2px",
            transform: "scale(1.25)",
            marginRight: "3px",
            cursor: "pointer",
          }}
          className="gdpr-checkbox"
        />{" "}
        <span className="gdpr-text" style={{ fontSize: "12px" }}>
          {gdprText1}
          <span className="gdpr-link">{gdprLink1}</span>
          {gdprText2}
        </span>
      </label>
    </Flex>
  );

  const titleAndDescription = (
    <span className={!isEmail ? "question" : ""}>
      <EditorController
        name="title"
        control={control}
        element={Title}
        className={isEmail ? "email-heading" : "question-heading"}
        dir={isEmail ? "auto" : undefined}
        placeholder={
          isEmail
            ? intl.formatMessage(messages.emailTitle)
            : "Your question here ..."
        }
        style={{
          fontSize: `${questionFontSize}px`,
          lineHeight: 1.2,
          textAlign: questionAlignment,
        }}
      />

      <Box mt={2} mb={5}>
        <EditorController
          name="description"
          control={control}
          element={Subtext}
          className={isEmail ? "email-subheading" : "question-subheading"}
          dir={isEmail ? "auto" : undefined}
          placeholder={
            isEmail
              ? intl.formatMessage(messages.emailSubtext)
              : "Description (optional)"
          }
          style={{
            fontSize: `${descriptionFontSize}px`,
            lineHeight: 1.2,
            textAlign: descriptionAlignment,
          }}
        />
      </Box>
    </span>
  );

  // question type is transition screen
  if (flowNode?.type === FlowNodeType.Transition) {
    return (
      <span className="transition-screen transition-wrapper">
        <Box
          className="transition-top-section"
          style={{
            margin: "0 auto",
            width: isMobile
              ? topWidthMobileType === "100%"
                ? "100%"
                : `${topWidthMobile}px`
              : topWidthDesktopType === "100%"
              ? "100%"
              : `${topWidth}px`,
          }}
        >
          <QuestionContentBlocksList
            contentBlocks={resultsContentBlocks}
            update={updateContentBlocks}
            setContentBlockId={setContentBlockId}
            location="top"
            openContentBlockModal={openContentBlockModal}
          />

          <ContentBlockButton
            onClick={() => {
              setContentBlockId(null);
              openContentBlockModal("top");
            }}
          />

          <EditorController
            name="title"
            className="transition-screen transition-headline"
            control={control}
            element={CenteredTitle}
            dir="auto"
            placeholder="Optional Headline"
            style={{
              fontSize: `${questionFontSize}px`,
              lineHeight: 1.2,
              textAlign: questionAlignment,
            }}
          />

          <Box mt={2} mb={5}>
            <EditorController
              name="description"
              className="transition-screen transition-subheadline"
              control={control}
              element={CenteredSubtext}
              dir="auto"
              placeholder="A longer piece of text explaining something during the transition can go here... Leave these blank to only display the image."
              style={{
                fontSize: `${descriptionFontSize}px`,
                lineHeight: 1.2,
                textAlign: descriptionAlignment,
              }}
            />
          </Box>

          <QuestionContentBlocksList
            contentBlocks={resultsContentBlocks}
            update={updateContentBlocks}
            setContentBlockId={setContentBlockId}
            location="middle"
            openContentBlockModal={openContentBlockModal}
          />

          <ContentBlockButton
            onClick={() => {
              setContentBlockId(null);
              openContentBlockModal("middle");
            }}
          />
        </Box>
        <Box
          className="transition-bottom-section"
          style={{
            margin: "0 auto",
            width: isMobile
              ? bottomWidthMobileType === "100%"
                ? "100%"
                : `${bottomWidthMobile}px`
              : bottomWidthDesktopType === "100%"
              ? "100%"
              : `${bottomWidth}px`,
          }}
        >
          <Box mb={[4, 7]} mx="auto" width="100%">
            <MediaInput
              image={flowNode.image}
              video={flowNode.video}
              alt="Media"
              flowNodeId={flowNodeId.current}
              imageProps={{
                height: flowNode?.inputHeight,
                width: flowNode?.inputWidth,
                transition: true,
              }}
              minEmptyHeight="150px"
              minEmptyWidth="350px"
              mx="auto"
              sizes="790px"
            />
          </Box>

          <QuestionContentBlocksList
            contentBlocks={resultsContentBlocks}
            update={updateContentBlocks}
            setContentBlockId={setContentBlockId}
            location="bottom"
            openContentBlockModal={openContentBlockModal}
          />

          <ContentBlockButton
            onClick={() => {
              setContentBlockId(null);
              openContentBlockModal("bottom");
            }}
          />

          <Appear transition={{ delay: 0.3, ...config.transition }}>
            <motion.div
              className="transition-screen transition-button-container"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3, ...config.transition }}
              style={{
                display: "flex",
                marginTop: "20px",
                justifyContent:
                  buttonAlignment === "center"
                    ? "center"
                    : buttonAlignment === "right"
                    ? "flex-end"
                    : "flex-start",
              }}
            >
              {flowNode?.isRequired && (
                <Button
                  arrow
                  width={
                    isMobile
                      ? buttonWidthMobileType === "100%"
                        ? "100%"
                        : buttonWidthMobileType === "auto"
                        ? "auto"
                        : `${buttonWidthMobile}px`
                      : buttonWidthType === "100%"
                      ? "100%"
                      : buttonWidthType === "auto"
                      ? "auto"
                      : `${buttonWidth}px`
                  }
                  onClick={() => {
                    if (current < totalQuestions) {
                      setNavParams({ question: `${current + 1}` });
                    }
                  }}
                  className="transition-screen transition-button button"
                  primaryColor={primaryColor}
                  disabled={current >= totalQuestions}
                  style={
                    isMobile
                      ? {
                          ...(useDefaultColorForButton === "no"
                            ? { backgroundColor: buttonColor }
                            : {}),
                          ...(useDefaultTextColorForButton === "no"
                            ? { color: buttonTextColor }
                            : {}),
                          ...(buttonFontSizeMobile >= 0
                            ? {
                                fontSize: `${buttonFontSizeMobile}px`,
                              }
                            : {}),
                          ...(buttonBorderRadiusMobile >= 0
                            ? {
                                borderRadius: `${buttonBorderRadiusMobile}px`,
                              }
                            : {}),
                          ...(buttonHorizontalPaddingMobile >= 0
                            ? {
                                paddingLeft: `${buttonHorizontalPaddingMobile}px`,
                                paddingRight: `${buttonHorizontalPaddingMobile}px`,
                              }
                            : {}),
                          ...(buttonVerticalPaddingMobile >= 0
                            ? {
                                paddingTop: `${buttonVerticalPaddingMobile}px`,
                                paddingBottom: `${buttonVerticalPaddingMobile}px`,
                              }
                            : {}),
                        }
                      : {
                          ...(useDefaultColorForButton === "no"
                            ? { backgroundColor: buttonColor }
                            : {}),
                          ...(useDefaultTextColorForButton === "no"
                            ? { color: buttonTextColor }
                            : {}),
                          ...(buttonFontSize >= 0
                            ? { fontSize: `${buttonFontSize}px` }
                            : {}),
                          ...(buttonBorderRadius >= 0
                            ? {
                                borderRadius: `${buttonBorderRadius}px`,
                              }
                            : {}),
                          ...(buttonHorizontalPadding >= 0
                            ? {
                                paddingLeft: `${buttonHorizontalPadding}px`,
                                paddingRight: `${buttonHorizontalPadding}px`,
                              }
                            : {}),
                          ...(buttonVerticalPadding >= 0
                            ? {
                                paddingTop: `${buttonVerticalPadding}px`,
                                paddingBottom: `${buttonVerticalPadding}px`,
                              }
                            : {}),
                        }
                  }
                >
                  {buttonTextOverride?.length > 0
                    ? buttonTextOverride
                    : current >= totalQuestions
                    ? intl.formatMessage(messages.finish)
                    : intl.formatMessage(messages.next)}
                </Button>
              )}
            </motion.div>
          </Appear>
          <QuestionContentBlockModal
            open={showContentBlockModal}
            onClose={() => setShowContentBlockModal(false)}
            onOpen={() => setShowContentBlockModal(true)}
            contentBlocks={resultsContentBlocks}
            location={contentBlockLocation}
            id={contentBlockId}
            save={saveContentBlock}
          />
        </Box>
      </span>
    );
  }

  // question type is multi-line input
  if (flowNode?.type === FlowNodeType.InputMultiLineText) {
    return (
      <span className="input-multi-line-text input-wrapper">
        <Box
          className="input-multi-line-top-section"
          style={{
            margin: "0 auto",
            width: isMobile
              ? topWidthMobileType === "100%"
                ? "100%"
                : `${topWidthMobile}px`
              : topWidthDesktopType === "100%"
              ? "100%"
              : `${topWidth}px`,
          }}
        >
          <QuestionContentBlocksList
            contentBlocks={resultsContentBlocks}
            update={updateContentBlocks}
            setContentBlockId={setContentBlockId}
            location="top"
            openContentBlockModal={openContentBlockModal}
          />

          <ContentBlockButton
            onClick={() => {
              setContentBlockId(null);
              openContentBlockModal("top");
            }}
          />

          <EditorController
            name="title"
            className="input-multi-line-text input-question"
            control={control}
            element={CenteredTitle}
            dir="auto"
            placeholder="Your question here ..."
            style={{
              fontSize: `${questionFontSize}px`,
              lineHeight: 1.2,
              textAlign: questionAlignment,
            }}
          />

          <QuestionContentBlocksList
            contentBlocks={resultsContentBlocks}
            update={updateContentBlocks}
            setContentBlockId={setContentBlockId}
            location="middle"
            openContentBlockModal={openContentBlockModal}
          />

          <ContentBlockButton
            onClick={() => {
              setContentBlockId(null);
              openContentBlockModal("middle");
            }}
          />
        </Box>
        <Box
          className="input-multi-line-bottom-section"
          style={{
            margin: "0 auto",
            width: isMobile
              ? bottomWidthMobileType === "100%"
                ? "100%"
                : `${bottomWidthMobile}px`
              : bottomWidthDesktopType === "100%"
              ? "100%"
              : `${bottomWidth}px`,
          }}
        >
          <Box
            width={{
              _: "100%",
              sm: inputWidth ?? "345px",
              lg: inputWidth ?? "370px",
            }}
            mx="auto"
            my="3"
            className="input-multi-line-text input-box-container"
          >
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <MultiLineInput
                  {...field}
                  name="custom-input"
                  className="input-multi-line-text input-box"
                  label=""
                  placeholder="Edit placeholder..."
                  type="text"
                  primaryColor={primaryColor}
                  inputHeight={inputHeight ?? undefined}
                  maxLength={maxLength}
                  minLength={minLength}
                />
              )}
            />
          </Box>

          <QuestionContentBlocksList
            contentBlocks={resultsContentBlocks}
            update={updateContentBlocks}
            setContentBlockId={setContentBlockId}
            location="bottom"
            openContentBlockModal={openContentBlockModal}
          />

          <ContentBlockButton
            onClick={() => {
              setContentBlockId(null);
              openContentBlockModal("bottom");
            }}
          />

          <motion.div
            className="input-multi-line-text input-button-container"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, ...config.transition }}
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent:
                buttonAlignment === "center"
                  ? "center"
                  : buttonAlignment === "right"
                  ? "flex-end"
                  : "flex-start",
            }}
          >
            <Button
              arrow
              width={
                isMobile
                  ? buttonWidthMobileType === "100%"
                    ? "100%"
                    : buttonWidthMobileType === "auto"
                    ? "auto"
                    : `${buttonWidthMobile}px`
                  : buttonWidthType === "100%"
                  ? "100%"
                  : buttonWidthType === "auto"
                  ? "auto"
                  : `${buttonWidth}px`
              }
              onClick={() => {
                if (current < totalQuestions) {
                  setNavParams({ question: `${current + 1}` });
                }
              }}
              className="input-multi-line-text input-button button"
              primaryColor={primaryColor}
              disabled={current >= totalQuestions}
              style={
                isMobile
                  ? {
                      ...(useDefaultColorForButton === "no"
                        ? { backgroundColor: buttonColor }
                        : {}),
                      ...(useDefaultTextColorForButton === "no"
                        ? { color: buttonTextColor }
                        : {}),
                      ...(buttonFontSizeMobile >= 0
                        ? {
                            fontSize: `${buttonFontSizeMobile}px`,
                          }
                        : {}),
                      ...(buttonBorderRadiusMobile >= 0
                        ? {
                            borderRadius: `${buttonBorderRadiusMobile}px`,
                          }
                        : {}),
                      ...(buttonHorizontalPaddingMobile >= 0
                        ? {
                            paddingLeft: `${buttonHorizontalPaddingMobile}px`,
                            paddingRight: `${buttonHorizontalPaddingMobile}px`,
                          }
                        : {}),
                      ...(buttonVerticalPaddingMobile >= 0
                        ? {
                            paddingTop: `${buttonVerticalPaddingMobile}px`,
                            paddingBottom: `${buttonVerticalPaddingMobile}px`,
                          }
                        : {}),
                    }
                  : {
                      ...(useDefaultColorForButton === "no"
                        ? { backgroundColor: buttonColor }
                        : {}),
                      ...(useDefaultTextColorForButton === "no"
                        ? { color: buttonTextColor }
                        : {}),
                      ...(buttonFontSize >= 0
                        ? { fontSize: `${buttonFontSize}px` }
                        : {}),
                      ...(buttonBorderRadius >= 0
                        ? {
                            borderRadius: `${buttonBorderRadius}px`,
                          }
                        : {}),
                      ...(buttonHorizontalPadding >= 0
                        ? {
                            paddingLeft: `${buttonHorizontalPadding}px`,
                            paddingRight: `${buttonHorizontalPadding}px`,
                          }
                        : {}),
                      ...(buttonVerticalPadding >= 0
                        ? {
                            paddingTop: `${buttonVerticalPadding}px`,
                            paddingBottom: `${buttonVerticalPadding}px`,
                          }
                        : {}),
                    }
              }
            >
              {inputMulti_buttonTextOverride?.length > 0
                ? inputMulti_buttonTextOverride
                : current === totalQuestions
                ? intl.formatMessage(messages.finish)
                : intl.formatMessage(messages.next)}
            </Button>
          </motion.div>
          <QuestionContentBlockModal
            open={showContentBlockModal}
            onClose={() => setShowContentBlockModal(false)}
            onOpen={() => setShowContentBlockModal(true)}
            contentBlocks={resultsContentBlocks}
            location={contentBlockLocation}
            id={contentBlockId}
            save={saveContentBlock}
          />
        </Box>
      </span>
    );
  }

  // question type is one line input
  if (flowNode?.type === FlowNodeType.InputOneLineText) {
    return (
      <span className="input-one-line-text input-wrapper">
        <Box
          className="input-one-line-top-section"
          style={{
            margin: "0 auto",
            width: isMobile
              ? topWidthMobileType === "100%"
                ? "100%"
                : `${topWidthMobile}px`
              : topWidthDesktopType === "100%"
              ? "100%"
              : `${topWidth}px`,
          }}
        >
          <QuestionContentBlocksList
            contentBlocks={resultsContentBlocks}
            update={updateContentBlocks}
            setContentBlockId={setContentBlockId}
            location="top"
            openContentBlockModal={openContentBlockModal}
          />

          <ContentBlockButton
            onClick={() => {
              setContentBlockId(null);
              openContentBlockModal("top");
            }}
          />

          <EditorController
            name="title"
            className="input-one-line-text input-question"
            control={control}
            element={CenteredTitle}
            dir="auto"
            placeholder="Your question here ..."
            style={{
              fontSize: `${questionFontSize}px`,
              lineHeight: 1.2,
              textAlign: questionAlignment,
            }}
          />

          <QuestionContentBlocksList
            contentBlocks={resultsContentBlocks}
            update={updateContentBlocks}
            setContentBlockId={setContentBlockId}
            location="middle"
            openContentBlockModal={openContentBlockModal}
          />

          <ContentBlockButton
            onClick={() => {
              setContentBlockId(null);
              openContentBlockModal("middle");
            }}
          />
        </Box>

        <Box
          className="input-one-line-bottom-section"
          style={{
            margin: "0 auto",
            width: isMobile
              ? bottomWidthMobileType === "100%"
                ? "100%"
                : `${bottomWidthMobile}px`
              : bottomWidthDesktopType === "100%"
              ? "100%"
              : `${bottomWidth}px`,
          }}
        >
          <Box
            width={{ _: "100%", sm: "345px", lg: "370px" }}
            mx="auto"
            my="3"
            className="input-one-line-text input-box-container"
          >
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  name="custom-input"
                  className="input-one-line-text input-box"
                  label=""
                  placeholder="Edit placeholder..."
                  type={inputFieldType ?? "text"}
                  primaryColor={primaryColor}
                  maxLength={maxLength}
                  minLength={minLength}
                />
              )}
            />
          </Box>

          <QuestionContentBlocksList
            contentBlocks={resultsContentBlocks}
            update={updateContentBlocks}
            setContentBlockId={setContentBlockId}
            location="bottom"
            openContentBlockModal={openContentBlockModal}
          />

          <ContentBlockButton
            onClick={() => {
              setContentBlockId(null);
              openContentBlockModal("bottom");
            }}
          />

          <motion.div
            className="input-one-line-text input-button-container"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, ...config.transition }}
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent:
                buttonAlignment === "center"
                  ? "center"
                  : buttonAlignment === "right"
                  ? "flex-end"
                  : "flex-start",
            }}
          >
            <Button
              arrow
              width={
                isMobile
                  ? buttonWidthMobileType === "100%"
                    ? "100%"
                    : buttonWidthMobileType === "auto"
                    ? "auto"
                    : `${buttonWidthMobile}px`
                  : buttonWidthType === "100%"
                  ? "100%"
                  : buttonWidthType === "auto"
                  ? "auto"
                  : `${buttonWidth}px`
              }
              onClick={() => {
                if (current < totalQuestions) {
                  setNavParams({ question: `${current + 1}` });
                }
              }}
              className="input-one-line-text input-button button"
              primaryColor={primaryColor}
              disabled={current >= totalQuestions}
              style={
                isMobile
                  ? {
                      ...(useDefaultColorForButton === "no"
                        ? { backgroundColor: buttonColor }
                        : {}),
                      ...(useDefaultTextColorForButton === "no"
                        ? { color: buttonTextColor }
                        : {}),
                      ...(buttonFontSizeMobile >= 0
                        ? {
                            fontSize: `${buttonFontSizeMobile}px`,
                          }
                        : {}),
                      ...(buttonBorderRadiusMobile >= 0
                        ? {
                            borderRadius: `${buttonBorderRadiusMobile}px`,
                          }
                        : {}),
                      ...(buttonHorizontalPaddingMobile >= 0
                        ? {
                            paddingLeft: `${buttonHorizontalPaddingMobile}px`,
                            paddingRight: `${buttonHorizontalPaddingMobile}px`,
                          }
                        : {}),
                      ...(buttonVerticalPaddingMobile >= 0
                        ? {
                            paddingTop: `${buttonVerticalPaddingMobile}px`,
                            paddingBottom: `${buttonVerticalPaddingMobile}px`,
                          }
                        : {}),
                    }
                  : {
                      ...(useDefaultColorForButton === "no"
                        ? { backgroundColor: buttonColor }
                        : {}),
                      ...(useDefaultTextColorForButton === "no"
                        ? { color: buttonTextColor }
                        : {}),
                      ...(buttonFontSize >= 0
                        ? { fontSize: `${buttonFontSize}px` }
                        : {}),
                      ...(buttonBorderRadius >= 0
                        ? {
                            borderRadius: `${buttonBorderRadius}px`,
                          }
                        : {}),
                      ...(buttonHorizontalPadding >= 0
                        ? {
                            paddingLeft: `${buttonHorizontalPadding}px`,
                            paddingRight: `${buttonHorizontalPadding}px`,
                          }
                        : {}),
                      ...(buttonVerticalPadding >= 0
                        ? {
                            paddingTop: `${buttonVerticalPadding}px`,
                            paddingBottom: `${buttonVerticalPadding}px`,
                          }
                        : {}),
                    }
              }
            >
              {inputOne_buttonTextOverride?.length > 0
                ? inputOne_buttonTextOverride
                : current === totalQuestions
                ? intl.formatMessage(messages.finish)
                : intl.formatMessage(messages.next)}
            </Button>
          </motion.div>
          <QuestionContentBlockModal
            open={showContentBlockModal}
            onClose={() => setShowContentBlockModal(false)}
            onOpen={() => setShowContentBlockModal(true)}
            contentBlocks={resultsContentBlocks}
            location={contentBlockLocation}
            id={contentBlockId}
            save={saveContentBlock}
          />
        </Box>
      </span>
    );
  }

  return (
    <>
      <MotionBox
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={config.transition}
        textAlign={isEmail ? "center" : undefined}
      >
        {isEmail ? (
          <span className="email-form">
            <Box
              className="email-top-section"
              width={{
                _: `${
                  emailPage_TopWidthMobileType === "100%"
                    ? "100%"
                    : emailPage_TopWidthMobile
                }`,
                lg: `${
                  emailPage_TopWidthType === "100%"
                    ? "100%"
                    : `${emailPage_TopWidth}px`
                }`,
              }}
              mx="auto"
            >
              <Appear>
                {emailPage_displayEmailIcon !== "no" && (
                  <Flex
                    className="email-icon-container"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="full"
                    bg="white"
                    width="80px"
                    height="80px"
                    mb={[4, 5]}
                    mx="auto"
                  >
                    <IconUI className="email-icon" icon={email as any} />
                  </Flex>
                )}

                <QuestionContentBlocksList
                  contentBlocks={resultsContentBlocks}
                  update={updateContentBlocks}
                  setContentBlockId={setContentBlockId}
                  location="top"
                  openContentBlockModal={openContentBlockModal}
                />

                <ContentBlockButton
                  onClick={() => {
                    setContentBlockId(null);
                    openContentBlockModal("top");
                  }}
                />

                {emailPage_displayHeadlineSubheadline !== "no" && (
                  <>
                    {titleAndDescription}

                    <QuestionContentBlocksList
                      contentBlocks={resultsContentBlocks}
                      update={updateContentBlocks}
                      setContentBlockId={setContentBlockId}
                      location="middle"
                      openContentBlockModal={openContentBlockModal}
                    />

                    <ContentBlockButton
                      onClick={() => {
                        setContentBlockId(null);
                        openContentBlockModal("middle");
                      }}
                    />
                  </>
                )}
              </Appear>
            </Box>

            <Box
              className="email-bottom-section"
              width={{
                _: `${
                  emailPage_BottomWidthMobileType === "100%"
                    ? "100%"
                    : emailPage_BottomWidthMobile
                }`,
                lg: `${
                  emailPage_BottomWidthType === "100%"
                    ? "100%"
                    : `${emailPage_BottomWidth}px`
                }`,
              }}
              mx="auto"
            >
              {askForName !== "no" && (
                <Appear
                  className="name-input-container"
                  transition={{ delay: 0.2, ...config.transition }}
                >
                  <Input
                    disabled
                    name="name"
                    label={intl.formatMessage(messages.nameLabel)}
                    placeholder={intl.formatMessage(messages.namePlaceholder)}
                    type="text"
                    primaryColor={primaryColor}
                    marginBottom={2}
                  />
                </Appear>
              )}

              {emailPage_displayEmailInput !== "no" && (
                <Appear
                  className="email-input-container"
                  transition={{ delay: 0.2, ...config.transition }}
                >
                  <Input
                    disabled
                    name="email"
                    label={intl.formatMessage(messages.emailLabel)}
                    placeholder={intl.formatMessage(messages.emailPlaceholder)}
                    type="email"
                    primaryColor={primaryColor}
                    marginBottom={2}
                  />
                </Appear>
              )}

              {emailPage_displayPhoneInput !== "no" && (
                <Appear transition={{ delay: 0.2, ...config.transition }}>
                  <div style={{ textAlign: "left", width: "100%" }}>
                    {emailPage_phoneLabel && (
                      <Text
                        as="label"
                        htmlFor="phone"
                        fontSize="12px"
                        lineHeight="15px"
                        mb={1}
                        letterSpacing="-0.01em"
                        color="black90"
                        display="inline-block"
                        verticalAlign="top"
                      >
                        {emailPage_phoneLabel}
                      </Text>
                    )}
                    <PhoneInput
                      key={`${emailPage_phoneOnlyCountries}-${emailPage_phoneExcludedCountries}-${emailPage_phonePreferredCountries}-${emailPage_phoneDefaultCountry}`}
                      placeholder={emailPage_phonePlaceholder}
                      {...(emailPage_phoneDefaultCountry?.length > 0 && {
                        country: emailPage_phoneDefaultCountry
                          ?.trim()
                          ?.toLowerCase(),
                      })}
                      preferredCountries={
                        emailPage_phonePreferredCountries
                          ?.split(",")
                          ?.map((country) => country.trim().toLowerCase()) ?? []
                      }
                      excludeCountries={
                        emailPage_phoneExcludedCountries
                          ?.split(",")
                          ?.map((country) => country.trim().toLowerCase()) ?? []
                      }
                      {...(emailPage_phoneOnlyCountries?.length > 0 && {
                        onlyCountries: emailPage_phoneOnlyCountries
                          .split(",")
                          .map((country) => country.trim().toLowerCase()),
                      })}
                      disabled
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      inputClass="phone-input"
                      containerClass="phone-input-container"
                      inputStyle={{
                        width: "100%",
                        height: "50px",
                        fontSize: "16px",
                        borderRadius: "8px",
                        border: "1px solid #e5e5e5",
                      }}
                      inputProps={{
                        name: "phone",
                        id: "phone",
                      }}
                    />
                  </div>
                </Appear>
              )}

              <QuestionContentBlocksList
                contentBlocks={resultsContentBlocks}
                update={updateContentBlocks}
                setContentBlockId={setContentBlockId}
                location="bottom"
                openContentBlockModal={openContentBlockModal}
              />

              <ContentBlockButton
                onClick={() => {
                  setContentBlockId(null);
                  openContentBlockModal("bottom");
                }}
              />

              <Appear transition={{ delay: 0.3, ...config.transition }}>
                <Flex
                  mt={4}
                  flexDirection="column"
                  alignItems="center"
                  className="email-button-container"
                >
                  {(gdprCheckbox === "above" ||
                    gdprCheckbox === "above-optional") &&
                    gdprBlock}

                  <Button
                    className="button email-submit-button"
                    arrow
                    width={
                      isMobile
                        ? emailPage_buttonWidthMobileType === "100%"
                          ? "100%"
                          : emailPage_buttonWidthMobileType === "auto"
                          ? "auto"
                          : `${emailPage_buttonWidthMobile}px`
                        : emailPage_buttonWidthType === "100%"
                        ? "100%"
                        : emailPage_buttonWidthType === "auto"
                        ? "auto"
                        : `${emailPage_buttonWidth}px`
                    }
                    disabled
                    primaryColor={primaryColor}
                    style={
                      isMobile
                        ? {
                            ...(emailPage_useDefaultColorForButton === "no"
                              ? { backgroundColor: emailPage_buttonColor }
                              : {}),
                            ...(emailPage_useDefaultTextColorForButton === "no"
                              ? { color: emailPage_buttonTextColor }
                              : {}),
                            ...(emailPage_buttonFontSizeMobile >= 0
                              ? {
                                  fontSize: `${emailPage_buttonFontSizeMobile}px`,
                                }
                              : {}),
                            ...(emailPage_buttonBorderRadiusMobile >= 0
                              ? {
                                  borderRadius: `${emailPage_buttonBorderRadiusMobile}px`,
                                }
                              : {}),
                            ...(emailPage_buttonHorizontalPaddingMobile >= 0
                              ? {
                                  paddingLeft: `${emailPage_buttonHorizontalPaddingMobile}px`,
                                  paddingRight: `${emailPage_buttonHorizontalPaddingMobile}px`,
                                }
                              : {}),
                            ...(emailPage_buttonVerticalPaddingMobile >= 0
                              ? {
                                  paddingTop: `${emailPage_buttonVerticalPaddingMobile}px`,
                                  paddingBottom: `${emailPage_buttonVerticalPaddingMobile}px`,
                                }
                              : {}),
                          }
                        : {
                            ...(emailPage_useDefaultColorForButton === "no"
                              ? { backgroundColor: emailPage_buttonColor }
                              : {}),
                            ...(emailPage_useDefaultTextColorForButton === "no"
                              ? { color: emailPage_buttonTextColor }
                              : {}),
                            ...(emailPage_buttonFontSize >= 0
                              ? { fontSize: `${emailPage_buttonFontSize}px` }
                              : {}),
                            ...(emailPage_buttonBorderRadius >= 0
                              ? {
                                  borderRadius: `${emailPage_buttonBorderRadius}px`,
                                }
                              : {}),
                            ...(emailPage_buttonHorizontalPadding >= 0
                              ? {
                                  paddingLeft: `${emailPage_buttonHorizontalPadding}px`,
                                  paddingRight: `${emailPage_buttonHorizontalPadding}px`,
                                }
                              : {}),
                            ...(emailPage_buttonVerticalPadding >= 0
                              ? {
                                  paddingTop: `${emailPage_buttonVerticalPadding}px`,
                                  paddingBottom: `${emailPage_buttonVerticalPadding}px`,
                                }
                              : {}),
                          }
                    }
                  >
                    {emailPage_buttonTextOverride?.length > 0 ? (
                      emailPage_buttonTextOverride
                    ) : (
                      <FormattedMessage {...messages.submit} />
                    )}
                  </Button>

                  {(gdprCheckbox === "below" ||
                    gdprCheckbox === "below-optional") &&
                    gdprBlock}

                  {!hideNoThanks && (
                    <div
                      className="optin-skip-container"
                      style={{ display: "flex" }}
                    >
                      <Link
                        className="optin-skip"
                        fontSize="18px"
                        lineHeight="22px"
                        mt={4}
                        disabled
                      >
                        <FormattedMessage {...messages.skip} />
                      </Link>
                    </div>
                  )}
                </Flex>
              </Appear>
            </Box>
          </span>
        ) : (
          <span className="question-form">
            <Appear
              style={{
                margin: "0 auto",
                width: isMobile
                  ? topWidthMobileType === "100%"
                    ? "100%"
                    : `${topWidthMobile}px`
                  : topWidthDesktopType === "100%"
                  ? "100%"
                  : `${topWidth}px`,
              }}
            >
              <QuestionContentBlocksList
                contentBlocks={resultsContentBlocks}
                update={updateContentBlocks}
                setContentBlockId={setContentBlockId}
                location="top"
                openContentBlockModal={openContentBlockModal}
              />

              <ContentBlockButton
                onClick={() => {
                  setContentBlockId(null);
                  openContentBlockModal("top");
                }}
              />

              {titleAndDescription}

              <QuestionContentBlocksList
                contentBlocks={resultsContentBlocks}
                update={updateContentBlocks}
                setContentBlockId={setContentBlockId}
                location="middle"
                openContentBlockModal={openContentBlockModal}
              />

              <ContentBlockButton
                onClick={() => {
                  setContentBlockId(null);
                  openContentBlockModal("middle");
                }}
              />
            </Appear>

            <Appear
              style={{
                margin: "0 auto",
                width: isMobile
                  ? bottomWidthMobileType === "100%"
                    ? "100%"
                    : `${bottomWidthMobile}px`
                  : bottomWidthDesktopType === "100%"
                  ? "100%"
                  : `${bottomWidth}px`,
              }}
            >
              {/* @ts-ignore */}
              <SortableList
                axis="xy"
                onSortEnd={onSortEnd}
                useDragHandle
                helperClass="is-dragging preview-pane"
                alignment={answersAlignment}
              >
                {fields.map((field, index) => {
                  const props = {
                    isActive: false,
                    field,
                    control,
                    register,
                    index,
                    onDelete: () => remove(index),
                  };

                  return (
                    <SortableItem
                      key={field.fieldKey}
                      index={index}
                      answersPerRowDesktop={answersPerRowDesktop}
                      answersPerRowMobile={answersPerRowMobile}
                      pageSettings={pageSettings}
                      // @ts-ignore
                      value={
                        {
                          ...props,
                          ...(flowNode.type === FlowNodeType.Image ||
                          flowNode.type === FlowNodeType.ImageMulti
                            ? {
                                src: find(flowNode?.options, {
                                  id: (field as any).id,
                                })?.image as File,
                                flowNodeId: flowNodeId.current,
                                optionId: (field as any).id,
                              }
                            : {
                                type: "radio",
                                emoji:
                                  flowNode.type === FlowNodeType.Emoji ||
                                  flowNode.type === FlowNodeType.EmojiMulti,
                              }),
                        } as any
                      }
                    />
                  );
                })}

                <Button
                  width={{
                    _: `${
                      answersPerRowMobile === 1
                        ? "100%"
                        : `calc(${100 / (answersPerRowMobile ?? 1)}% - 16px)`
                    }`,
                    lg: `${
                      answersPerRowDesktop === 1
                        ? "100%"
                        : `calc(${100 / (answersPerRowDesktop ?? 3)}% - 16px)`
                    }`,
                  }}
                  minHeight="72px"
                  mx={1}
                  mb={[1, 2]}
                  variant="plain"
                  fontWeight="regular"
                  fontSize={2.25}
                  css={css({
                    "&&": {
                      color: "interactive",
                    },
                    "> div:first-of-type": {
                      bg: "black4",
                    },
                    "&:hover > div:first-of-type": {
                      bg: "black10",
                    },
                  })}
                  onClick={() => {
                    append(
                      { id: "new", label: "", description: "" },
                      { shouldFocus: true }
                    );
                  }}
                >
                  Add answer
                </Button>
              </SortableList>

              <QuestionContentBlocksList
                contentBlocks={resultsContentBlocks}
                update={updateContentBlocks}
                setContentBlockId={setContentBlockId}
                location="bottom"
                openContentBlockModal={openContentBlockModal}
              />

              <ContentBlockButton
                onClick={() => {
                  setContentBlockId(null);
                  openContentBlockModal("bottom");
                }}
              />

              <motion.div
                className="next-question-button-container"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3, ...config.transition }}
                style={{
                  display: "flex",
                  justifyContent:
                    alignment === "center"
                      ? "center"
                      : alignment === "right"
                      ? "flex-end"
                      : "flex-start",
                }}
              >
                <Button
                  arrow
                  mt={7}
                  width={
                    isMobile
                      ? buttonWidthMobileType === "100%"
                        ? "100%"
                        : buttonWidthMobileType === "auto"
                        ? "auto"
                        : `${buttonWidthMobile}px`
                      : buttonWidthType === "100%"
                      ? "100%"
                      : buttonWidthType === "auto"
                      ? "auto"
                      : `${buttonWidth}px`
                  }
                  onClick={() => {
                    if (current < totalQuestions) {
                      setNavParams({ question: `${current + 1}` });
                    }
                  }}
                  className="button next-question-button"
                  primaryColor={primaryColor}
                  disabled={current >= totalQuestions}
                  style={
                    isMobile
                      ? {
                          ...(useDefaultColorForButton === "no"
                            ? { backgroundColor: buttonColor }
                            : {}),
                          ...(useDefaultTextColorForButton === "no"
                            ? { color: buttonTextColor }
                            : {}),
                          ...(buttonFontSizeMobile >= 0
                            ? {
                                fontSize: `${buttonFontSizeMobile}px`,
                              }
                            : {}),
                          ...(buttonBorderRadiusMobile >= 0
                            ? {
                                borderRadius: `${buttonBorderRadiusMobile}px`,
                              }
                            : {}),
                          ...(buttonHorizontalPaddingMobile >= 0
                            ? {
                                paddingLeft: `${buttonHorizontalPaddingMobile}px`,
                                paddingRight: `${buttonHorizontalPaddingMobile}px`,
                              }
                            : {}),
                          ...(buttonVerticalPaddingMobile >= 0
                            ? {
                                paddingTop: `${buttonVerticalPaddingMobile}px`,
                                paddingBottom: `${buttonVerticalPaddingMobile}px`,
                              }
                            : {}),
                        }
                      : {
                          ...(useDefaultColorForButton === "no"
                            ? { backgroundColor: buttonColor }
                            : {}),
                          ...(useDefaultTextColorForButton === "no"
                            ? { color: buttonTextColor }
                            : {}),
                          ...(buttonFontSize >= 0
                            ? { fontSize: `${buttonFontSize}px` }
                            : {}),
                          ...(buttonBorderRadius >= 0
                            ? {
                                borderRadius: `${buttonBorderRadius}px`,
                              }
                            : {}),
                          ...(buttonHorizontalPadding >= 0
                            ? {
                                paddingLeft: `${buttonHorizontalPadding}px`,
                                paddingRight: `${buttonHorizontalPadding}px`,
                              }
                            : {}),
                          ...(buttonVerticalPadding >= 0
                            ? {
                                paddingTop: `${buttonVerticalPadding}px`,
                                paddingBottom: `${buttonVerticalPadding}px`,
                              }
                            : {}),
                        }
                  }
                >
                  {buttonTextOverride?.length > 0
                    ? buttonTextOverride
                    : current === totalQuestions
                    ? intl.formatMessage(messages.finish)
                    : intl.formatMessage(messages.next)}
                </Button>
              </motion.div>
            </Appear>
          </span>
        )}
      </MotionBox>
      <QuestionContentBlockModal
        open={showContentBlockModal}
        onClose={() => setShowContentBlockModal(false)}
        onOpen={() => setShowContentBlockModal(true)}
        contentBlocks={resultsContentBlocks}
        location={contentBlockLocation}
        id={contentBlockId}
        save={saveContentBlock}
      />
    </>
  );
};
