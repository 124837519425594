import { useMemo } from "react";
import { Box, Flex } from "@storyofams/react-ui";
import { AnimatePresence, motion } from "framer-motion";
import qs from "query-string";
import { useIntl } from "react-intl";
import { MediaInput } from "~/components";
import { BackButton, ProgressBar, TopNav } from "~/components/preview";
import config from "~/config";
import {
  File,
  FlowColorScheme,
  FlowFragmentFragment,
  FlowNodeLayout,
  FlowNodeType,
} from "~/graphql/sdk";
import { useFlow, useNavParams } from "~/hooks";

import { QuestionForm } from "./forms/QuestionForm";
import { ResultsForm } from "./forms/ResultsForm";
import { WelcomeForm } from "./forms/WelcomeForm";
import { messages } from "./messages";
import { Badge, BlockStack, Card, InlineStack, Text } from "@shopify/polaris";
interface PreviewPaneProps {
  flow: FlowFragmentFragment;
  isRtl?: boolean;
}

export const PreviewPane = ({ flow, isRtl }: PreviewPaneProps) => {
  const intl = useIntl();
  const [{ question: current }, setNavParams] = useNavParams();
  const { flowNode, data } = useFlow();

  const activeQuestionID = data?.flows?.[1]?.nodes?.[current]?.id ?? "";

  const hasWelcome = flow?.nodes?.[0]?.type === FlowNodeType.Welcome;
  const isWelcome = flowNode?.type === FlowNodeType.Welcome;
  const isTransition = flowNode?.type === FlowNodeType.Transition;
  const isEmail = flowNode?.type === FlowNodeType.Email;
  const isResults = !flowNode;

  const flowNodeType =
    flowNode?.type === FlowNodeType.Welcome
      ? "Welcome Screen"
      : flowNode?.type === FlowNodeType.Transition
      ? "Transition Screen"
      : flowNode?.type === FlowNodeType.Email
      ? "Optin Page"
      : flowNode?.type === FlowNodeType.Simple
      ? "Single Choice Question - Text-Only"
      : flowNode?.type === FlowNodeType.SimpleMulti
      ? "Multiple Choice Question - Text-Only"
      : flowNode?.type === FlowNodeType.Emoji
      ? "Single Choice Question - Text & Emoji"
      : flowNode?.type === FlowNodeType.EmojiMulti
      ? "Multiple Choice Question - Text & Emoji"
      : flowNode?.type === FlowNodeType.Image
      ? "Single Choice Question - Text & Image"
      : flowNode?.type === FlowNodeType.ImageMulti
      ? "Multiple Choice Question - Text & Image"
      : flowNode?.type === FlowNodeType.InputOneLineText
      ? "Single-Line Text Input"
      : flowNode?.type === FlowNodeType.InputMultiLineText
      ? "Multi-Line Text Input"
      : flowNode?.type === FlowNodeType.InputCalendar
      ? "Calendar Input"
      : flowNode?.type === FlowNodeType.InputSlider
      ? "Slider Input"
      : "Results Page";

  // total questions (excluding welcome, email and transition)
  const total = flow?.nodes?.filter(
    (node) =>
      node.type !== FlowNodeType.Welcome &&
      node.type !== FlowNodeType.Transition &&
      node.type !== FlowNodeType.Email
  )?.length;
  const layout = flowNode?.layout as FlowNodeLayout;

  // map question index to question number
  const questionNumberMap = {} as { [key: number]: number };
  let questionNumber = 0;
  flow?.nodes?.forEach((node, idx) => {
    if (
      node.type !== FlowNodeType.Welcome &&
      node.type !== FlowNodeType.Transition &&
      node.type !== FlowNodeType.Email
    ) {
      questionNumberMap[idx] = ++questionNumber;
    }
  });

  const bg = useMemo(() => {
    if (flow?.bgColor) {
      return flow.bgColor;
    }
    switch (flow?.colorScheme) {
      case FlowColorScheme.Cool:
        return "#F4F6FB";
      case FlowColorScheme.Warm:
        return "#FBF8F4";
      default:
        return "#F5F5F5";
    }
  }, [flow?.colorScheme, flow?.bgColor]);

  return (
    <Flex flexDirection="column" flex="1" mb={2}>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      {!!flow?.fontFamily && (
        <>
          <link
            href={`https://fonts.googleapis.com/css2?${qs.stringify({
              family: `${flow.fontFamily}:wght@400;700`,
              display: "swap",
            })}`}
            rel="stylesheet"
          />
          <style
            dangerouslySetInnerHTML={{
              __html: `
                #preview-plane *, .preview-pane * {
                  font-family: ${flow.fontFamily};
                }

                ${flow?.cssEditor ?? ""}
              `,
            }}
          />
        </>
      )}

      <BlockStack gap="100" align="start" inlineAlign="start">
        <InlineStack gap="100">
          <Text as="h4" variant="bodyMd">
            Preview
          </Text>
          <Badge
            tone={
              flowNode?.type === FlowNodeType.Welcome
                ? "success"
                : flowNode?.type === FlowNodeType.Transition
                ? "warning"
                : flowNode?.type === FlowNodeType.Email
                ? "magic"
                : flowNode?.type === FlowNodeType.Simple ||
                  flowNode?.type === FlowNodeType.Emoji ||
                  flowNode?.type === FlowNodeType.Image
                ? "attention"
                : "info"
            }
          >
            {flowNodeType ?? "Results Page"}
          </Badge>
        </InlineStack>
        <Text as="p" variant="bodySm" tone="subdued">
          {activeQuestionID}
        </Text>
      </BlockStack>
      <br />

      <Card>
        <Flex
          id="preview-plane"
          className="main-container"
          dir={isRtl ? "rtl" : undefined}
          flexDirection={
            layout === FlowNodeLayout.MediaCoverRight ? "row-reverse" : "row"
          }
          flex="1"
          minWidth="0"
          width="100%"
          borderBottomLeftRadius="md"
          borderBottomRightRadius="md"
          bg={bg}
          mr={2}
          style={{
            fontSize: 2,
            overflowX: "auto",
          }}
        >
          {!!flowNode &&
            [
              FlowNodeLayout.MediaCoverLeft,
              FlowNodeLayout.MediaCoverRight,
            ].includes(layout) && (
              <Flex flex="1" height="100%">
                <MediaInput
                  image={flowNode.image}
                  video={flowNode.video}
                  alt="Cover media"
                  flowNodeId={flowNode.id}
                  imageProps={{
                    minHeight: "100%",
                  }}
                  cover
                  minEmptyHeight="100%"
                  minHeight="100%"
                  sizes="570px"
                />
              </Flex>
            )}

          <Flex
            className={questionNumberMap[current] ? `question-${current}` : ""}
            flexDirection="column"
            flex="1"
            width="100%"
            px={4}
            pb={4}
          >
            <TopNav
              current={questionNumberMap[current] ?? 0}
              total={current >= 0 ? total : undefined}
              closeText={
                isResults || isWelcome || isTransition
                  ? intl.formatMessage(messages.close)
                  : undefined
              }
              hasStepIndicator={
                flow?.hasStepIndicator &&
                !isWelcome &&
                !isResults &&
                !isTransition &&
                !isEmail
              }
              logo={flow?.logo as File}
            />

            {/* @ts-ignore */}
            <AnimatePresence exitBeforeEnter>
              {flow?.hasProgressBar !== false &&
              !isWelcome &&
              !isResults &&
              !isTransition &&
              !isEmail ? (
                <ProgressBar
                  percentage={(questionNumberMap[current] ?? 0) * (100 / total)}
                  color={flow?.primaryColor as string}
                />
              ) : (
                <motion.div
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={config.transition}
                />
              )}
            </AnimatePresence>

            {!isResults && !isTransition && (
              <>
                {/* @ts-ignore */}
                <AnimatePresence exitBeforeEnter>
                  {current > 0 ? (
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      pt={3}
                      mb={3}
                    >
                      <BackButton
                        onClick={() => {
                          if (current > 0) {
                            setNavParams({ question: `${current - 1}` });
                          }
                        }}
                      />
                    </Flex>
                  ) : (
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      pt={
                        [
                          FlowNodeLayout.MediaFloatTop,
                          FlowNodeLayout.MediaFloatRight,
                        ].includes(layout)
                          ? 0
                          : 3
                      }
                      mb={
                        [
                          FlowNodeLayout.MediaFloatTop,
                          FlowNodeLayout.MediaFloatRight,
                        ].includes(layout)
                          ? 0
                          : 3
                      }
                    >
                      <Box
                        height={
                          layout === FlowNodeLayout.MediaFloatTop
                            ? "8px"
                            : layout === FlowNodeLayout.MediaFloatRight
                            ? "32px"
                            : "24px"
                        }
                      />
                    </Flex>
                  )}
                </AnimatePresence>
              </>
            )}

            {/* @ts-ignore */}
            <AnimatePresence exitBeforeEnter>
              {!!flowNode && (
                <>
                  {isWelcome ? (
                    <WelcomeForm
                      key="welcome"
                      total={total}
                      primaryColor={flow?.primaryColor as string}
                      mt={
                        [
                          FlowNodeLayout.MediaCoverLeft,
                          FlowNodeLayout.MediaCoverRight,
                        ].includes(layout)
                          ? "-160px"
                          : 0
                      }
                    />
                  ) : (
                    <QuestionForm
                      key={`question-${flowNode.id}`}
                      data={flowNode as any}
                      total={total}
                      primaryColor={flow?.primaryColor as string}
                      hasWelcome={hasWelcome}
                      hideNoThanks={flow?.hideNoThanks ?? (false as boolean)}
                      askForName={flow?.askForName ?? "no"}
                      gdprCheckbox={flow?.gdprCheckbox ?? "no"}
                      gdprText={flow?.gdprText ?? ""}
                      gdprLink={flow?.gdprLink ?? ""}
                      flowPageSettings={JSON.parse(flow?.pageSettings ?? "{}")}
                    />
                  )}
                </>
              )}

              {isResults && <ResultsForm />}
            </AnimatePresence>
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
};
