import { useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Layout,
  Link,
  Card,
  Form,
  FormLayout,
  Button,
  InlineStack,
  Text,
  Badge,
  BlockStack,
} from "@shopify/polaris";
import { useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import * as Yup from "yup";

import { ErrorBanner, Input } from "~/components";
import { IntegrationStatus, IntegrationType } from "~/graphql/sdk";
import { useSdk } from "~/hooks";

const schema = Yup.object().shape({
  mailchimpListId: Yup.string().required(),
});

export const Mailchimp = ({ data, id, deactivate }) => {
  const queryClient = useQueryClient();
  const sdk = useSdk();
  const [error, setError] = useState<any>();

  const mailchimpListId = useMemo(
    () => data?.integrationMetadata?.mailchimp?.listId,
    [data]
  );

  const { data: mailchimpIntegration } = useQuery(
    ["mailchimpIntegration"],
    () =>
      sdk
        .integrations({ filter: { type: { eq: IntegrationType.Mailchimp } } })
        .then((res) => res.integrations?.edges?.[0]?.node),
    { suspense: true }
  );

  const isMailchimpActive = useMemo(
    () => mailchimpIntegration?.status === IntegrationStatus.Connected,
    [mailchimpIntegration]
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    reset,
  } = useForm({
    defaultValues: {
      mailchimpListId: mailchimpListId || "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    try {
      await sdk.updateOneContainer({
        input: {
          id,
          update: {
            integrationMetadata: {
              ...(data?.integrationMetadata || {}),
              mailchimp: { listId: values.mailchimpListId },
            },
          },
        },
      });

      queryClient.invalidateQueries("flowContainer");
      setError(null);
      reset({ mailchimpListId: values.mailchimpListId });
      window?.top?.frames?.["app-iframe"]?.postMessage(
        {
          action: "toast",
          content: "Saved successfully",
        },
        "*"
      );
    } catch (e: any) {
      setError({
        message: e?.message,
        messages: e?.response?.errors?.map((err) => err?.message),
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Layout>
        <Layout.Section>
          {!!error && (
            <ErrorBanner
              title={`To connect ${name}, please fix the following error:`}
              messages={[`The provided API key is invalid.`]}
              error={error}
            />
          )}
        </Layout.Section>

        <Layout.AnnotatedSection
          title={
            <InlineStack gap="100" align="center">
              <div style={{ height: "24px" }}>
                <img
                  src="/logos/mailchimp.png"
                  alt="Mailchimp logo"
                  height="24px"
                  width="24px"
                />
              </div>
              <Text variant="headingMd" as="h2">
                Mailchimp
              </Text>
              {isMailchimpActive && <Badge tone="success">Connected</Badge>}
            </InlineStack>
          }
          description={
            <p>
              Enter the Mailchimp list ID of the list that users of this should
              be added to.
              <br />
              <Link
                url="https://help.trylantern.com/en/articles/7144626-mailchimp-integration"
                external
              >
                Read more about setting up Mailchimp
              </Link>
            </p>
          }
        >
          <Card>
            <BlockStack gap="200">
              <FormLayout>
                <Input
                  autoComplete="off"
                  name="mailchimpListId"
                  control={control}
                  label="Audience ID"
                  error={errors?.mailchimpListId?.message}
                  labelAction={{
                    content: "Where do I find this?",
                    url: "https://help.trylantern.com/en/articles/7144626-mailchimp-integration",
                    external: true,
                  }}
                  placeholder="xxxxxx"
                  disabled={!isMailchimpActive}
                />
              </FormLayout>

              <InlineStack blockAlign="end">
                <Button
                  onClick={async () => {
                    await onSubmit({ mailchimpListId: "" });
                    deactivate();
                  }}
                  disabled={false}
                  loading={isSubmitting}
                >
                  Deactivate
                </Button>
                <Button
                  submit
                  variant="primary"
                  disabled={!isDirty || !isMailchimpActive}
                  loading={isSubmitting}
                >
                  Save
                </Button>
              </InlineStack>
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </Form>
  );
};
