import {
  Checkbox,
  RangeSlider,
  Select,
  Text,
  Tooltip,
  BlockStack,
  Button,
  Collapsible,
  TextField,
  Link,
  InlineStack,
  RadioButton,
  Icon,
} from "@shopify/polaris";
import {
  LinkIcon,
  ProductIcon,
  DesktopIcon,
  MobileIcon,
  TextAlignCenterIcon,
  TextAlignLeftIcon,
  TextAlignRightIcon,
  PaintBrushFlatIcon,
  CartIcon,
  MagicIcon,
  TextWithImageIcon,
  DiscountIcon,
  ButtonIcon,
  TextFontListIcon,
} from "@shopify/polaris-icons";
import { Input } from "@storyofams/react-ui";
import { range } from "lodash";
import { Controller, useWatch } from "react-hook-form";
import { Section } from "~/components/Section";
import { useOptionsForm } from "~/hooks";
import { useState } from "react";
import styled from "styled-components";
import { ColorInput } from "~/components/ColorInput";

const resultsOptions = range(10).map((idx) => ({
  label: `${idx + 1} result${idx > 0 ? "s" : ""}`,
  value: `${idx + 1}`,
}));

const extraOptions = range(20).map((idx) => ({
  label: `${idx + 1} result${idx > 0 ? "s" : ""}`,
  value: `${idx + 1}`,
}));

const priorityOptions = [
  { label: "Lowest Price", value: "LOWEST" },
  { label: "Highest Price", value: "HIGHEST" },
  { label: "A-Z", value: "AZ" },
  { label: "Z-A", value: "ZA" },
  { label: "Random", value: "RANDOM" },
];

const formatOptions = [
  { label: "8 / 10", value: "NUMBER" },
  { label: "80%", value: "PERCENTAGE" },
  { label: "-- hide --", value: "HIDE" },
];

const getDefaultValues = (values) => {
  const pageSettingsJSON = values?.pageSettings;
  const pageSettings = pageSettingsJSON ? JSON.parse(pageSettingsJSON) : {};

  return {
    enableRestart:
      typeof values?.enableRestart !== "undefined"
        ? !!values.enableRestart
        : true,
    enableReviews:
      typeof values?.enableReviews !== "undefined"
        ? !!values.enableReviews
        : true,
    returnToResults:
      typeof values?.returnToResults !== "undefined"
        ? !!values.returnToResults
        : true,
    reviewsSource: `${values?.reviewsSource || "shopify"}`,
    cartPage: `${values?.cartPage || "default"}`,
    subscriptionsService: `${values?.subscriptionsService || "none"}`,
    defaultSubscriptionsOption: `${
      values?.defaultSubscriptionsOption || "subscribe-save"
    }`,
    oneTimeLabel: `${values?.oneTimeLabel || "One-Time Purchase"}`,
    subscribeSaveLabel: `${values?.subscribeSaveLabel || "Subscribe & Save"}`,
    showSubscriptionsHelpIcon:
      typeof values?.showSubscriptionsHelpIcon !== "undefined"
        ? !!values.showSubscriptionsHelpIcon
        : true,
    subscriptionsHelpText: `${
      values?.subscriptionsHelpText ||
      "Products are automatically delivered on your schedule. No obligation, modify or cancel your subscription anytime."
    }`,
    enableMoreResults:
      typeof values?.enableMoreResults !== "undefined"
        ? !!values.enableMoreResults
        : true,
    enableAddToCart:
      typeof values?.enableAddToCart !== "undefined"
        ? !!values.enableAddToCart
        : true,
    noCart: typeof values?.noCart !== "undefined" ? !!values.noCart : false,
    enableAddAll:
      typeof values?.enableAddAll !== "undefined"
        ? !!values.enableAddAll
        : false,
    hideDefaultRecommendations:
      typeof values?.hideDefaultRecommendations !== "undefined"
        ? !!values.hideDefaultRecommendations
        : false,
    useURLTrackingParameters:
      typeof values?.useURLTrackingParameters !== "undefined"
        ? !!values.useURLTrackingParameters
        : true,
    customUTM: `${
      values?.customUTM || "utm_source=lantern&utm_campaign={slug}"
    }`,
    showProductDescription:
      typeof values?.showProductDescription !== "undefined"
        ? !!values.showProductDescription
        : false,
    showShortDescription:
      typeof values?.showShortDescription !== "undefined"
        ? !!values.showShortDescription
        : false,
    customDescriptionMetafield: `${
      values?.customDescriptionMetafield || "lantern_description"
    }`,
    showVisualMatchBlock:
      typeof values?.showVisualMatchBlock !== "undefined"
        ? !!values.showVisualMatchBlock
        : false,
    resultsAmount: `${values?.resultsAmount || 3}`,
    extraAmount: `${values?.extraAmount || 6}`,
    enableSoldOutProducts:
      typeof values?.enableSoldOutProducts !== "undefined"
        ? !!values.enableSoldOutProducts
        : true,
    openInNewTab:
      typeof values?.openInNewTab !== "undefined"
        ? !!values.openInNewTab
        : true,
    tagOrders:
      typeof values?.tagOrders !== "undefined" ? !!values.tagOrders : true,
    customTag: values?.customTag || "Lantern Quiz",
    discountCode: values?.discountCode || "",
    createCustomer:
      typeof values?.createCustomer !== "undefined"
        ? !!values.createCustomer
        : false,
    addCustomerTags:
      typeof values?.addCustomerTags !== "undefined"
        ? !!values.addCustomerTags
        : false,
    doubleOptIn:
      typeof values?.doubleOptIn !== "undefined" ? !!values.doubleOptIn : true,
    customerTags: values?.customerTags || "lantern,quiz",
    resultSortingPriority:
      typeof values?.resultSortingPriority !== "undefined"
        ? values.resultSortingPriority
        : priorityOptions[0].value,
    resultsPerRow:
      typeof values?.resultsPerRow !== "undefined" ? values.resultsPerRow : 3,
    resultsPerRowMobile:
      typeof values?.resultsPerRowMobile !== "undefined"
        ? values.resultsPerRowMobile
        : 1,
    matchThreshold:
      typeof values?.matchThreshold !== "undefined" ? values.matchThreshold : 0,
    weightThreshold:
      typeof values?.weightThreshold !== "undefined"
        ? values.weightThreshold
        : 0,
    matchFormat:
      typeof values?.matchFormat !== "undefined"
        ? values.matchFormat
        : "NUMBER",
    resultsRedirectUrl:
      typeof values?.resultsRedirectUrl !== "undefined"
        ? values.resultsRedirectUrl
        : "",
    viewMoreRedirectUrl:
      typeof values?.viewMoreRedirectUrl !== "undefined"
        ? values.viewMoreRedirectUrl
        : "",
    noResultsRedirectUrl:
      typeof values?.noResultsRedirectUrl !== "undefined"
        ? values.noResultsRedirectUrl
        : "",
    // general design
    results_topWidthDesktopType:
      pageSettings?.results_topWidthDesktopType ?? "100%",
    results_topWidth: pageSettings?.results_topWidth ?? "1200",
    results_bottomWidthDesktopType:
      pageSettings?.results_bottomWidthDesktopType ?? "100%",
    results_bottomWidth: pageSettings?.results_bottomWidth ?? "1200",
    results_topWidthMobileType:
      pageSettings?.results_topWidthMobileType ?? "100%",
    results_topWidthMobile: pageSettings?.results_topWidthMobile ?? "1200",
    results_bottomWidthMobileType:
      pageSettings?.results_bottomWidthMobileType ?? "100%",
    results_bottomWidthMobile:
      pageSettings?.results_bottomWidthMobile ?? "1200",
    // headline
    results_questionAlignment:
      pageSettings?.results_questionAlignment ?? "center",
    results_questionFontSize: pageSettings?.results_questionFontSize ?? "32",
    // subheadline
    results_descriptionAlignment:
      pageSettings?.results_descriptionAlignment ?? "center",
    results_descriptionFontSize:
      pageSettings?.results_descriptionFontSize ?? "16",
    // atc button
    atc_buttonColor: pageSettings?.atc_buttonColor ?? "#000",
    atc_useDefaultColorForButton:
      pageSettings?.atc_useDefaultColorForButton ?? "yes",
    atc_buttonTextColor: pageSettings?.atc_buttonTextColor ?? "#fff",
    atc_useDefaultTextColorForButton:
      pageSettings?.atc_useDefaultTextColorForButton ?? "yes",
    atc_buttonTextOverride: pageSettings?.atc_buttonTextOverride ?? "",
    atc_buttonFontSize: pageSettings?.atc_buttonFontSize ?? "18",
    atc_buttonHorizontalPadding:
      pageSettings?.atc_buttonHorizontalPadding ?? "56",
    atc_buttonVerticalPadding: pageSettings?.atc_buttonVerticalPadding ?? "20",
    atc_buttonBorderRadius: pageSettings?.atc_buttonBorderRadius ?? "8",
    atc_buttonWidthType: pageSettings?.atc_buttonWidthType ?? "100%",
    atc_buttonWidth: pageSettings?.atc_buttonWidth ?? "370",
    atc_buttonFontSizeMobile: pageSettings?.atc_buttonFontSizeMobile ?? "18",
    atc_buttonBorderRadiusMobile:
      pageSettings?.atc_buttonBorderRadiusMobile ?? "8",
    atc_buttonHorizontalPaddingMobile:
      pageSettings?.atc_buttonHorizontalPaddingMobile ?? "56",
    atc_buttonVerticalPaddingMobile:
      pageSettings?.atc_buttonVerticalPaddingMobile ?? "20",
    atc_buttonWidthMobileType:
      pageSettings?.atc_buttonWidthMobileType ?? "100%",
    atc_buttonWidthMobile: pageSettings?.atc_buttonWidthMobile ?? "345",
    atc_buttonAlignment: pageSettings?.atc_buttonAlignment ?? "center",
    // add all to cart button
    addAll_buttonColor: pageSettings?.addAll_buttonColor ?? "#000",
    addAll_useDefaultColorForButton:
      pageSettings?.addAll_useDefaultColorForButton ?? "yes",
    addAll_buttonTextColor: pageSettings?.addAll_buttonTextColor ?? "#fff",
    addAll_useDefaultTextColorForButton:
      pageSettings?.addAll_useDefaultTextColorForButton ?? "yes",
    addAll_buttonTextOverride: pageSettings?.addAll_buttonTextOverride ?? "",
    addAll_buttonFontSize: pageSettings?.addAll_buttonFontSize ?? "18",
    addAll_buttonHorizontalPadding:
      pageSettings?.addAll_buttonHorizontalPadding ?? "56",
    addAll_buttonVerticalPadding:
      pageSettings?.addAll_buttonVerticalPadding ?? "20",
    addAll_buttonBorderRadius: pageSettings?.addAll_buttonBorderRadius ?? "8",
    addAll_buttonWidthType: pageSettings?.addAll_buttonWidthType ?? "custom",
    addAll_buttonWidth: pageSettings?.addAll_buttonWidth ?? "370",
    addAll_buttonFontSizeMobile:
      pageSettings?.addAll_buttonFontSizeMobile ?? "18",
    addAll_buttonBorderRadiusMobile:
      pageSettings?.addAll_buttonBorderRadiusMobile ?? "8",
    addAll_buttonHorizontalPaddingMobile:
      pageSettings?.addAll_buttonHorizontalPaddingMobile ?? "56",
    addAll_buttonVerticalPaddingMobile:
      pageSettings?.addAll_buttonVerticalPaddingMobile ?? "20",
    addAll_buttonWidthMobileType:
      pageSettings?.addAll_buttonWidthMobileType ?? "100%",
    addAll_buttonWidthMobile: pageSettings?.addAll_buttonWidthMobile ?? "345",
    addAll_buttonAlignment: pageSettings?.addAll_buttonAlignment ?? "center",
    // view more button
    viewMore_buttonColor: pageSettings?.viewMore_buttonColor ?? "#000",
    viewMore_useDefaultColorForButton:
      pageSettings?.viewMore_useDefaultColorForButton ?? "yes",
    viewMore_buttonTextColor: pageSettings?.viewMore_buttonTextColor ?? "#fff",
    viewMore_useDefaultTextColorForButton:
      pageSettings?.viewMore_useDefaultTextColorForButton ?? "yes",
    viewMore_buttonTextOverride:
      pageSettings?.viewMore_buttonTextOverride ?? "",
    viewMore_buttonFontSize: pageSettings?.viewMore_buttonFontSize ?? "18",
    viewMore_buttonHorizontalPadding:
      pageSettings?.viewMore_buttonHorizontalPadding ?? "56",
    viewMore_buttonVerticalPadding:
      pageSettings?.viewMore_buttonVerticalPadding ?? "20",
    viewMore_buttonBorderRadius:
      pageSettings?.viewMore_buttonBorderRadius ?? "8",
    viewMore_buttonWidthType:
      pageSettings?.viewMore_buttonWidthType ?? "custom",
    viewMore_buttonWidth: pageSettings?.viewMore_buttonWidth ?? "370",
    viewMore_buttonFontSizeMobile:
      pageSettings?.viewMore_buttonFontSizeMobile ?? "18",
    viewMore_buttonBorderRadiusMobile:
      pageSettings?.viewMore_buttonBorderRadiusMobile ?? "8",
    viewMore_buttonHorizontalPaddingMobile:
      pageSettings?.viewMore_buttonHorizontalPaddingMobile ?? "56",
    viewMore_buttonVerticalPaddingMobile:
      pageSettings?.viewMore_buttonVerticalPaddingMobile ?? "20",
    viewMore_buttonWidthMobileType:
      pageSettings?.viewMore_buttonWidthMobileType ?? "100%",
    viewMore_buttonWidthMobile:
      pageSettings?.viewMore_buttonWidthMobile ?? "345",
    viewMore_buttonAlignment:
      pageSettings?.viewMore_buttonAlignment ?? "center",
  };
};

const IntegrationSettings = ({ control }) => {
  const enableReviews = useWatch({
    control,
    name: "enableReviews",
  });
  const showHelpIcon = useWatch({
    control,
    name: "showSubscriptionsHelpIcon",
  });

  return (
    <Section>
      <Text variant="bodyLg" as="p" fontWeight="bold">
        Reviews
      </Text>

      <Controller
        control={control}
        name="enableReviews"
        render={({ field: { value, ...field } }) => (
          <Checkbox label="Show product reviews" checked={value} {...field} />
        )}
      />

      {!!enableReviews && (
        <Controller
          control={control}
          name="reviewsSource"
          render={({ field: { ref, ...field } }) => (
            <Select
              label="Reviews app you use"
              options={[
                { label: "Shopify", value: "shopify" },
                { label: "Yotpo", value: "yotpo" },
                { label: "Judge.me", value: "judgeme" },
                { label: "Loox", value: "loox" },
                { label: "Stamped", value: "stamped" },
                { label: "Junip", value: "junip" },
                { label: "Opinew", value: "opinew" },
                { label: "Ryviu", value: "ryviu" },
                { label: "Rivyo", value: "rivyo" },
                { label: "Reviews.io", value: "reviewsio" },
                { label: "Okendo", value: "okendo" },
              ]}
              {...field}
            />
          )}
        />
      )}

      <Text variant="bodyLg" as="p" fontWeight="bold">
        Cart &amp; Side Cart Drawer
      </Text>

      <Controller
        control={control}
        name="noCart"
        render={({ field: { value, ...field } }) => (
          <Checkbox
            label="Do not open the cart page, stay on the quiz page when adding a product to the cart."
            checked={value}
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="cartPage"
        render={({ field: { ref, ...field } }) => (
          <Select
            label="When adding to cart, open:"
            options={[
              { label: "Default Cart Page", value: "default" },
              { label: "Rebuy Smart Cart", value: "rebuy" },
              { label: "Dawn Cart Drawer", value: "dawn" },
              { label: "Refresh Cart Drawer", value: "refresh" },
              { label: "Impact Cart Drawer", value: "impact" },
              { label: "Focal Cart Drawer", value: "focal" },
              { label: "Split Cart Drawer", value: "split" },
              { label: "Corner Cart Drawer", value: "corner" },
              { label: "Concept Cart Drawer", value: "concept" },
              { label: "Enterprise Cart Drawer", value: "enterprise" },
              { label: "Shine Cart Drawer", value: "shine" },
              { label: "Sahara Cart Drawer", value: "sahara" },
            ]}
            {...field}
          />
        )}
      />

      <Text variant="bodyLg" as="p" fontWeight="bold">
        Subscriptions
      </Text>

      <Controller
        control={control}
        name="subscriptionsService"
        render={({ field: { ref, ...field } }) => (
          <Select
            label="Subscriptions app you use"
            options={[
              { label: "None", value: "none" },
              {
                label: "Shopify Subscriptions",
                value: "shopify_subscriptions",
              },
              { label: "Recharge", value: "recharge" },
              { label: "Skio", value: "skio" },
              { label: "Seal", value: "seal" },
              { label: "Ordergroove", value: "ordergroove" },
              {
                label: "Utterbond",
                value: "utterbond_subscriptions",
              },
              {
                label: "Loop",
                value: "loop_subscriptions",
              },
              { label: "Appstle", value: "appstle" },
              { label: "Subi", value: "subi" },
              { label: "Bold", value: "bold" },
              { label: "PayWhirl", value: "paywhirl" },
              { label: "Super", value: "super" },
              { label: "Yotpo", value: "yotpo_subscriptions" },
              { label: "Recurpay", value: "recurpay" },
              { label: "Casa", value: "casa" },
              { label: "Smartrr", value: "smartrr_subscriptions" },
              { label: "Propel", value: "propel" },
              { label: "Subscription Plus", value: "subscription_plus" },
            ]}
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="defaultSubscriptionsOption"
        render={({ field: { ref, ...field } }) => (
          <Select
            label="Default Selection"
            options={[
              { label: "Subscribe & Save", value: "subscribe-save" },
              { label: "One-Time Purchase", value: "one-time" },
            ]}
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="oneTimeLabel"
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="One-Time Purchase Label"
            autoComplete="off"
            placeholder="One-Time Purchase"
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="subscribeSaveLabel"
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Subscribe & Save Label"
            autoComplete="off"
            placeholder="Subscribe & Save"
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="showSubscriptionsHelpIcon"
        render={({ field: { value, ...field } }) => (
          <Checkbox label="Show help icon" checked={value} {...field} />
        )}
      />

      {showHelpIcon && (
        <Controller
          control={control}
          name="subscriptionsHelpText"
          render={({ field: { ref, ...field } }) => (
            <TextField
              label="Help Text"
              autoComplete="off"
              placeholder="Products are automatically delivered on your schedule. No obligation, modify or cancel your subscription anytime."
              multiline={3}
              {...field}
            />
          )}
        />
      )}
    </Section>
  );
};

const ProductRecommendationsSettings = ({ control }) => {
  return (
    <Section>
      <Controller
        control={control}
        name="enableSoldOutProducts"
        render={({ field: { value, ...field } }) => (
          <Checkbox label="Show sold out products" checked={value} {...field} />
        )}
      />

      <Controller
        control={control}
        name="hideDefaultRecommendations"
        render={({ field: { value, ...field } }) => (
          <Checkbox
            label="Hide default recommendations"
            checked={value}
            helpText="Use this option to only recommend products through Dynamic Content Blocks."
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="resultsAmount"
        render={({ field: { ref, ...field } }) => (
          <Select
            label="Total Product Recommendations"
            options={resultsOptions}
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="resultsPerRow"
        render={({ field: { ref, ...field } }) => (
          <RangeSlider
            output
            label="Results Per Row (Desktop)"
            min={1}
            max={10}
            step={1}
            {...field}
            prefix={`${field.value}`}
          />
        )}
      />

      <Controller
        control={control}
        name="resultsPerRowMobile"
        render={({ field: { ref, ...field } }) => (
          <RangeSlider
            output
            label="Results Per Row (Mobile)"
            min={1}
            max={10}
            step={1}
            {...field}
            prefix={`${field.value}`}
          />
        )}
      />

      <Controller
        control={control}
        name="matchThreshold"
        render={({ field: { ref, ...field } }) => (
          <RangeSlider
            output
            label="Match Score Threshold"
            min={0}
            max={100}
            step={5}
            {...field}
            suffix={`${field.value}%`}
            helpText="The minimum match score a product must have to be shown in the results."
          />
        )}
      />

      <Controller
        control={control}
        name="weightThreshold"
        render={({ field: { ref, ...field } }) => (
          <RangeSlider
            output
            label="Total Weight Threshold"
            min={0}
            max={100000}
            step={100}
            {...field}
            suffix={`${field.value}`}
            helpText="Only if you use custom answer weights - the minimum total weight a product must have to be shown in the results."
          />
        )}
      />

      <Controller
        control={control}
        name="matchFormat"
        render={({ field: { ref, ...field } }) => (
          <Select
            label="Match Score Format"
            options={formatOptions}
            {...field}
          />
        )}
      />

      <Tooltip
        content={
          <div>
            Determine which result to show first in case the match count is the
            same across several results. For example, if three of your results
            have a 8/10 match, this setting will determine the order those three
            results are displayed.
          </div>
        }
      >
        <Controller
          control={control}
          name="resultSortingPriority"
          render={({ field: { ref, ...field } }) => (
            <Select
              label="Sorting Priority"
              options={priorityOptions}
              {...field}
            />
          )}
        />
      </Tooltip>
    </Section>
  );
};

const URLSettings = ({ control }) => {
  return (
    <Section>
      <Controller
        control={control}
        name="returnToResults"
        render={({ field: { value, ...field } }) => (
          <Checkbox
            label="Return to results page URL automatically"
            checked={value}
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="enableRestart"
        render={({ field: { value, ...field } }) => (
          <Checkbox
            label="Allow quiz takers to restart - show Start Over button"
            checked={value}
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="openInNewTab"
        render={({ field: { value, ...field } }) => (
          <Checkbox label="Open links in new tab" checked={value} {...field} />
        )}
      />

      <Controller
        control={control}
        name="useURLTrackingParameters"
        render={({ field: { value, ...field } }) => (
          <Checkbox
            label="Use URL tracking parameters"
            checked={value}
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="customUTM"
        render={({ field: { ref, ...field } }) => (
          <Input
            label="Custom UTM parameters"
            placeholder="utm_source=lantern&utm_campaign={slug}"
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="resultsRedirectUrl"
        render={({ field: { ref, ...field } }) => (
          <Input
            label="Instead of showing results, redirect to this URL"
            placeholder="https://example.com"
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="viewMoreRedirectUrl"
        render={({ field: { ref, ...field } }) => (
          <Input
            label="When clicking on the View More button, redirect to this URL"
            placeholder="leave blank to show more products"
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="noResultsRedirectUrl"
        render={({ field: { ref, ...field } }) => (
          <Input
            label="When no results are found, link to this URL"
            placeholder="/products"
            {...field}
          />
        )}
      />
    </Section>
  );
};

const DiscountSettings = ({ control }) => {
  return (
    <Section>
      <Controller
        control={control}
        name="discountCode"
        render={({ field: { ref, ...field } }) => (
          <Input
            label="Discount code"
            placeholder="leave empty to disable"
            {...field}
          />
        )}
      />
      <Text as="p" variant="bodySm">
        The discount code will be applied automatically to the cart of all
        customers who complete the quiz.
      </Text>
    </Section>
  );
};

const TaggingSettings = ({ control }) => {
  return (
    <Section>
      <Text as="p" variant="bodyMd" fontWeight="bold">
        Order Tags
      </Text>

      <Controller
        control={control}
        name="tagOrders"
        render={({ field: { value, ...field } }) => (
          <Checkbox label="Enable order tagging" checked={value} {...field} />
        )}
      />
      <Controller
        control={control}
        name="customTag"
        render={({ field: { ref, ...field } }) => (
          <Input label="Order tag" placeholder="Lantern Quiz" {...field} />
        )}
      />

      <Text as="p" variant="bodyMd" fontWeight="bold">
        Customer Tags
      </Text>

      <Controller
        control={control}
        name="addCustomerTags"
        render={({ field: { value, ...field } }) => (
          <Checkbox
            label="Enable customer tagging"
            checked={value}
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="createCustomer"
        render={({ field: { value, ...field } }) => (
          <Checkbox
            label="Create Customer in Shopify"
            checked={value}
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="doubleOptIn"
        render={({ field: { value, ...field } }) => (
          <Checkbox
            label="Enable Shopify Double Opt-In - customers need to confirm marketing consent via email"
            checked={value}
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="customerTags"
        render={({ field: { ref, ...field } }) => (
          <Input label="Customer tags" placeholder="Lantern Quiz" {...field} />
        )}
      />
    </Section>
  );
};

const ShowHideSettings = ({ control }) => {
  return (
    <Section>
      <Controller
        control={control}
        name="showProductDescription"
        render={({ field: { value, ...field } }) => (
          <Checkbox
            label="Show product description"
            checked={value}
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="showShortDescription"
        render={({ field: { value, ...field } }) => (
          <Checkbox
            label="Show short description from product metafields"
            helpText={
              <>
                Learn more about{" "}
                <Link
                  url="https://help.trylantern.com/en/articles/8983472-add-a-short-description-using-product-metafields"
                  external
                >
                  the description metafield.
                </Link>
              </>
            }
            checked={value}
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="customDescriptionMetafield"
        render={({ field: { ref, ...field } }) => (
          <Input
            label="Metafield name"
            placeholder="lantern_description"
            {...field}
          />
        )}
      />
    </Section>
  );
};

const HeadlineSettings = ({ control }) => {
  return (
    <>
      <Section>
        <Controller
          control={control}
          name="results_questionAlignment"
          render={({ field: { value, onChange, ...field } }) => (
            <InlineStack
              align="space-between"
              blockAlign="center"
              wrap={false}
              gap="100"
            >
              <RadioButton
                label={<Icon source={TextAlignLeftIcon as any} tone="base" />}
                checked={value === "left"}
                onChange={() => onChange("left")}
                {...field}
              />
              <RadioButton
                label={<Icon source={TextAlignCenterIcon as any} tone="base" />}
                checked={value === "center"}
                onChange={() => onChange("center")}
                {...field}
              />
              <RadioButton
                label={<Icon source={TextAlignRightIcon as any} tone="base" />}
                checked={value === "right"}
                onChange={() => onChange("right")}
                {...field}
              />
            </InlineStack>
          )}
        />
        <Controller
          control={control}
          name="results_questionFontSize"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Font Size"
              value={value}
              onChange={onChange}
              min={1}
              max={100}
              step={1}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {value}px
                </p>
              }
              {...field}
            />
          )}
        />
      </Section>
    </>
  );
};

const SubheadlineSettings = ({ control }) => {
  return (
    <>
      <Section>
        <Controller
          control={control}
          name="results_descriptionAlignment"
          render={({ field: { value, onChange, ...field } }) => (
            <InlineStack
              align="space-between"
              blockAlign="center"
              wrap={false}
              gap="100"
            >
              <RadioButton
                label={<Icon source={TextAlignLeftIcon} tone="base" />}
                checked={value === "left"}
                onChange={() => onChange("left")}
                {...field}
              />
              <RadioButton
                label={<Icon source={TextAlignCenterIcon} tone="base" />}
                checked={value === "center"}
                onChange={() => onChange("center")}
                {...field}
              />
              <RadioButton
                label={<Icon source={TextAlignRightIcon} tone="base" />}
                checked={value === "right"}
                onChange={() => onChange("right")}
                {...field}
              />
            </InlineStack>
          )}
        />
        <Controller
          control={control}
          name="results_descriptionFontSize"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Font Size"
              value={value}
              onChange={onChange}
              min={1}
              max={100}
              step={1}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {value}px
                </p>
              }
              {...field}
            />
          )}
        />
      </Section>
    </>
  );
};

const GeneralDesignSettings = ({ control }) => {
  const topWidthMobileType = useWatch({
    name: "results_topWidthMobileType",
    control,
  });
  const bottomWidthMobileType = useWatch({
    name: "results_bottomWidthMobileType",
    control,
  });
  const topWidthDesktopType = useWatch({
    name: "results_topWidthDesktopType",
    control,
  });
  const bottomWidthDesktopType = useWatch({
    name: "results_bottomWidthDesktopType",
    control,
  });

  return (
    <>
      <Section
        title={
          (
            <InlineStack
              gap="100"
              align="center"
              blockAlign="center"
              wrap={false}
            >
              <Icon source={DesktopIcon} />
              <span style={{ flex: "1" }}>Desktop</span>
            </InlineStack>
          ) as any
        }
      >
        <Controller
          control={control}
          name="results_topWidthDesktopType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Top Section Width"
              value={value}
              {...field}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {topWidthDesktopType === "custom" && (
          <Controller
            control={control}
            name="results_topWidth"
            render={({ field: { value, onChange, ...field } }) => (
              <TextField
                label=""
                value={value}
                onChange={onChange}
                type="text"
                autoComplete="off"
                connectedLeft={
                  <span
                    style={{
                      position: "relative",
                      top: "10px",
                      display: "inline-block",
                      marginRight: "5px",
                    }}
                  >
                    <RangeSlider
                      label=""
                      value={value}
                      onChange={onChange}
                      min={0}
                      max={1500}
                      step={5}
                      output
                    />
                  </span>
                }
                suffix="px"
                {...field}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="results_bottomWidthDesktopType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Bottom Section Width"
              value={value}
              {...field}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {bottomWidthDesktopType === "custom" && (
          <Controller
            control={control}
            name="results_bottomWidth"
            render={({ field: { value, onChange, ...field } }) => (
              <TextField
                label=""
                value={value}
                onChange={onChange}
                type="text"
                autoComplete="off"
                connectedLeft={
                  <span
                    style={{
                      position: "relative",
                      top: "10px",
                      display: "inline-block",
                      marginRight: "5px",
                    }}
                  >
                    <RangeSlider
                      label=""
                      value={value}
                      onChange={onChange}
                      min={0}
                      max={1500}
                      step={5}
                      output
                    />
                  </span>
                }
                suffix="px"
                {...field}
              />
            )}
          />
        )}
      </Section>
      <Section
        title={
          (
            <InlineStack
              gap="100"
              align="center"
              blockAlign="center"
              wrap={false}
            >
              <Icon source={MobileIcon} />
              <span style={{ flex: "1" }}>Mobile</span>
            </InlineStack>
          ) as any
        }
      >
        <Controller
          control={control}
          name="results_topWidthMobileType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Top Section Width"
              value={value}
              {...field}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {topWidthMobileType === "custom" && (
          <Controller
            control={control}
            name="results_topWidthMobile"
            render={({ field: { value, onChange, ...field } }) => (
              <RangeSlider
                value={value}
                onChange={onChange}
                label=""
                min={0}
                max={1500}
                step={5}
                output
                suffix={
                  <p
                    style={{
                      minWidth: "24px",
                      textAlign: "right",
                    }}
                  >
                    {`${value}px`}
                  </p>
                }
                {...field}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="results_bottomWidthMobileType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Bottom Section Width"
              value={value}
              {...field}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {bottomWidthMobileType === "custom" && (
          <Controller
            control={control}
            name="results_bottomWidthMobile"
            render={({ field: { value, onChange, ...field } }) => (
              <RangeSlider
                value={value}
                onChange={onChange}
                label=""
                min={0}
                max={1500}
                step={5}
                output
                suffix={
                  <p
                    style={{
                      minWidth: "24px",
                      textAlign: "right",
                    }}
                  >
                    {`${value}px`}
                  </p>
                }
                {...field}
              />
            )}
          />
        )}
      </Section>
    </>
  );
};

const ATCButtonSettings = ({ control }) => {
  const useDefaultColorForButton = useWatch({
    name: "atc_useDefaultColorForButton",
    control,
  });
  const useDefaultTextColorForButton = useWatch({
    name: "atc_useDefaultTextColorForButton",
    control,
  });
  const buttonWidthType = useWatch({
    name: "atc_buttonWidthType",
    control,
  });

  return (
    <Section>
      <Controller
        control={control}
        name="enableAddToCart"
        render={({ field: { value, ...field } }) => (
          <Checkbox label="Show add to cart" checked={value} {...field} />
        )}
      />
      <Controller
        control={control}
        name="showVisualMatchBlock"
        render={({ field: { value, ...field } }) => (
          <Checkbox
            label="Show the matches block above the add-to-cart button"
            checked={value}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="atc_buttonTextOverride"
        render={({ field: { value, onChange, ...field } }) => (
          <TextField
            label="Button Text Override"
            value={value}
            onChange={onChange}
            autoComplete="off"
            placeholder="leave empty to use default text"
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="atc_buttonAlignment"
        render={({ field: { value, onChange, ...field } }) => (
          <InlineStack
            align="space-between"
            blockAlign="center"
            wrap={false}
            gap="100"
          >
            <RadioButton
              label={<Icon source={TextAlignLeftIcon as any} tone="base" />}
              checked={value === "left"}
              onChange={() => onChange("left")}
              {...field}
            />
            <RadioButton
              label={<Icon source={TextAlignCenterIcon as any} tone="base" />}
              checked={value === "center"}
              onChange={() => onChange("center")}
              {...field}
            />
            <RadioButton
              label={<Icon source={TextAlignRightIcon as any} tone="base" />}
              checked={value === "right"}
              onChange={() => onChange("right")}
              {...field}
            />
          </InlineStack>
        )}
      />
      <InlineStack
        gap="200"
        align="space-between"
        blockAlign="end"
        wrap={false}
      >
        <Controller
          control={control}
          name="atc_useDefaultColorForButton"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Background Color"
              value={value}
              {...field}
              options={[
                { label: "Use default color", value: "yes" },
                { label: "Use custom color", value: "no" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />

        {useDefaultColorForButton === "no" && (
          <Controller
            control={control}
            name="atc_buttonColor"
            render={({ field: { value, onChange, ...field } }) => (
              <ColorInput value={value} onChange={onChange} {...field} />
            )}
          />
        )}
      </InlineStack>
      <InlineStack
        gap="200"
        align="space-between"
        blockAlign="end"
        wrap={false}
      >
        <Controller
          control={control}
          name="atc_useDefaultTextColorForButton"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Text Color"
              value={value}
              {...field}
              options={[
                { label: "Use default color", value: "yes" },
                { label: "Use custom color", value: "no" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {useDefaultTextColorForButton === "no" && (
          <Controller
            control={control}
            name="atc_buttonTextColor"
            render={({ field: { value, onChange, ...field } }) => (
              <ColorInput value={value} onChange={onChange} {...field} />
            )}
          />
        )}
      </InlineStack>
      <Section
        title={
          (
            <InlineStack
              align="center"
              blockAlign="center"
              gap="100"
              wrap={false}
            >
              <Icon source={DesktopIcon as any} />
              <span style={{ flex: "1" }}>Desktop</span>
            </InlineStack>
          ) as any
        }
      >
        <Controller
          control={control}
          name="atc_buttonFontSize"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Font Size"
              value={value}
              onChange={onChange}
              min={8}
              max={50}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="atc_buttonBorderRadius"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Border Radius"
              value={value}
              onChange={onChange}
              min={0}
              max={60}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="atc_buttonWidthType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Width"
              value={value}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
                { label: "Auto Width", value: "auto" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {buttonWidthType === "custom" && (
          <Controller
            control={control}
            name="atc_buttonWidth"
            render={({ field: { value, onChange, ...field } }) => (
              <RangeSlider
                value={value}
                onChange={onChange}
                label=""
                min={0}
                max={1500}
                step={5}
                output
                suffix={
                  <p
                    style={{
                      minWidth: "24px",
                      textAlign: "right",
                    }}
                  >
                    {`${value}px`}
                  </p>
                }
                {...field}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="atc_buttonHorizontalPadding"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Horizontal Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={500}
              step={5}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="atc_buttonVerticalPadding"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Vertical Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={150}
              step={1}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
      </Section>
      <Section
        title={
          (
            <InlineStack
              gap="100"
              align="center"
              blockAlign="center"
              wrap={false}
            >
              <Icon source={MobileIcon as any} />
              <span style={{ flex: "1" }}>Mobile</span>
            </InlineStack>
          ) as any
        }
      >
        <Controller
          control={control}
          name="atc_buttonFontSizeMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Font Size"
              value={value}
              onChange={onChange}
              min={8}
              max={50}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="atc_buttonBorderRadiusMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Border Radius"
              value={value}
              onChange={onChange}
              min={0}
              max={60}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="atc_buttonWidthMobileType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Width"
              value={value}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
                { label: "Auto Width", value: "auto" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {buttonWidthType === "custom" && (
          <Controller
            control={control}
            name="atc_buttonWidthMobile"
            render={({ field: { value, onChange, ...field } }) => (
              <RangeSlider
                value={value}
                onChange={onChange}
                label=""
                min={0}
                max={780}
                step={5}
                output
                suffix={
                  <p
                    style={{
                      minWidth: "24px",
                      textAlign: "right",
                    }}
                  >
                    {`${value}px`}
                  </p>
                }
                {...field}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="atc_buttonHorizontalPaddingMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Horizontal Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={500}
              step={5}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="atc_buttonVerticalPaddingMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Vertical Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={150}
              step={1}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
      </Section>
    </Section>
  );
};

const AddAllButtonSettings = ({ control }) => {
  const useDefaultColorForButton = useWatch({
    name: "addAll_useDefaultColorForButton",
    control,
  });
  const useDefaultTextColorForButton = useWatch({
    name: "addAll_useDefaultTextColorForButton",
    control,
  });
  const buttonWidthType = useWatch({
    name: "addAll_buttonWidthType",
    control,
  });

  return (
    <Section>
      <Controller
        control={control}
        name="enableAddAll"
        render={({ field: { value, ...field } }) => (
          <Checkbox
            label="Show Add All To Cart button"
            checked={value}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="addAll_buttonTextOverride"
        render={({ field: { value, onChange, ...field } }) => (
          <TextField
            label="Button Text Override"
            value={value}
            onChange={onChange}
            autoComplete="off"
            placeholder="leave empty to use default text"
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="addAll_buttonAlignment"
        render={({ field: { value, onChange, ...field } }) => (
          <InlineStack
            align="space-between"
            blockAlign="center"
            wrap={false}
            gap="100"
          >
            <RadioButton
              label={<Icon source={TextAlignLeftIcon as any} tone="base" />}
              checked={value === "left"}
              onChange={() => onChange("left")}
              {...field}
            />
            <RadioButton
              label={<Icon source={TextAlignCenterIcon as any} tone="base" />}
              checked={value === "center"}
              onChange={() => onChange("center")}
              {...field}
            />
            <RadioButton
              label={<Icon source={TextAlignRightIcon as any} tone="base" />}
              checked={value === "right"}
              onChange={() => onChange("right")}
              {...field}
            />
          </InlineStack>
        )}
      />
      <InlineStack
        gap="200"
        align="space-between"
        blockAlign="end"
        wrap={false}
      >
        <Controller
          control={control}
          name="addAll_useDefaultColorForButton"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Background Color"
              value={value}
              {...field}
              options={[
                { label: "Use default color", value: "yes" },
                { label: "Use custom color", value: "no" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />

        {useDefaultColorForButton === "no" && (
          <Controller
            control={control}
            name="addAll_buttonColor"
            render={({ field: { value, onChange, ...field } }) => (
              <ColorInput value={value} onChange={onChange} {...field} />
            )}
          />
        )}
      </InlineStack>
      <InlineStack
        gap="200"
        align="space-between"
        blockAlign="end"
        wrap={false}
      >
        <Controller
          control={control}
          name="addAll_useDefaultTextColorForButton"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Text Color"
              value={value}
              {...field}
              options={[
                { label: "Use default color", value: "yes" },
                { label: "Use custom color", value: "no" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {useDefaultTextColorForButton === "no" && (
          <Controller
            control={control}
            name="addAll_buttonTextColor"
            render={({ field: { value, onChange, ...field } }) => (
              <ColorInput value={value} onChange={onChange} {...field} />
            )}
          />
        )}
      </InlineStack>
      <Section
        title={
          (
            <InlineStack
              align="center"
              blockAlign="center"
              gap="100"
              wrap={false}
            >
              <Icon source={DesktopIcon as any} />
              <span style={{ flex: "1" }}>Desktop</span>
            </InlineStack>
          ) as any
        }
      >
        <Controller
          control={control}
          name="addAll_buttonFontSize"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Font Size"
              value={value}
              onChange={onChange}
              min={8}
              max={50}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="addAll_buttonBorderRadius"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Border Radius"
              value={value}
              onChange={onChange}
              min={0}
              max={60}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="addAll_buttonWidthType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Width"
              value={value}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
                { label: "Auto Width", value: "auto" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {buttonWidthType === "custom" && (
          <Controller
            control={control}
            name="addAll_buttonWidth"
            render={({ field: { value, onChange, ...field } }) => (
              <RangeSlider
                value={value}
                onChange={onChange}
                label=""
                min={0}
                max={1500}
                step={5}
                output
                suffix={
                  <p
                    style={{
                      minWidth: "24px",
                      textAlign: "right",
                    }}
                  >
                    {`${value}px`}
                  </p>
                }
                {...field}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="addAll_buttonHorizontalPadding"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Horizontal Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={500}
              step={5}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="addAll_buttonVerticalPadding"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Vertical Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={150}
              step={1}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
      </Section>
      <Section
        title={
          (
            <InlineStack
              gap="100"
              align="center"
              blockAlign="center"
              wrap={false}
            >
              <Icon source={MobileIcon as any} />
              <span style={{ flex: "1" }}>Mobile</span>
            </InlineStack>
          ) as any
        }
      >
        <Controller
          control={control}
          name="addAll_buttonFontSizeMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Font Size"
              value={value}
              onChange={onChange}
              min={8}
              max={50}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="addAll_buttonBorderRadiusMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Border Radius"
              value={value}
              onChange={onChange}
              min={0}
              max={60}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="addAll_buttonWidthMobileType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Width"
              value={value}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
                { label: "Auto Width", value: "auto" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {buttonWidthType === "custom" && (
          <Controller
            control={control}
            name="addAll_buttonWidthMobile"
            render={({ field: { value, onChange, ...field } }) => (
              <RangeSlider
                value={value}
                onChange={onChange}
                label=""
                min={0}
                max={780}
                step={5}
                output
                suffix={
                  <p
                    style={{
                      minWidth: "24px",
                      textAlign: "right",
                    }}
                  >
                    {`${value}px`}
                  </p>
                }
                {...field}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="addAll_buttonHorizontalPaddingMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Horizontal Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={500}
              step={5}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="addAll_buttonVerticalPaddingMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Vertical Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={150}
              step={1}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
      </Section>
    </Section>
  );
};

const ViewMoreButtonSettings = ({ control }) => {
  const useDefaultColorForButton = useWatch({
    name: "viewMore_useDefaultColorForButton",
    control,
  });
  const useDefaultTextColorForButton = useWatch({
    name: "viewMore_useDefaultTextColorForButton",
    control,
  });
  const buttonWidthType = useWatch({
    name: "viewMore_buttonWidthType",
    control,
  });
  const enableMoreResults = useWatch({
    control,
    name: "enableMoreResults",
  });

  return (
    <Section>
      <Controller
        control={control}
        name="enableMoreResults"
        render={({ field: { value, ...field } }) => (
          <Checkbox label="Show more results" checked={value} {...field} />
        )}
      />

      {!!enableMoreResults && (
        <Controller
          control={control}
          name="extraAmount"
          render={({ field: { ref, ...field } }) => (
            <Select
              label="Total Extra Results"
              options={extraOptions}
              {...field}
            />
          )}
        />
      )}
      <Controller
        control={control}
        name="viewMore_buttonTextOverride"
        render={({ field: { value, onChange, ...field } }) => (
          <TextField
            label="Button Text Override"
            value={value}
            onChange={onChange}
            autoComplete="off"
            placeholder="leave empty to use default text"
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="viewMore_buttonAlignment"
        render={({ field: { value, onChange, ...field } }) => (
          <InlineStack
            align="space-between"
            blockAlign="center"
            wrap={false}
            gap="100"
          >
            <RadioButton
              label={<Icon source={TextAlignLeftIcon as any} tone="base" />}
              checked={value === "left"}
              onChange={() => onChange("left")}
              {...field}
            />
            <RadioButton
              label={<Icon source={TextAlignCenterIcon as any} tone="base" />}
              checked={value === "center"}
              onChange={() => onChange("center")}
              {...field}
            />
            <RadioButton
              label={<Icon source={TextAlignRightIcon as any} tone="base" />}
              checked={value === "right"}
              onChange={() => onChange("right")}
              {...field}
            />
          </InlineStack>
        )}
      />
      <InlineStack
        gap="200"
        align="space-between"
        blockAlign="end"
        wrap={false}
      >
        <Controller
          control={control}
          name="viewMore_useDefaultColorForButton"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Background Color"
              value={value}
              {...field}
              options={[
                { label: "Use default color", value: "yes" },
                { label: "Use custom color", value: "no" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />

        {useDefaultColorForButton === "no" && (
          <Controller
            control={control}
            name="viewMore_buttonColor"
            render={({ field: { value, onChange, ...field } }) => (
              <ColorInput value={value} onChange={onChange} {...field} />
            )}
          />
        )}
      </InlineStack>
      <InlineStack
        gap="200"
        align="space-between"
        blockAlign="end"
        wrap={false}
      >
        <Controller
          control={control}
          name="viewMore_useDefaultTextColorForButton"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Text Color"
              value={value}
              {...field}
              options={[
                { label: "Use default color", value: "yes" },
                { label: "Use custom color", value: "no" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {useDefaultTextColorForButton === "no" && (
          <Controller
            control={control}
            name="viewMore_buttonTextColor"
            render={({ field: { value, onChange, ...field } }) => (
              <ColorInput value={value} onChange={onChange} {...field} />
            )}
          />
        )}
      </InlineStack>
      <Section
        title={
          (
            <InlineStack
              align="center"
              blockAlign="center"
              gap="100"
              wrap={false}
            >
              <Icon source={DesktopIcon as any} />
              <span style={{ flex: "1" }}>Desktop</span>
            </InlineStack>
          ) as any
        }
      >
        <Controller
          control={control}
          name="viewMore_buttonFontSize"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Font Size"
              value={value}
              onChange={onChange}
              min={8}
              max={50}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="viewMore_buttonBorderRadius"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Border Radius"
              value={value}
              onChange={onChange}
              min={0}
              max={60}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="viewMore_buttonWidthType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Width"
              value={value}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
                { label: "Auto Width", value: "auto" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {buttonWidthType === "custom" && (
          <Controller
            control={control}
            name="viewMore_buttonWidth"
            render={({ field: { value, onChange, ...field } }) => (
              <RangeSlider
                value={value}
                onChange={onChange}
                label=""
                min={0}
                max={1500}
                step={5}
                output
                suffix={
                  <p
                    style={{
                      minWidth: "24px",
                      textAlign: "right",
                    }}
                  >
                    {`${value}px`}
                  </p>
                }
                {...field}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="viewMore_buttonHorizontalPadding"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Horizontal Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={500}
              step={5}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="viewMore_buttonVerticalPadding"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Vertical Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={150}
              step={1}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
      </Section>
      <Section
        title={
          (
            <InlineStack
              gap="100"
              align="center"
              blockAlign="center"
              wrap={false}
            >
              <Icon source={MobileIcon as any} />
              <span style={{ flex: "1" }}>Mobile</span>
            </InlineStack>
          ) as any
        }
      >
        <Controller
          control={control}
          name="viewMore_buttonFontSizeMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Font Size"
              value={value}
              onChange={onChange}
              min={8}
              max={50}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="viewMore_buttonBorderRadiusMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Border Radius"
              value={value}
              onChange={onChange}
              min={0}
              max={60}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="viewMore_buttonWidthMobileType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Width"
              value={value}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
                { label: "Auto Width", value: "auto" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {buttonWidthType === "custom" && (
          <Controller
            control={control}
            name="viewMore_buttonWidthMobile"
            render={({ field: { value, onChange, ...field } }) => (
              <RangeSlider
                value={value}
                onChange={onChange}
                label=""
                min={0}
                max={780}
                step={5}
                output
                suffix={
                  <p
                    style={{
                      minWidth: "24px",
                      textAlign: "right",
                    }}
                  >
                    {`${value}px`}
                  </p>
                }
                {...field}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="viewMore_buttonHorizontalPaddingMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Horizontal Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={500}
              step={5}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="viewMore_buttonVerticalPaddingMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Vertical Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={150}
              step={1}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
      </Section>
    </Section>
  );
};

export const ResultsOptions = () => {
  const { control } = useOptionsForm({
    getDefaultValues,
    formatValues: (values) => ({
      ...values,
      resultsAmount: parseInt(values.resultsAmount, 10),
      extraAmount: parseInt(values.extraAmount, 10),
      resultsPerRow: parseInt(values.resultsPerRow, 10),
      resultsPerRowMobile: parseInt(values.resultsPerRowMobile, 10),
      matchThreshold: parseInt(values.matchThreshold, 10),
      weightThreshold: parseInt(values.weightThreshold, 10),
    }),
    pageSettingsKeys: [
      // headline settings
      "results_questionAlignment",
      "results_questionFontSize",
      // subheadline settings
      "results_descriptionAlignment",
      "results_descriptionFontSize",
      // general design
      "results_topWidth",
      "results_topWidthDesktopType",
      "results_bottomWidth",
      "results_bottomWidthDesktopType",
      "results_topWidthMobileType",
      "results_topWidthMobile",
      "results_bottomWidthMobileType",
      "results_bottomWidthMobile",
      // add to cart button
      "atc_buttonColor",
      "atc_useDefaultColorForButton",
      "atc_useDefaultTextColorForButton",
      "atc_buttonTextColor",
      "atc_buttonTextOverride",
      "atc_buttonFontSize",
      "atc_buttonHorizontalPadding",
      "atc_buttonVerticalPadding",
      "atc_buttonBorderRadius",
      "atc_buttonWidthType",
      "atc_buttonWidth",
      "atc_buttonFontSizeMobile",
      "atc_buttonBorderRadiusMobile",
      "atc_buttonHorizontalPaddingMobile",
      "atc_buttonVerticalPaddingMobile",
      "atc_buttonWidthMobileType",
      "atc_buttonWidthMobile",
      "atc_buttonAlignment",
      // add all to cart button
      "addAll_buttonColor",
      "addAll_useDefaultColorForButton",
      "addAll_useDefaultTextColorForButton",
      "addAll_buttonTextColor",
      "addAll_buttonTextOverride",
      "addAll_buttonFontSize",
      "addAll_buttonHorizontalPadding",
      "addAll_buttonVerticalPadding",
      "addAll_buttonBorderRadius",
      "addAll_buttonWidthType",
      "addAll_buttonWidth",
      "addAll_buttonFontSizeMobile",
      "addAll_buttonBorderRadiusMobile",
      "addAll_buttonHorizontalPaddingMobile",
      "addAll_buttonVerticalPaddingMobile",
      "addAll_buttonWidthMobileType",
      "addAll_buttonWidthMobile",
      "addAll_buttonAlignment",
      // view more button
      "viewMore_buttonColor",
      "viewMore_useDefaultColorForButton",
      "viewMore_useDefaultTextColorForButton",
      "viewMore_buttonTextColor",
      "viewMore_buttonTextOverride",
      "viewMore_buttonFontSize",
      "viewMore_buttonHorizontalPadding",
      "viewMore_buttonVerticalPadding",
      "viewMore_buttonBorderRadius",
      "viewMore_buttonWidthType",
      "viewMore_buttonWidth",
      "viewMore_buttonFontSizeMobile",
      "viewMore_buttonBorderRadiusMobile",
      "viewMore_buttonHorizontalPaddingMobile",
      "viewMore_buttonVerticalPaddingMobile",
      "viewMore_buttonWidthMobileType",
      "viewMore_buttonWidthMobile",
      "viewMore_buttonAlignment",
    ],
    type: "flow",
  });

  const [settings, setSettings] = useState(0);

  return (
    <SettingsContainer>
      <BlockStack align="start" inlineAlign="stretch" gap="200">
        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 1 ? "critical" : undefined}
          disclosure={settings === 1 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 1 ? 0 : 1))}
          icon={CartIcon}
        >
          Reviews, Cart, Subscriptions
        </Button>
        <Collapsible
          open={settings === 1}
          id="collapsible-1"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <IntegrationSettings control={control} />
        </Collapsible>

        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 7 ? "critical" : undefined}
          disclosure={settings === 7 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 7 ? 0 : 7))}
          icon={PaintBrushFlatIcon}
        >
          General Design
        </Button>
        <Collapsible
          open={settings === 7}
          id="collapsible-7"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <GeneralDesignSettings control={control} />
        </Collapsible>

        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 8 ? "critical" : undefined}
          disclosure={settings === 8 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 8 ? 0 : 8))}
          icon={TextFontListIcon}
        >
          Headline Design
        </Button>
        <Collapsible
          open={settings === 8}
          id="collapsible-8"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <HeadlineSettings control={control} />
        </Collapsible>

        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 9 ? "critical" : undefined}
          disclosure={settings === 9 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 9 ? 0 : 9))}
          icon={TextFontListIcon}
        >
          Sub-headline Design
        </Button>
        <Collapsible
          open={settings === 9}
          id="collapsible-9"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <SubheadlineSettings control={control} />
        </Collapsible>

        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 10 ? "critical" : undefined}
          disclosure={settings === 10 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 10 ? 0 : 10))}
          icon={ButtonIcon}
        >
          Add To Cart Button
        </Button>
        <Collapsible
          open={settings === 10}
          id="collapsible-10"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <ATCButtonSettings control={control} />
        </Collapsible>

        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 11 ? "critical" : undefined}
          disclosure={settings === 11 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 11 ? 0 : 11))}
          icon={ButtonIcon}
        >
          Add All To Cart Button
        </Button>
        <Collapsible
          open={settings === 11}
          id="collapsible-11"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <AddAllButtonSettings control={control} />
        </Collapsible>

        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 12 ? "critical" : undefined}
          disclosure={settings === 12 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 12 ? 0 : 12))}
          icon={ButtonIcon}
        >
          View More Button
        </Button>
        <Collapsible
          open={settings === 12}
          id="collapsible-12"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <ViewMoreButtonSettings control={control} />
        </Collapsible>

        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 2 ? "critical" : undefined}
          disclosure={settings === 2 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 2 ? 0 : 2))}
          icon={TextWithImageIcon}
        >
          Product Description
        </Button>
        <Collapsible
          open={settings === 2}
          id="collapsible-2"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <ShowHideSettings control={control} />
        </Collapsible>

        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 3 ? "critical" : undefined}
          disclosure={settings === 3 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 3 ? 0 : 3))}
          icon={MagicIcon}
        >
          Product Recommendations
        </Button>
        <Collapsible
          open={settings === 3}
          id="collapsible-3"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <ProductRecommendationsSettings control={control} />
        </Collapsible>

        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 4 ? "critical" : undefined}
          disclosure={settings === 4 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 4 ? 0 : 4))}
          icon={LinkIcon as any}
        >
          URLs
        </Button>
        <Collapsible
          open={settings === 4}
          id="collapsible-4"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <URLSettings control={control} />
        </Collapsible>

        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 5 ? "critical" : undefined}
          disclosure={settings === 5 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 5 ? 0 : 5))}
          icon={ProductIcon}
        >
          Order &amp; Customer Tags
        </Button>
        <Collapsible
          open={settings === 5}
          id="collapsible-5"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <TaggingSettings control={control} />
        </Collapsible>

        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 6 ? "critical" : undefined}
          disclosure={settings === 6 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 6 ? 0 : 6))}
          icon={DiscountIcon}
        >
          Discount
        </Button>
        <Collapsible
          open={settings === 6}
          id="collapsible-6"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <DiscountSettings control={control} />
        </Collapsible>
      </BlockStack>
    </SettingsContainer>
  );
};

const SettingsContainer = styled.div`
  padding: 15px 5px;

  & .Polaris-Button__Content {
    width: 100%;
    justify-content: space-between !important;
  }
`;
