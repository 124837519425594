import { useMemo } from "react";
import {
  Button,
  Card,
  Grid,
  Layout,
  OptionList,
  Page,
  BlockStack,
  Text,
} from "@shopify/polaris";
import { PlusIcon } from "@shopify/polaris-icons";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { FlowNodeType, FlowNodeLayout } from "~/graphql/sdk";
import { useFlow, useNavParams, useSdk } from "~/hooks";
import { useBillingWrapper } from "~/lib";

const NewPagePanel = () => {
  const [selected, setSelected] = useState(["singleChoice"]);
  const [isBusy, setBusy] = useState(false);
  const [, setNavParams] = useNavParams();
  const sdk = useSdk();
  const { data } = useFlow();
  const currentFlow = data?.flows?.[0];
  const queryClient = useQueryClient();

  const numQuestions = useMemo(() => {
    return currentFlow?.nodes?.filter(
      ({ type }) => type !== FlowNodeType.Email && type !== FlowNodeType.Welcome
    ).length;
  }, [currentFlow]);

  const billingWrapperQuestions = useBillingWrapper({
    freeBeforeDate: new Date("2022-12-20"),
  });

  const createNewQuestion = async (type) => {
    if (
      type !== FlowNodeType.Email &&
      type !== FlowNodeType.Welcome &&
      numQuestions &&
      numQuestions >= 3
    ) {
      billingWrapperQuestions(() => {
        protectedCreateNewQuestion(type);
      })();
    } else {
      protectedCreateNewQuestion(type);
    }
  };

  const protectedCreateNewQuestion = async (type) => {
    if (isBusy || !currentFlow) {
      return;
    }

    setBusy(true);

    try {
      const isMulti =
        type === FlowNodeType.EmojiMulti ||
        type === FlowNodeType.ImageMulti ||
        type === FlowNodeType.SimpleMulti;

      const {
        createOneFlowNode: { id: newNodeId },
      } = await sdk.createOneFlowNode({
        input: {
          flowNode: {
            flowId: currentFlow.id,
            layout: FlowNodeLayout.Simple,
            isRequired: type !== FlowNodeType.Transition ? true : false,
            maxLength: type !== FlowNodeType.Transition ? "" : "3",
            type,
            nextQuestionOnSelection: !isMulti,
            options: [],
          },
        },
      });

      const nodes = currentFlow.nodes.map((node) => node.id);
      nodes.push(newNodeId);

      await sdk.updateFlowNodesOrder({
        input: {
          flowId: currentFlow.id,
          order: nodes,
        },
      });

      queryClient.invalidateQueries(["container"]);

      setNavParams({
        main: "0",
        question:
          type === FlowNodeType.Welcome
            ? "0"
            : currentFlow?.nodes?.[currentFlow?.nodes?.length - 1]?.type ===
              FlowNodeType.Email
            ? `${currentFlow?.nodes?.length - 1}`
            : `${currentFlow?.nodes?.length}`,
      });
    } catch (e: any) {
      console.log("error", { e });
    }

    setBusy(false);
  };

  return (
    <Page>
      <Layout>
        <Layout.Section variant="oneThird">
          <Card>
            <OptionList
              title="Page Type"
              onChange={setSelected}
              options={[
                { value: "singleChoice", label: "Single Choice Question" },
                { value: "multipleChoice", label: "Multiple Choice Question" },
                { value: "input", label: "Open Ended Question" },
                { value: "email", label: "Optin Page" },
                { value: "transition", label: "Transition Screen" },
              ]}
              selected={selected}
            />
          </Card>
        </Layout.Section>
        <Layout.Section>
          <Grid>
            {selected.includes("singleChoice") && (
              <>
                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                  <Card>
                    <BlockStack gap="200" align="center" inlineAlign="center">
                      <Text as="p" variant="bodyMd" fontWeight="bold">
                        Single Choice - Text Only
                      </Text>
                      <div style={{ height: "100px" }}>
                        <Text as="p" variant="bodyMd" tone="subdued">
                          Customers can select a single answer from a set of
                          options. Each option is text-only.
                        </Text>
                      </div>
                      <Button
                        loading={isBusy}
                        disabled={isBusy}
                        fullWidth
                        variant="primary"
                        icon={PlusIcon as any}
                        onClick={() => {
                          if (window?.analytics) {
                            window.analytics.track("Flow Builder - New Page", {
                              type: FlowNodeType.Simple,
                            });
                          }
                          createNewQuestion(FlowNodeType.Simple);
                        }}
                      >
                        Add Page
                      </Button>
                    </BlockStack>
                  </Card>
                </Grid.Cell>
                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                  <Card>
                    <BlockStack gap="100" align="center" inlineAlign="center">
                      <Text as="p" variant="bodyMd" fontWeight="bold">
                        Single Choice - Text &amp; Emoji
                      </Text>
                      <div style={{ height: "100px" }}>
                        <Text as="p" variant="bodyMd" tone="subdued">
                          Customers can select a single answer from a set of
                          options. Each option is comprised of text and emojis.
                        </Text>
                      </div>
                      <Button
                        loading={isBusy}
                        disabled={isBusy}
                        fullWidth
                        variant="primary"
                        icon={PlusIcon as any}
                        onClick={() => {
                          if (window?.analytics) {
                            window.analytics.track("Flow Builder - New Page", {
                              type: FlowNodeType.Emoji,
                            });
                          }
                          createNewQuestion(FlowNodeType.Emoji);
                        }}
                      >
                        Add Page
                      </Button>
                    </BlockStack>
                  </Card>
                </Grid.Cell>
                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                  <Card>
                    <BlockStack gap="100" align="center" inlineAlign="center">
                      <Text as="p" variant="bodyMd" fontWeight="bold">
                        Single Choice - Text &amp; Image
                      </Text>
                      <div style={{ height: "100px" }}>
                        <Text as="p" variant="bodyMd" tone="subdued">
                          Customers can select a single answer from a set of
                          options. Each option is comprised of text and images.
                        </Text>
                      </div>
                      <Button
                        loading={isBusy}
                        disabled={isBusy}
                        fullWidth
                        variant="primary"
                        icon={PlusIcon as any}
                        onClick={() => {
                          if (window?.analytics) {
                            window.analytics.track("Flow Builder - New Page", {
                              type: FlowNodeType.Image,
                            });
                          }
                          createNewQuestion(FlowNodeType.Image);
                        }}
                      >
                        Add Page
                      </Button>
                    </BlockStack>
                  </Card>
                </Grid.Cell>
              </>
            )}
            {selected.includes("multipleChoice") && (
              <>
                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                  <Card>
                    <BlockStack gap="100" align="center" inlineAlign="center">
                      <Text as="p" variant="bodyMd" fontWeight="bold">
                        Multiple Choice - Text Only
                      </Text>
                      <div style={{ height: "100px" }}>
                        <Text as="p" variant="bodyMd" tone="subdued">
                          Customers can select a multiple answers from a set of
                          options. Each option is comprised of text-only.
                        </Text>
                      </div>
                      <Button
                        loading={isBusy}
                        disabled={isBusy}
                        fullWidth
                        variant="primary"
                        icon={PlusIcon as any}
                        onClick={() => {
                          if (window?.analytics) {
                            window.analytics.track("Flow Builder - New Page", {
                              type: FlowNodeType.SimpleMulti,
                            });
                          }
                          createNewQuestion(FlowNodeType.SimpleMulti);
                        }}
                      >
                        Add Page
                      </Button>
                    </BlockStack>
                  </Card>
                </Grid.Cell>
                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                  <Card>
                    <BlockStack gap="100" align="center" inlineAlign="center">
                      <Text as="p" variant="bodyMd" fontWeight="bold">
                        Multiple Choice - Text &amp; Emoji
                      </Text>
                      <div style={{ height: "100px" }}>
                        <Text as="p" variant="bodyMd" tone="subdued">
                          Customers can select a multiple answers from a set of
                          options. Each option is comprised of text and emojis.
                        </Text>
                      </div>
                      <Button
                        loading={isBusy}
                        disabled={isBusy}
                        fullWidth
                        variant="primary"
                        icon={PlusIcon as any}
                        onClick={() => {
                          if (window?.analytics) {
                            window.analytics.track("Flow Builder - New Page", {
                              type: FlowNodeType.EmojiMulti,
                            });
                          }
                          createNewQuestion(FlowNodeType.EmojiMulti);
                        }}
                      >
                        Add Page
                      </Button>
                    </BlockStack>
                  </Card>
                </Grid.Cell>
                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                  <Card>
                    <BlockStack gap="100" align="center" inlineAlign="center">
                      <Text as="p" variant="bodyMd" fontWeight="bold">
                        Multiple Choice - Text &amp; Image
                      </Text>
                      <div style={{ height: "100px" }}>
                        <Text as="p" variant="bodyMd" tone="subdued">
                          Customers can select a multiple answers from a set of
                          options. Each option is comprised of text and images.
                        </Text>
                      </div>
                      <Button
                        loading={isBusy}
                        disabled={isBusy}
                        fullWidth
                        variant="primary"
                        icon={PlusIcon as any}
                        onClick={() => {
                          if (window?.analytics) {
                            window.analytics.track("Flow Builder - New Page", {
                              type: FlowNodeType.ImageMulti,
                            });
                          }
                          createNewQuestion(FlowNodeType.ImageMulti);
                        }}
                      >
                        Add Page
                      </Button>
                    </BlockStack>
                  </Card>
                </Grid.Cell>
              </>
            )}
            {selected.includes("input") && (
              <>
                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                  <Card>
                    <BlockStack gap="100" align="center" inlineAlign="center">
                      <Text as="p" variant="bodyMd" fontWeight="bold">
                        Single-Line Text Field
                      </Text>
                      <div style={{ height: "100px" }}>
                        <Text as="p" variant="bodyMd" tone="subdued">
                          Suitable for short answers (one line).
                        </Text>
                      </div>
                      <Button
                        loading={isBusy}
                        disabled={isBusy}
                        fullWidth
                        variant="primary"
                        icon={PlusIcon as any}
                        onClick={() => {
                          if (window?.analytics) {
                            window.analytics.track("Flow Builder - New Page", {
                              type: FlowNodeType.InputOneLineText,
                            });
                          }
                          createNewQuestion(FlowNodeType.InputOneLineText);
                        }}
                      >
                        Add Page
                      </Button>
                    </BlockStack>
                  </Card>
                </Grid.Cell>
                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                  <Card>
                    <BlockStack gap="100" align="center" inlineAlign="center">
                      <Text as="p" variant="bodyMd" fontWeight="bold">
                        Multi-Line Text Field
                      </Text>
                      <div style={{ height: "100px" }}>
                        <Text tone="subdued" variant="bodyMd" as="p">
                          Suitable for longer answers (multiple lines).
                        </Text>
                      </div>
                      <Button
                        loading={isBusy}
                        disabled={isBusy}
                        fullWidth
                        variant="primary"
                        icon={PlusIcon as any}
                        onClick={() => {
                          if (window?.analytics) {
                            window.analytics.track("Flow Builder - New Page", {
                              type: FlowNodeType.InputMultiLineText,
                            });
                          }
                          createNewQuestion(FlowNodeType.InputMultiLineText);
                        }}
                      >
                        Add Page
                      </Button>
                    </BlockStack>
                  </Card>
                </Grid.Cell>
              </>
            )}
            {selected.includes("email") && (
              <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                <Card>
                  <BlockStack gap="100" align="center" inlineAlign="center">
                    <Text as="p" variant="bodyMd" fontWeight="bold">
                      Optin Page
                    </Text>
                    <div style={{ height: "100px" }}>
                      <Text as="p" variant="bodyMd" tone="subdued">
                        Collect your customers email address or phone number to
                        send them a copy of their results.
                      </Text>
                    </div>
                    <Button
                      loading={isBusy}
                      disabled={isBusy}
                      fullWidth
                      variant="primary"
                      icon={PlusIcon as any}
                      onClick={() => {
                        if (window?.analytics) {
                          window.analytics.track("Flow Builder - New Page", {
                            type: FlowNodeType.Email,
                          });
                        }
                        createNewQuestion(FlowNodeType.Email);
                      }}
                    >
                      Add Page
                    </Button>
                  </BlockStack>
                </Card>
              </Grid.Cell>
            )}
            {selected.includes("transition") && (
              <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                <Card>
                  <BlockStack gap="100" align="center" inlineAlign="center">
                    <Text as="p" variant="bodyMd" fontWeight="bold">
                      Transition Screen
                    </Text>
                    <div style={{ height: "100px" }}>
                      <Text as="p" variant="bodySm" tone="subdued">
                        Add a transition screen between questions or before
                        revealing the results.
                      </Text>
                    </div>
                    <Button
                      loading={isBusy}
                      disabled={isBusy}
                      fullWidth
                      variant="primary"
                      icon={PlusIcon as any}
                      onClick={() => {
                        if (window?.analytics) {
                          window.analytics.track("Flow Builder - New Page", {
                            type: FlowNodeType.Transition,
                          });
                        }
                        createNewQuestion(FlowNodeType.Transition);
                      }}
                    >
                      Add Page
                    </Button>
                  </BlockStack>
                </Card>
              </Grid.Cell>
            )}
          </Grid>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default NewPagePanel;
