import {
  AutoSelection,
  Combobox,
  InlineStack,
  Listbox,
  Tag,
} from "@shopify/polaris";
import { useCallback, useState, useMemo } from "react";

export const MultiSelect = ({ label, options, selected, setSelected }) => {
  const [value, setValue] = useState("");
  const [suggestion, setSuggestion] = useState("");

  const formatOptionText = (text) => {
    if (!text) {
      return "-";
    }
    if (text.length > 50) {
      return `${text.slice(0, 50)}...`;
    }
    return text;
  };

  const handleActiveOptionChange = useCallback(
    (activeOption) => {
      if (!selected.includes(activeOption)) {
        setSuggestion(activeOption);
      } else {
        setSuggestion("");
      }
    },
    [value, selected]
  );

  const updateSelection = useCallback(
    (selection) => {
      const nextSelected = new Set([...selected]);
      if (nextSelected.has(selection)) {
        nextSelected.delete(selection);
      } else {
        nextSelected.add(selection);
      }
      setSelected(Array.from(nextSelected));
      setValue("");
      setSuggestion("");
    },
    [selected]
  );

  const removeSelection = (selection) => {
    updateSelection(selection);
  };

  const verticalContentMarkup =
    selected?.length > 0 ? (
      <InlineStack gap="050" align="start">
        {selected.map((selection) => {
          return (
            <Tag key={selection} onRemove={() => removeSelection(selection)}>
              {options.find((option) => option.value === selection)?.label ??
                "-"}
            </Tag>
          );
        })}
      </InlineStack>
    ) : null;

  const escapeSpecialRegExCharacters = useCallback(
    (value: string) => value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
    []
  );

  const availableOptions = useMemo(() => {
    const filterRegex = new RegExp(escapeSpecialRegExCharacters(value), "i");

    if (value) {
      return options.filter((option) => option?.label?.match(filterRegex));
    } else {
      return options;
    }
  }, [options, value]);

  const optionMarkup =
    availableOptions?.length > 0
      ? availableOptions.map((option) => {
          if (!option?.value) {
            return null;
          }
          return (
            <Listbox.Option
              key={option.value}
              value={option.value}
              selected={selected.includes(option.value)}
              accessibilityLabel={option?.label ?? "-"}
            >
              <Listbox.TextOption selected={selected.includes(option.value)}>
                {formatOptionText(option?.label ?? "-")}
              </Listbox.TextOption>
            </Listbox.Option>
          );
        })
      : null;

  const listBoxMarkup = (
    <Listbox
      autoSelection={AutoSelection.None}
      onSelect={updateSelection}
      onActiveOptionChange={handleActiveOptionChange}
    >
      {optionMarkup}
    </Listbox>
  );

  return (
    <Combobox
      allowMultiple
      activator={
        <Combobox.TextField
          placeholder={label}
          autoComplete="off"
          label={label}
          labelHidden={true}
          value={value}
          verticalContent={verticalContentMarkup}
          onChange={setValue}
          suggestion={suggestion}
        />
      }
    >
      {listBoxMarkup}
    </Combobox>
  );
};
