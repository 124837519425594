import { useEffect, useMemo, useRef, useState } from "react";
import { Modal, Tabs } from "@shopify/polaris";
import { Box } from "@storyofams/react-ui";

import { GifPaneProps } from "./GifPane";
import { GifPane } from "./GifPane";
import { ImagePane, ImagePaneProps } from "./ImagePane";
import { VideoPane, VideoPaneProps } from "./VideoPane";

export interface MediaModalPaneProps {
  isBusy?: boolean;
}

type MediaType = "gif" | "image" | "video";

interface MediaModalProps extends MediaModalPaneProps {
  isOpen: boolean;
  setOpen(isOpen: boolean): void;
  types?: MediaType[];
}

export const MediaModal = ({
  isOpen,
  setOpen,
  types = ["gif", "image", "video"],
  ...props
}: MediaModalProps & (GifPaneProps | ImagePaneProps | VideoPaneProps)) => {
  const [selected, setSelected] = useState(0);
  const previousOpen = useRef(isOpen);

  const tabs = useMemo(() => {
    const result: any[] = [];

    if (types.includes("image")) {
      result.push({
        id: "image",
        content: "Image",
        panelID: "file",
      });
    }

    if (types.includes("video")) {
      result.push({
        id: "video",
        content: "Video",
        panelID: "video",
      });
    }

    if (types.includes("gif")) {
      // @todo: reenable
      result.push({
        id: "gif",
        content: "GIF",
        panelID: "gif",
      });
    }

    return result;
  }, [types]);

  useEffect(() => {
    if (previousOpen.current && !isOpen && selected !== 0) {
      setSelected(0);
    }

    previousOpen.current = isOpen;
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setOpen(false);
      }}
      title={
        <Box
          position="relative"
          css={{
            ".Polaris-Tabs__Wrapper": {
              borderBottom: "none",
              margin: "-0.75rem -0.75rem",
            },
          }}
        >
          <Tabs tabs={tabs} selected={selected} onSelect={setSelected}></Tabs>
        </Box>
      }
      limitHeight
    >
      <Modal.Section>
        {tabs?.[selected]?.id === "image" && <ImagePane {...(props as any)} />}
        {tabs?.[selected]?.id === "video" && <VideoPane {...(props as any)} />}
        {tabs?.[selected]?.id === "gif" && <GifPane {...(props as any)} />}
      </Modal.Section>
    </Modal>
  );
};
