import { useState, useMemo, useEffect, FC } from "react";
import {
  Page,
  Layout,
  Card,
  Button,
  BlockStack,
  Banner,
  TextField,
  Spinner,
  Link,
  Text,
  InlineStack,
  Box,
} from "@shopify/polaris";
import copy from "copy-to-clipboard";
import { Flex } from "@storyofams/react-ui";
import { FlowContainerStatus, FlowNodeType } from "~/graphql/sdk";
import { IPublishErrors, useFlow, useStore } from "~/hooks";
import { useShopOrigin } from "~/hooks";
import { useBillingWrapper, hasPaidFeatureAccess } from "~/lib";
import useCopied from "~/hooks/useCopied";
import CopiedBadge from "~/components/CopiedBadge";
import PublishErrorBanner from "~/components/ErrorBanner/PublishErrorBanner";
import styled from "styled-components";

interface IPublishPageProps {
  publishErrorInfo: IPublishErrors;
}

export const PublishPage: FC<IPublishPageProps> = ({ publishErrorInfo }) => {
  const { data, publishFlow } = useFlow();
  const shopOrigin = useShopOrigin();
  const store = useStore();
  const billingWrapper = useBillingWrapper();
  const billingWrapperPublish = useBillingWrapper({
    message:
      "Quizzes of the free plan are limited to 3 single-choice questions. Please upgrade to publish this quiz or remove the extra questions.",
  });
  const paidFeatureAccess = hasPaidFeatureAccess();
  const isV2 = store?.tier?.billingVersion === 2;

  const {
    publishError,
    setPublishError,
    errorsLength,
    isLoading,
    setIsLoading,
  } = publishErrorInfo;
  const [isPublished, setIsPublished] = useState(false);

  const flowLink = useMemo(
    () =>
      `https://${shopOrigin}/tools/${process.env.REACT_APP_SHOPIFY_APP_NAME}/${data?.slug}`,
    [shopOrigin, data]
  );
  const quizSubUrl = useMemo(
    () => `tools/${process.env.REACT_APP_SHOPIFY_APP_NAME}/${data?.slug}`,
    [data]
  );
  const quizPopupUrl = useMemo(
    () =>
      `#open-quiz-tools/${process.env.REACT_APP_SHOPIFY_APP_NAME}/${data?.slug}`,
    [data]
  );
  const openThemeEditorUrl = useMemo(
    () => `https://${shopOrigin}/admin/themes/current/editor`,
    [shopOrigin, data]
  );

  const { active: isCopiedUrl, activate: activateCopiedUrl } = useCopied();
  const { active: isCopiedKey, activate: activateCopiedKey } = useCopied();
  const { active: isCopiedPopup, activate: activateCopiedPopup } = useCopied();
  const { active: isCopiedInstore, activate: activateCopiedInstore } =
    useCopied();

  const articleStandalone =
    "https://help.trylantern.com/en/articles/7145127-link-your-quiz-to-a-dedicated-page";
  const articleEmbed =
    "https://help.trylantern.com/en/articles/8121418-embed-the-quiz-in-any-store-page";
  const articlePopup =
    "https://help.trylantern.com/en/articles/8119539-how-to-open-the-quiz-in-a-popup";
  const instoreArticle =
    "https://help.trylantern.com/en/articles/10502421-display-your-quiz-with-the-in-store-page";

  const videoPopup = "https://www.youtube.com/watch?v=bKNznf_X5XI";
  const videoEmbed = "https://www.youtube.com/watch?v=cXXbu4lzxhI";
  const videoStandalone = "https://www.youtube.com/watch?v=2OksrcbTCQQ";
  const videoInstore = "https://www.youtube.com/watch?v=oblExhdGkHE";

  const republishing = data?.status === FlowContainerStatus.Published;

  useEffect(() => {
    const draftFlow = data?.flows?.[0];

    if (!draftFlow) {
      setIsPublished(true);
      return;
    }

    if (draftFlow.createdAt !== draftFlow.updatedAt) {
      setIsPublished(false);
      return;
    }

    for (let i = 0; i < draftFlow.nodes.length; i += 1) {
      const { createdAt, updatedAt } = draftFlow.nodes[i];

      if (createdAt !== updatedAt) {
        setIsPublished(false);
        return;
      }
    }

    setIsPublished(true);
    return;
  }, [data?.flows?.[0]]);

  useEffect(() => {
    //Checks to see if there are any errors that need to be addressed
    publish({ validateOnly: true });
  }, [data]);

  const publish = async ({ validateOnly }) => {
    setIsLoading(true);
    try {
      const success = await publishFlow({ validateOnly: validateOnly });
      if (success && !validateOnly) {
        setIsPublished(true);
        window?.top?.frames?.["app-iframe"]?.postMessage(
          {
            action: "toast",
            content: "Quiz published successfully",
          },
          "*"
        );
      }
      setPublishError(null);
    } catch (e: any) {
      setPublishError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ marginBottom: "32px" }}>
      <Page title="Publish">
        <Layout>
          <Layout.Section>
            {!!isLoading && !isPublished && (
              <Flex justifyContent="center" alignItems="center">
                <Spinner />
              </Flex>
            )}
            {!isLoading && !!errorsLength && (
              <PublishErrorBanner
                publishError={publishError}
                errorsLength={errorsLength}
                hasWelcomePage={
                  data?.flows[0].nodes?.[0]?.type === FlowNodeType.Welcome
                }
              />
            )}
            {!isLoading && !errorsLength && isPublished && (
              <>
                <p style={{ color: "#6D7175" }}>
                  Your quiz is ready. Please use one of the methods below to
                  publish it to your storefront.
                </p>
                <br></br>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    alignItems: "stretch",
                    gap: "8px",
                  }}
                >
                  <CardContainer>
                    <Card>
                      <BlockStack gap="200">
                        <Text as="h2" variant="headingMd">
                          Option 1. IN-STORE page (includes theme settings,
                          header and footer)
                        </Text>
                        <InlineStack
                          gap="400"
                          align="start"
                          blockAlign="center"
                          wrap={false}
                        >
                          <div
                            style={{
                              minWidth: "275px",
                              flex: "0 1 auto",
                              maxWidth: "100%",
                            }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/illustrations/publish-store.png`}
                              style={{ width: "275px" }}
                            />
                          </div>
                          <BlockStack
                            gap="100"
                            align="start"
                            inlineAlign="stretch"
                          >
                            <BlockStack
                              align="start"
                              inlineAlign="stretch"
                              gap="200"
                            >
                              <TextField
                                value={flowLink + "?instore=true"}
                                label=""
                                disabled
                                autoComplete="off"
                              />

                              <InlineStack
                                gap="200"
                                align="start"
                                blockAlign="start"
                              >
                                <CopiedBadge show={isCopiedInstore}>
                                  <Button
                                    onClick={() => {
                                      copy(flowLink + "?instore=true");
                                      activateCopiedInstore();
                                    }}
                                  >
                                    Copy Quiz URL
                                  </Button>
                                </CopiedBadge>
                                <Button
                                  variant="primary"
                                  onClick={() =>
                                    window.open(flowLink + "?instore=true")
                                  }
                                  external
                                >
                                  Open the quiz in a new tab
                                </Button>
                              </InlineStack>
                            </BlockStack>
                            <BlockStack gap="100">
                              <Text as="p" variant="bodyMd">
                                1. Copy the quiz link above.
                              </Text>
                              <Text as="p" variant="bodyMd">
                                2. Go to the Theme Editor, and link to the quiz
                                from any link, button, or menu item of your
                                choice.
                              </Text>
                              <Text as="p" variant="bodyMd">
                                3. Clicking the button, menu item or link will
                                open the quiz in a dedicated page your store.
                              </Text>
                              <p>
                                4. Need more help? Read this{" "}
                                <Link url={instoreArticle} external>
                                  step-by-step
                                </Link>{" "}
                                guide or watch this{" "}
                                <Link url={videoInstore} external>
                                  video tutorial
                                </Link>
                                .
                              </p>
                            </BlockStack>
                          </BlockStack>
                        </InlineStack>
                      </BlockStack>
                    </Card>
                  </CardContainer>
                  <CardContainer>
                    <Card>
                      <BlockStack gap="200">
                        <Text as="h2" variant="headingMd">
                          Option 2. STANDALONE link (outside your store)
                        </Text>

                        <InlineStack
                          gap="400"
                          align="start"
                          blockAlign="center"
                          wrap={false}
                        >
                          <div
                            style={{
                              minWidth: "275px",
                              flex: "0 1 auto",
                              maxWidth: "100%",
                            }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/illustrations/publish-standalone.png`}
                              style={{ width: "275px" }}
                            />
                          </div>
                          <BlockStack gap="100">
                            <BlockStack
                              align="space-between"
                              inlineAlign="stretch"
                              gap="200"
                            >
                              <TextField
                                value={flowLink}
                                label=""
                                disabled
                                autoComplete="off"
                              />

                              <InlineStack
                                gap="200"
                                align="start"
                                blockAlign="start"
                              >
                                <CopiedBadge show={isCopiedUrl}>
                                  <Button
                                    fullWidth
                                    onClick={() => {
                                      copy(flowLink);
                                      activateCopiedUrl();
                                    }}
                                  >
                                    Copy Quiz URL
                                  </Button>
                                </CopiedBadge>
                                <Button
                                  variant="primary"
                                  onClick={() => window.open(flowLink)}
                                  external
                                >
                                  Open the quiz in a new tab
                                </Button>
                              </InlineStack>
                            </BlockStack>
                            <BlockStack gap="100">
                              <Text as="p" variant="bodyMd">
                                1. Copy the quiz link above.
                              </Text>
                              <Text as="p" variant="bodyMd">
                                2. Go to the Theme Editor, and link to the quiz
                                from any link, button, or menu item of your
                                choice.
                              </Text>
                              <Text as="p" variant="bodyMd">
                                3. Clicking the button, menu item or link will
                                open the quiz in a standalone page.
                              </Text>
                              <Text as="p" variant="bodyMd">
                                4. Need more help? Read this{" "}
                                <Link url={articleStandalone} external>
                                  step-by-step
                                </Link>{" "}
                                guide or watch this{" "}
                                <Link url={videoStandalone} external>
                                  video tutorial
                                </Link>
                                .
                              </Text>
                            </BlockStack>
                          </BlockStack>
                        </InlineStack>
                      </BlockStack>
                    </Card>
                  </CardContainer>
                  <CardContainer>
                    <Card>
                      <BlockStack gap="200">
                        <Text as="h2" variant="headingMd">
                          Option 3. Open in a POPUP
                        </Text>
                        <InlineStack
                          gap="400"
                          wrap={false}
                          align="start"
                          blockAlign="center"
                        >
                          <div
                            style={{
                              minWidth: "275px",
                              flex: "0 1 auto",
                              maxWidth: "100%",
                            }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/illustrations/publish-popup.png`}
                              style={{ width: "275px" }}
                            />
                          </div>
                          <BlockStack gap="100">
                            <BlockStack
                              align="space-between"
                              inlineAlign="stretch"
                              gap="200"
                            >
                              <TextField
                                value={
                                  paidFeatureAccess
                                    ? quizPopupUrl
                                    : "Upgrade to access this feature"
                                }
                                label=""
                                disabled
                                autoComplete="off"
                              />

                              <InlineStack
                                gap="200"
                                align="start"
                                blockAlign="start"
                              >
                                <CopiedBadge show={isCopiedPopup}>
                                  <Button
                                    fullWidth
                                    onClick={() => {
                                      billingWrapper(() => {
                                        copy(quizPopupUrl);
                                        activateCopiedPopup();
                                      })();
                                    }}
                                  >
                                    {paidFeatureAccess
                                      ? "Copy Popup URL"
                                      : "Upgrade to unlock"}
                                  </Button>
                                </CopiedBadge>
                                <Button
                                  variant="primary"
                                  onClick={billingWrapper(() =>
                                    window.open(openThemeEditorUrl, "_blank")
                                  )}
                                >
                                  {paidFeatureAccess
                                    ? "Go to the Theme Editor"
                                    : "Upgrade to unlock"}
                                </Button>
                              </InlineStack>
                            </BlockStack>
                            <BlockStack gap="100">
                              <Text as="p" variant="bodyMd">
                                1. Copy the popup URL above.
                              </Text>
                              <Text as="p" variant="bodyMd">
                                2. Go to the Theme Editor, and activate{" "}
                                <b>Lantern Quiz Popup</b> under App Embeds.
                              </Text>
                              <Text as="p" variant="bodyMd">
                                3. Add the popup URL to the link, menu item or
                                button of your choice to trigger the popup.
                              </Text>
                              <Text as="p" variant="bodyMd">
                                4. Need more help? Read this{" "}
                                <Link url={articlePopup} external>
                                  step-by-step
                                </Link>{" "}
                                guide or watch this{" "}
                                <Link url={videoPopup} external>
                                  video tutorial
                                </Link>
                                .
                              </Text>
                            </BlockStack>
                          </BlockStack>
                        </InlineStack>
                      </BlockStack>
                    </Card>
                  </CardContainer>
                  <CardContainer>
                    <Card>
                      <BlockStack gap="200">
                        <Text as="h2" variant="headingMd">
                          Option 4. EMBED anywhere in your store
                        </Text>
                        <InlineStack
                          gap="400"
                          align="start"
                          blockAlign="center"
                          wrap={false}
                        >
                          <div
                            style={{
                              minWidth: "275px",
                              flex: "0 1 auto",
                              maxWidth: "100%",
                            }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/illustrations/publish-embed.png`}
                              style={{ width: "275px" }}
                            />
                          </div>
                          <BlockStack gap="100">
                            <BlockStack
                              align="space-between"
                              inlineAlign="stretch"
                              gap="200"
                            >
                              <TextField
                                value={
                                  paidFeatureAccess
                                    ? quizSubUrl
                                    : "Upgrade to access this feature"
                                }
                                label=""
                                disabled
                                autoComplete="off"
                              />

                              <InlineStack
                                gap="200"
                                align="start"
                                blockAlign="start"
                              >
                                <CopiedBadge show={isCopiedKey}>
                                  <Button
                                    fullWidth
                                    onClick={() => {
                                      billingWrapper(() => {
                                        copy(quizSubUrl);
                                        activateCopiedKey();
                                      })();
                                    }}
                                  >
                                    {paidFeatureAccess
                                      ? "Copy Key"
                                      : "Upgrade to unlock"}
                                  </Button>
                                </CopiedBadge>
                                <Button
                                  variant="primary"
                                  onClick={billingWrapper(() =>
                                    window.open(openThemeEditorUrl, "_blank")
                                  )}
                                >
                                  {paidFeatureAccess
                                    ? "Go to the Theme Editor"
                                    : "Upgrade to unlock"}
                                </Button>
                              </InlineStack>
                            </BlockStack>
                            <BlockStack gap="100">
                              <Text as="p" variant="bodyMd">
                                1. Copy the quiz key above.
                              </Text>
                              <Text as="p" variant="bodyMd">
                                2. Go to the Theme Editor, and add the{" "}
                                <b>Lantern Quiz</b> block to the page section
                                where you want to display the quiz.
                              </Text>
                              <Text as="p" variant="bodyMd">
                                3. Paste the quiz key in the <b>Quiz Sub-URL</b>{" "}
                                setting.
                              </Text>
                              <Text as="p" variant="bodyMd">
                                4. Need more help? Read this{" "}
                                <Link url={articleEmbed} external>
                                  step-by-step
                                </Link>{" "}
                                guide or watch this{" "}
                                <Link url={videoEmbed} external>
                                  video tutorial
                                </Link>
                                .
                              </Text>
                            </BlockStack>
                          </BlockStack>
                        </InlineStack>
                      </BlockStack>
                    </Card>
                  </CardContainer>
                </div>
              </>
            )}
            {!isLoading && !errorsLength && !isPublished && (
              <Banner
                title={`Your quiz is ready to ${
                  republishing ? "republish" : "publish"
                }!`}
                tone="success"
                action={{
                  content: republishing ? "Republish" : "Publish",
                  onAction: () => {
                    if (
                      data &&
                      data?.flows?.[0]?.nodes?.filter(
                        (node) =>
                          node.type !== "EMAIL" && node.type !== "WELCOME"
                      )?.length > 3 &&
                      isV2
                    ) {
                      billingWrapperPublish(() =>
                        publish({ validateOnly: false })
                      )();
                      return;
                    } else {
                      publish({ validateOnly: false });
                    }
                  },
                }}
              >
                <p>
                  Click on the Publish button below to get more options on where
                  to publish your quiz.
                </p>
              </Banner>
            )}
            <Box paddingBlockEnd="1200"></Box>
          </Layout.Section>
        </Layout>
      </Page>
    </div>
  );
};

const CardContainer = styled.div`
  flex: 1 1 0;
  min-width: 240px;

  & > .Polaris-Card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }
`;
