import { ReactNode } from "react";
import { Text, BlockStack } from "@shopify/polaris";
import { Box, SystemProps } from "@storyofams/react-ui";
import styled from "styled-components";

const Wrapper = styled(Box).attrs((attrs) => ({
  py: 2,
  px: 1.5,
  ...attrs,
}))`
  &:not(:last-of-type) {
    border-bottom: 1px solid #ebeaea;
  }

  > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

interface SectionProps extends SystemProps {
  children?: ReactNode;
  title?: string;
}

export const Section = ({ children, title, ...props }: SectionProps) => (
  <Wrapper {...props}>
    <BlockStack gap="400">
      {!!title && (
        <Text as="h3" variant="headingMd">
          {title}
        </Text>
      )}

      {children}
    </BlockStack>
  </Wrapper>
);
