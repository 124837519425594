import { useState } from "react";
import { Modal, BlockStack } from "@shopify/polaris";
import { Box } from "@storyofams/react-ui";
import { produce } from "immer";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { FlowFragmentFragment, FlowNodeType } from "~/graphql/sdk";
import { useNavParams, useSdk } from "~/hooks";
import { ErrorBanner } from "../ErrorBanner";

interface QuestionDeleteModalProps {
  currentFlow: FlowFragmentFragment;
  isOpen: boolean | number;
  setOpen(isOpen: boolean): void;
}

export const QuestionDeleteModal = ({
  currentFlow,
  isOpen,
  setOpen,
}: QuestionDeleteModalProps) => {
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const [{ question: activeQuestion }, setNavParams] = useNavParams();
  const sdk = useSdk();

  const [error, setError] = useState<any>(null);
  const [isBusy, setBusy] = useState(false);

  const isWelcome =
    currentFlow?.nodes?.[isOpen as number]?.type === FlowNodeType.Welcome;
  const isEmail =
    currentFlow?.nodes?.[isOpen as number]?.type === FlowNodeType.Email;

  const deleteQuestion = async () => {
    if (isBusy || !currentFlow) {
      return;
    }

    setBusy(true);

    try {
      await sdk.deleteFlowNode({
        input: {
          nodeId: currentFlow?.nodes?.[isOpen as number]?.id,
        },
      });

      setError(null);
      window?.top?.frames?.["app-iframe"]?.postMessage(
        {
          action: "toast",
          content: `${
            isWelcome ? "Welcome screen" : isEmail ? "Email step" : "Question"
          } deleted`,
        },
        "*"
      );

      queryClient.setQueryData(["container", { id }], (old: any) =>
        produce(old, (draft) => {
          draft.flows?.[0]?.nodes?.splice(isOpen as number, 1);
        })
      );
      setNavParams({
        question:
          activeQuestion < currentFlow?.nodes?.length - 1
            ? `${activeQuestion}`
            : `${activeQuestion - 1}`,
      });
      setOpen(false);
    } catch (e: any) {
      setError({
        message: e?.message,
        messages: e?.response?.errors?.map((err) => err?.message),
      });
    }

    setBusy(false);
  };

  return (
    <Modal
      open={isOpen !== false}
      onClose={() => {
        setOpen(false);
      }}
      title={`Delete ${
        isWelcome ? "welcome screen" : isEmail ? "email step" : `question`
      }`}
      primaryAction={{
        content: "Delete",
        onAction: deleteQuestion,
        loading: isBusy,
        destructive: true,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            setOpen(false);
          },
        },
      ]}
    >
      <Box p={2}>
        <ErrorBanner
          title={`Error deleting ${
            isWelcome ? "welcome screen" : isEmail ? "email step" : "question"
          }:`}
          error={error}
        />

        <BlockStack gap="100">
          <p>
            Are you sure you want to delete{" "}
            {isWelcome
              ? "the welcome screen"
              : isEmail
              ? "the email step"
              : `the question`}
            ?
          </p>
        </BlockStack>
      </Box>
    </Modal>
  );
};
