import { useEffect, useState } from "react";
import { Box, Flex, Icon } from "@storyofams/react-ui";
import { motion } from "framer-motion";
import { range } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import styled, { css } from "styled-components";
import { Appear } from "~/components";
import {
  Button,
  Subtext,
  Link as LinkComponent,
  Title,
  EditorController,
  Result,
} from "~/components/preview";
import { ReactComponent as refresh } from "~/components/preview/Icon/library/refresh.svg";
import config from "~/config";
import { useFlow, useOptionsForm, useSdk } from "~/hooks";
import { messages } from "./messages";
import { useBillingWrapper } from "~/lib";
import ContentBlockModal from "~/components/preview/ContentBlockModal";
import ContentBlocksList from "~/components/preview/ContentBlocksList";
import { useQueryClient } from "react-query";

const MotionBox = motion(Box) as any;

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.25,
      ...config.transition,
    },
  },
};

const getDefaultValues = (values) => ({
  resultsTitle: values?.resultsTitle || "",
  resultsDescription: values?.resultsDescription || "",
});

const ResetLink = styled.div<{ disabled?: boolean }>`
  svg {
    transition: transform 0.3s ease-in-out;
    transform: rotate(0deg);
  }

  &:hover svg {
    transform: rotate(30deg);
  }

  &:active svg,
  &:focus svg {
    transform: rotate(360deg);
  }

  ${(p) =>
    !!p.disabled &&
    css`
      svg {
        transform: rotate(360deg) !important;
      }
    `}
`;

export const ResultsForm = () => {
  const intl = useIntl();
  const { data } = useFlow();

  const { control } = useOptionsForm({
    getDefaultValues,
    type: "flow",
  });

  const billingWrapper = useBillingWrapper({});

  const [showContentBlockModal, setShowContentBlockModal] = useState(false);
  const [contentBlockLocation, setContentBlockLocation] = useState("top");
  const [contentBlockId, setContentBlockId] = useState(null);
  const [resultsContentBlocks, setResultsContentBlocks] = useState(null as any);

  const queryClient = useQueryClient();

  const sdk = useSdk();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (data?.flows?.[0]?.resultsContentBlocks && !resultsContentBlocks) {
      setResultsContentBlocks(
        JSON.parse(data?.flows?.[0]?.resultsContentBlocks)
      );
    }
  }, [data?.flows?.[0]?.resultsContentBlocks]);

  const pageSettings = JSON.parse(data?.flows?.[0]?.pageSettings ?? "{}");
  /* general design settings */
  const results_topWidthDesktopType =
    pageSettings?.results_topWidthDesktopType ?? "100%";
  const results_topWidth = pageSettings?.results_topWidth ?? "1200";
  const results_bottomWidthDesktopType =
    pageSettings?.results_bottomWidthDesktopType ?? "100%";
  const results_bottomWidth = pageSettings?.results_bottomWidth ?? "1200";
  const results_topWidthMobileType =
    pageSettings?.results_topWidthMobileType ?? "100%";
  const results_topWidthMobile = pageSettings?.results_topWidthMobile ?? "1200";
  const results_bottomWidthMobileType =
    pageSettings?.results_bottomWidthMobileType ?? "100%";
  const results_bottomWidthMobile =
    pageSettings?.results_bottomWidthMobile ?? "1200";
  /* question settings */
  const results_questionAlignment =
    pageSettings?.results_questionAlignment ?? "center";
  const results_questionFontSize =
    pageSettings?.results_questionFontSize ?? "32";
  /* description settings */
  const results_descriptionAlignment =
    pageSettings?.results_descriptionAlignment ?? "center";
  const results_descriptionFontSize =
    pageSettings?.results_descriptionFontSize ?? "16";
  /* add all to cart button settings */
  const addAll_useDefaultColorForButton =
    pageSettings?.addAll_useDefaultColorForButton ?? "yes";
  const addAll_buttonColor = pageSettings?.addAll_buttonColor ?? "#000";
  const addAll_useDefaultTextColorForButton =
    pageSettings?.addAll_useDefaultTextColorForButton ?? "yes";
  const addAll_buttonTextColor = pageSettings?.addAll_buttonTextColor ?? "#fff";
  const addAll_buttonTextOverride =
    pageSettings?.addAll_buttonTextOverride ?? "";
  const addAll_buttonFontSize = pageSettings?.addAll_buttonFontSize ?? "18";
  const addAll_buttonBorderRadius =
    pageSettings?.addAll_buttonBorderRadius ?? "8";
  const addAll_buttonHorizontalPadding =
    pageSettings?.addAll_buttonHorizontalPadding ?? "56";
  const addAll_buttonVerticalPadding =
    pageSettings?.addAll_buttonVerticalPadding ?? "20";
  const addAll_buttonFontSizeMobile =
    pageSettings?.addAll_buttonFontSizeMobile ?? "18";
  const addAll_buttonBorderRadiusMobile =
    pageSettings?.addAll_buttonBorderRadiusMobile ?? "8";
  const addAll_buttonHorizontalPaddingMobile =
    pageSettings?.addAll_buttonHorizontalPaddingMobile ?? "56";
  const addAll_buttonVerticalPaddingMobile =
    pageSettings?.addAll_buttonVerticalPaddingMobile ?? "20";
  const addAll_buttonWidthMobileType =
    pageSettings?.addAll_buttonWidthMobileType ?? "100%";
  const addAll_buttonWidthMobile =
    pageSettings?.addAll_buttonWidthMobile ?? "345";
  const addAll_buttonWidthType =
    pageSettings?.addAll_buttonWidthType ?? "custom";
  const addAll_buttonWidth = pageSettings?.addAll_buttonWidth ?? "370";
  const addAll_buttonAlignment =
    pageSettings?.addAll_buttonAlignment ?? "center";
  /* view more button settings */
  const viewMore_useDefaultColorForButton =
    pageSettings?.viewMore_useDefaultColorForButton ?? "yes";
  const viewMore_buttonColor = pageSettings?.viewMore_buttonColor ?? "#000";
  const viewMore_useDefaultTextColorForButton =
    pageSettings?.viewMore_useDefaultTextColorForButton ?? "yes";
  const viewMore_buttonTextColor =
    pageSettings?.viewMore_buttonTextColor ?? "#fff";
  const viewMore_buttonTextOverride =
    pageSettings?.viewMore_buttonTextOverride ?? "";
  const viewMore_buttonFontSize = pageSettings?.viewMore_buttonFontSize ?? "18";
  const viewMore_buttonBorderRadius =
    pageSettings?.viewMore_buttonBorderRadius ?? "8";
  const viewMore_buttonHorizontalPadding =
    pageSettings?.viewMore_buttonHorizontalPadding ?? "56";
  const viewMore_buttonVerticalPadding =
    pageSettings?.viewMore_buttonVerticalPadding ?? "20";
  const viewMore_buttonFontSizeMobile =
    pageSettings?.viewMore_buttonFontSizeMobile ?? "18";
  const viewMore_buttonBorderRadiusMobile =
    pageSettings?.viewMore_buttonBorderRadiusMobile ?? "8";
  const viewMore_buttonHorizontalPaddingMobile =
    pageSettings?.viewMore_buttonHorizontalPaddingMobile ?? "56";
  const viewMore_buttonVerticalPaddingMobile =
    pageSettings?.viewMore_buttonVerticalPaddingMobile ?? "20";
  const viewMore_buttonWidthMobileType =
    pageSettings?.viewMore_buttonWidthMobileType ?? "100%";
  const viewMore_buttonWidthMobile =
    pageSettings?.viewMore_buttonWidthMobile ?? "345";
  const viewMore_buttonWidthType =
    pageSettings?.viewMore_buttonWidthType ?? "custom";
  const viewMore_buttonWidth = pageSettings?.viewMore_buttonWidth ?? "370";
  const viewMore_buttonAlignment =
    pageSettings?.viewMore_buttonAlignment ?? "center";

  const openContentBlockModal = (location) => {
    setContentBlockLocation(location);
    billingWrapper(setShowContentBlockModal)(true);
  };

  const saveContentBlock = async (location, id, contentBlock) => {
    if (id && data?.flows?.[0]?.id) {
      const existingContentBlocks = resultsContentBlocks?.[location] ?? [];
      const blockIndex = existingContentBlocks.findIndex(
        (cb) => cb?.id && cb.id === id
      );

      if (blockIndex >= 0) {
        existingContentBlocks[blockIndex] = contentBlock;
      } else {
        existingContentBlocks.push(contentBlock);
      }
      const result = await sdk.updateOneFlow({
        input: {
          id: data.flows[0].id,
          update: {
            resultsContentBlocks: JSON.stringify({
              ...resultsContentBlocks,
              [location]: existingContentBlocks,
            }),
          },
        },
      });

      queryClient.invalidateQueries(["container", { id: data?.id }]);

      if (result?.updateOneFlow?.resultsContentBlocks) {
        const newResultsContentBlocks = JSON.parse(
          result?.updateOneFlow?.resultsContentBlocks
        );

        setResultsContentBlocks(newResultsContentBlocks);
      }
    }
  };

  const updateContentBlocks = async (contentBlocks) => {
    if (data?.flows?.[0]?.id) {
      const result = await sdk.updateOneFlow({
        input: {
          id: data.flows[0].id,
          update: {
            resultsContentBlocks: JSON.stringify(contentBlocks),
          },
        },
      });

      queryClient.invalidateQueries(["container", { id: data?.id }]);

      if (result?.updateOneFlow?.resultsContentBlocks) {
        setResultsContentBlocks(
          JSON.parse(result?.updateOneFlow?.resultsContentBlocks)
        );
      }
    }
  };

  return (
    <>
      <MotionBox
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={config.transition}
        textAlign="center"
        className="results-section"
      >
        <span className="results-container">
          <div
            style={{
              margin: "0 auto",
              width: isMobile
                ? results_topWidthMobileType === "100%"
                  ? "100%"
                  : `${results_topWidthMobile}px`
                : results_topWidthDesktopType === "100%"
                ? "100%"
                : `${results_topWidth}px`,
            }}
            className="results-top-section"
          >
            {!!data?.flows?.[0]?.enableRestart && (
              <motion.div
                className="start-over-container"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0, ...config.transition }}
              >
                <Box className="start-over" mb={3}>
                  <ResetLink
                    className="start-over-link"
                    as={LinkComponent}
                    disabled
                  >
                    <Icon
                      className="start-over-icon"
                      icon={refresh as any}
                      css={{ marginInlineEnd: "8px" }}
                    />{" "}
                    <FormattedMessage {...messages.restart} />
                  </ResetLink>
                </Box>
              </motion.div>
            )}

            <ContentBlocksList
              contentBlocks={resultsContentBlocks}
              update={updateContentBlocks}
              setContentBlockId={setContentBlockId}
              location="top"
              openContentBlockModal={openContentBlockModal}
            />

            <Box my={3}>
              <Button
                className="button-secondary"
                size="small"
                variant="secondary"
                primaryColor="#a79efe"
                plus={true}
                onClick={() => {
                  setContentBlockId(null);
                  openContentBlockModal("top");
                }}
                style={{
                  border: "2px dashed #a79efe",
                  backgroundColor: "transparent",
                  borderRadius: "15px",
                }}
              >
                Add a Dynamic Content Block
              </Button>
            </Box>
          </div>
          <div
            className="results-bottom-section"
            style={{
              margin: "0 auto",
              width: isMobile
                ? results_bottomWidthMobileType === "100%"
                  ? "100%"
                  : `${results_bottomWidthMobile}px`
                : results_bottomWidthDesktopType === "100%"
                ? "100%"
                : `${results_bottomWidth}px`,
            }}
          >
            {!data?.flows?.[0]?.hideDefaultRecommendations && (
              <>
                <Appear className="results-heading-section">
                  <Box
                    className="results-heading-container"
                    maxWidth="790px"
                    mx="auto"
                  >
                    <EditorController
                      name="resultsTitle"
                      className="results-heading"
                      control={control}
                      element={Title}
                      dir="auto"
                      placeholder={intl.formatMessage(messages.resultsTitle)}
                      style={{
                        fontSize: `${results_questionFontSize}px`,
                        lineHeight: 1.2,
                        textAlign: results_questionAlignment,
                      }}
                    />

                    <Box mt={2} mb={5}>
                      <EditorController
                        name="resultsDescription"
                        className="results-subheading"
                        control={control}
                        element={Subtext}
                        dir="auto"
                        placeholder={intl.formatMessage(
                          messages.resultsSubtext
                        )}
                        style={{
                          fontSize: `${results_descriptionFontSize}px`,
                          lineHeight: 1.2,
                          textAlign: results_descriptionAlignment,
                        }}
                      />
                    </Box>
                  </Box>
                </Appear>

                <motion.div
                  className="results-top-3-products-container"
                  variants={container}
                  initial="hidden"
                  animate="show"
                >
                  <Flex
                    className="results-top-3-products"
                    flexWrap="wrap"
                    mx={-1}
                    justifyContent="center"
                  >
                    {range(data?.flows?.[0]?.resultsAmount || 3).map((idx) => (
                      <Result
                        resultsPerRowMobile={
                          data?.flows?.[0]?.resultsPerRowMobile ?? 1
                        }
                        resultsPerRow={data?.flows?.[0]?.resultsPerRow ?? 3}
                        key={idx}
                        idx={idx}
                      />
                    ))}
                  </Flex>

                  {!!data?.flows?.[0]?.enableAddAll && (
                    <motion.div
                      className="add-all-button-container"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.3, ...config.transition }}
                      style={{
                        display: "flex",
                        marginTop: "20px",
                        width: "100%",
                        justifyContent:
                          addAll_buttonAlignment === "center"
                            ? "center"
                            : addAll_buttonAlignment === "right"
                            ? "flex-end"
                            : "flex-start",
                      }}
                    >
                      <Button
                        className="button button-add-all-to-cart"
                        primaryColor={
                          data?.flows?.[0]?.primaryColor || undefined
                        }
                        width={
                          isMobile
                            ? addAll_buttonWidthMobileType === "100%"
                              ? "100%"
                              : addAll_buttonWidthMobileType === "auto"
                              ? "auto"
                              : `${addAll_buttonWidthMobile}px`
                            : addAll_buttonWidthType === "100%"
                            ? "100%"
                            : addAll_buttonWidthType === "auto"
                            ? "auto"
                            : `${addAll_buttonWidth}px`
                        }
                        borderRadius="xs"
                        mt={5}
                        disabled
                        style={
                          isMobile
                            ? {
                                ...(addAll_useDefaultColorForButton === "no"
                                  ? { backgroundColor: addAll_buttonColor }
                                  : {}),
                                ...(addAll_useDefaultTextColorForButton === "no"
                                  ? { color: addAll_buttonTextColor }
                                  : {}),
                                ...(addAll_buttonFontSizeMobile >= 0
                                  ? {
                                      fontSize: `${addAll_buttonFontSizeMobile}px`,
                                    }
                                  : {}),
                                ...(addAll_buttonBorderRadiusMobile >= 0
                                  ? {
                                      borderRadius: `${addAll_buttonBorderRadiusMobile}px`,
                                    }
                                  : {}),
                                ...(addAll_buttonHorizontalPaddingMobile >= 0
                                  ? {
                                      paddingLeft: `${addAll_buttonHorizontalPaddingMobile}px`,
                                      paddingRight: `${addAll_buttonHorizontalPaddingMobile}px`,
                                    }
                                  : {}),
                                ...(addAll_buttonVerticalPaddingMobile >= 0
                                  ? {
                                      paddingTop: `${addAll_buttonVerticalPaddingMobile}px`,
                                      paddingBottom: `${addAll_buttonVerticalPaddingMobile}px`,
                                    }
                                  : {}),
                              }
                            : {
                                ...(addAll_useDefaultColorForButton === "no"
                                  ? { backgroundColor: addAll_buttonColor }
                                  : {}),
                                ...(addAll_useDefaultTextColorForButton === "no"
                                  ? { color: addAll_buttonTextColor }
                                  : {}),
                                ...(addAll_buttonFontSize >= 0
                                  ? { fontSize: `${addAll_buttonFontSize}px` }
                                  : {}),
                                ...(addAll_buttonBorderRadius >= 0
                                  ? {
                                      borderRadius: `${addAll_buttonBorderRadius}px`,
                                    }
                                  : {}),
                                ...(addAll_buttonHorizontalPadding >= 0
                                  ? {
                                      paddingLeft: `${addAll_buttonHorizontalPadding}px`,
                                      paddingRight: `${addAll_buttonHorizontalPadding}px`,
                                    }
                                  : {}),
                                ...(addAll_buttonVerticalPadding >= 0
                                  ? {
                                      paddingTop: `${addAll_buttonVerticalPadding}px`,
                                      paddingBottom: `${addAll_buttonVerticalPadding}px`,
                                    }
                                  : {}),
                              }
                        }
                      >
                        {addAll_buttonTextOverride?.length > 0 ? (
                          addAll_buttonTextOverride
                        ) : messages?.addAll ? (
                          <FormattedMessage {...messages.addAll} />
                        ) : (
                          "Add all to cart"
                        )}
                      </Button>
                    </motion.div>
                  )}
                </motion.div>

                {!!data?.flows?.[0]?.enableMoreResults && (
                  <motion.div
                    className="button-view-more-container"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0, ...config.transition }}
                    style={{
                      display: "flex",
                      marginTop: "20px",
                      width: "100%",
                      justifyContent:
                        viewMore_buttonAlignment === "center"
                          ? "center"
                          : viewMore_buttonAlignment === "right"
                          ? "flex-end"
                          : "flex-start",
                    }}
                  >
                    <Button
                      className="button-secondary button-view-more"
                      width={
                        isMobile
                          ? viewMore_buttonWidthMobileType === "100%"
                            ? "100%"
                            : viewMore_buttonWidthMobileType === "auto"
                            ? "auto"
                            : `${viewMore_buttonWidthMobile}px`
                          : viewMore_buttonWidthType === "100%"
                          ? "100%"
                          : viewMore_buttonWidthType === "auto"
                          ? "auto"
                          : `${viewMore_buttonWidth}px`
                      }
                      variant="secondary"
                      mt={5}
                      disabled
                      style={
                        isMobile
                          ? {
                              ...(viewMore_useDefaultColorForButton === "no"
                                ? { backgroundColor: viewMore_buttonColor }
                                : {}),
                              ...(viewMore_useDefaultTextColorForButton === "no"
                                ? { color: viewMore_buttonTextColor }
                                : {}),
                              ...(viewMore_buttonFontSizeMobile >= 0
                                ? {
                                    fontSize: `${viewMore_buttonFontSizeMobile}px`,
                                  }
                                : {}),
                              ...(viewMore_buttonBorderRadiusMobile >= 0
                                ? {
                                    borderRadius: `${viewMore_buttonBorderRadiusMobile}px`,
                                  }
                                : {}),
                              ...(viewMore_buttonHorizontalPaddingMobile >= 0
                                ? {
                                    paddingLeft: `${viewMore_buttonHorizontalPaddingMobile}px`,
                                    paddingRight: `${viewMore_buttonHorizontalPaddingMobile}px`,
                                  }
                                : {}),
                              ...(viewMore_buttonVerticalPaddingMobile >= 0
                                ? {
                                    paddingTop: `${viewMore_buttonVerticalPaddingMobile}px`,
                                    paddingBottom: `${viewMore_buttonVerticalPaddingMobile}px`,
                                  }
                                : {}),
                            }
                          : {
                              ...(viewMore_useDefaultColorForButton === "no"
                                ? { backgroundColor: viewMore_buttonColor }
                                : {}),
                              ...(viewMore_useDefaultTextColorForButton === "no"
                                ? { color: viewMore_buttonTextColor }
                                : {}),
                              ...(viewMore_buttonFontSize >= 0
                                ? { fontSize: `${viewMore_buttonFontSize}px` }
                                : {}),
                              ...(viewMore_buttonBorderRadius >= 0
                                ? {
                                    borderRadius: `${viewMore_buttonBorderRadius}px`,
                                  }
                                : {}),
                              ...(viewMore_buttonHorizontalPadding >= 0
                                ? {
                                    paddingLeft: `${viewMore_buttonHorizontalPadding}px`,
                                    paddingRight: `${viewMore_buttonHorizontalPadding}px`,
                                  }
                                : {}),
                              ...(viewMore_buttonVerticalPadding >= 0
                                ? {
                                    paddingTop: `${viewMore_buttonVerticalPadding}px`,
                                    paddingBottom: `${viewMore_buttonVerticalPadding}px`,
                                  }
                                : {}),
                            }
                      }
                    >
                      {viewMore_buttonTextOverride?.length > 0 ? (
                        viewMore_buttonTextOverride
                      ) : (
                        <FormattedMessage {...messages.viewMore} />
                      )}
                    </Button>
                  </motion.div>
                )}
              </>
            )}

            {!data?.flows?.[0]?.hideDefaultRecommendations && (
              <>
                <ContentBlocksList
                  contentBlocks={resultsContentBlocks}
                  update={updateContentBlocks}
                  setContentBlockId={setContentBlockId}
                  location="bottom"
                  openContentBlockModal={openContentBlockModal}
                />

                <Box my={3}>
                  <Button
                    className="button-secondary"
                    size="small"
                    variant="secondary"
                    primaryColor="#a79efe"
                    plus={true}
                    onClick={() => {
                      setContentBlockId(null);
                      openContentBlockModal("bottom");
                    }}
                    style={{
                      border: "2px dashed #a79efe",
                      backgroundColor: "transparent",
                      borderRadius: "15px",
                    }}
                  >
                    Add a Dynamic Content Block
                  </Button>
                </Box>
              </>
            )}
          </div>
        </span>
      </MotionBox>

      <ContentBlockModal
        open={showContentBlockModal}
        onClose={() => setShowContentBlockModal(false)}
        onOpen={() => setShowContentBlockModal(true)}
        nodes={data?.flows?.[0]?.nodes}
        contentBlocks={resultsContentBlocks}
        location={contentBlockLocation}
        id={contentBlockId}
        save={saveContentBlock}
      />
    </>
  );
};
