import React from "react";
import { BlockStack, Card, Icon, Text } from "@shopify/polaris";
import styled from "styled-components";

const Title = styled.div`
  display: flex;

  [class~="Polaris-Icon"] {
    display: inline-block;
    margin: 0;
    margin-right: 0.7rem;
  }
`;

interface DescriptionCardProps {
  icon: any;
  title: string;
  content?: string;
}

const DescriptionCard: React.FC<DescriptionCardProps> = ({
  icon,
  title,
  content,
}) => {
  return (
    <Card>
      <BlockStack gap="200">
        <Title>
          <Icon source={icon} />
          <Text as="span" variant="headingMd">
            {title}
          </Text>
        </Title>
        {content}
      </BlockStack>
    </Card>
  );
};

export default DescriptionCard;
