import { useEffect, useMemo, useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import Editor from "@monaco-editor/react";
import {
  Button,
  Checkbox,
  Link,
  Select,
  BlockStack,
  Modal,
  Text,
} from "@shopify/polaris";
import { Box, Text as UIText } from "@storyofams/react-ui";
import equal from "fast-deep-equal";
import produce from "immer";
import { useForm, Controller } from "react-hook-form";
import { useQueryClient, useQuery, useMutation } from "react-query";
import * as Yup from "yup";

import { ColorInput } from "~/components/ColorInput";
import { FileUpload } from "~/components/FileUpload";
import { FontInput } from "~/components/FontInput";
import { Section } from "~/components/Section";
import { File, FlowColorScheme, FlowLocale } from "~/graphql/sdk";
import { useFlow, useSdk, useShopOrigin } from "~/hooks";
import { getLocaleName, useToast } from "~/lib";
import { useBillingWrapper } from "~/lib";

const LANGUAGE_OPTIONS = [
  {
    label: "Dutch",
    value: FlowLocale.Nl,
  },
  {
    label: "English",
    value: FlowLocale.En,
  },
];

const schema = Yup.object().shape({
  colorScheme: Yup.string().oneOf(Object.values(FlowColorScheme)),
  primaryColor: Yup.string().matches(
    /^#(?:[0-9a-fA-F]{3}){1,2}$/i,
    "Must be a valid HEX color"
  ),
  bgColor: Yup.string().matches(
    /^#(?:[0-9a-fA-F]{3}){1,2}$/i,
    "Must be a valid HEX color"
  ),
  fontFamily: Yup.string(),
  language: Yup.string()
    .oneOf(LANGUAGE_OPTIONS.map(({ value }) => value))
    .required(),
  hasProgressBar: Yup.boolean(),
  hasStepIndicator: Yup.boolean(),
});

const getDefaultValues = (values) => ({
  colorScheme: values?.colorScheme || "default",
  primaryColor: values?.primaryColor || "#0D33FF",
  bgColor: values?.bgColor || "#F5F5F5",
  fontFamily: values?.fontFamily || "Inter",
  language:
    (values?.locale === FlowLocale.Custom
      ? values?.translationId
      : values?.locale) || FlowLocale.En,
  hasProgressBar:
    typeof values?.hasProgressBar !== "undefined"
      ? !!values.hasProgressBar
      : true,
  hasStepIndicator:
    typeof values?.hasStepIndicator !== "undefined"
      ? !!values.hasStepIndicator
      : true,
  cssEditor: values?.cssEditor || "",
});

export const DesignPane = () => {
  const queryClient = useQueryClient();
  const sdk = useSdk();
  const { data, setStatus } = useFlow();
  const shop = useShopOrigin();
  const toast = useToast();
  const billingWrapper = useBillingWrapper({ freeForExistingV1Users: true });

  const currentFlow = data?.flows?.[0];

  const previousValues = useRef<any>(getDefaultValues(currentFlow));

  const { control, reset, watch } = useForm({
    defaultValues: getDefaultValues(currentFlow),
    resolver: yupResolver(schema),
  });

  const values = watch();

  //CSS Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [css, setCss] = useState(values?.cssEditor || ``);
  const [cssEditor, setCssEditor] = useState(css);
  const [markers, setMarkers] = useState([] as any[]);

  const { data: translations } = useQuery(
    ["translations"],
    () => sdk.translations().then((res) => res.translations),
    {
      refetchOnWindowFocus: false,
    }
  );

  const languageOptions = useMemo(
    () => [
      {
        title: "Preset",
        options: LANGUAGE_OPTIONS,
      },
      ...(translations?.edges?.length
        ? [
            {
              title: "Custom",
              options: translations.edges
                .map(({ node: { id, locale } }) => ({
                  label: getLocaleName(locale),
                  value: id,
                }))
                .sort((a, b) =>
                  getLocaleName(a.label).localeCompare(getLocaleName(b.label))
                ),
            },
          ]
        : []),
    ],
    [translations]
  );

  const flowMutation = useMutation(
    ({ id, values }: { id: string; values: any }) =>
      sdk.updateOneFlow({
        input: {
          id,
          update: values,
        },
      }),
    {
      onMutate: async ({ values }) => {
        const key = ["container", { id: data?.id }];

        await queryClient.cancelQueries(key);

        const previous = queryClient.getQueryData(key);

        queryClient.setQueryData(key, (old: any) =>
          produce(old, (draft) => {
            draft.flows[0] = { ...draft.flows[0], ...values };
          })
        );

        return { previous };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["container", { id: data?.id }]);
        setStatus("saved");
      },
      onError: (e: any, _, context: any) => {
        reset(getDefaultValues(context?.previous?.flows?.[0]));
        queryClient.setQueryData(
          ["container", { id: data?.id }],
          context?.previous
        );
        setStatus("error");
        toast({
          error: true,
          content: e?.messages?.[0] || e?.message || "Error saving settings.",
        });
      },
    }
  );

  useEffect(() => {
    if (!previousValues?.current || !equal(values, previousValues.current)) {
      if (currentFlow?.id) {
        setStatus("saving");
        flowMutation.mutate({
          id: currentFlow.id,
          values: {
            ...values,
            cssEditor: cssEditor,
            language: undefined,
            ...(!Object.values(FlowLocale).includes(values?.language)
              ? {
                  locale: FlowLocale.Custom,
                  translationId: values?.language,
                }
              : { locale: values?.language }),
          },
        });
      }
    }

    previousValues.current = values;
  }, [values]);

  if (!currentFlow) {
    return null;
  }

  return (
    <>
      <Section title="Theme settings">
        <Controller
          control={control}
          name="primaryColor"
          render={({ field }) => (
            <ColorInput {...field} label="Primary color" />
          )}
        />

        <Controller
          control={control}
          name="bgColor"
          render={({ field }) => (
            <ColorInput {...field} label="Background color" />
          )}
        />

        <BlockStack gap="050">
          <Button
            fullWidth
            variant="primary"
            onClick={() => billingWrapper(setIsModalOpen)(true)}
          >
            {"Edit Custom CSS"}
          </Button>
          <Text as="p" variant="bodySm" tone="subdued">
            Learn more about{" "}
            <Link
              external
              url="https://help.trylantern.com/en/articles/9804975-how-to-add-custom-css-to-your-quiz"
            >
              using custom CSS
            </Link>
            .
          </Text>
        </BlockStack>

        <Modal
          open={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
          title={"Edit CSS"}
          sectioned={true}
          primaryAction={{
            content: "Save Changes",
            disabled: markers.length > 0 || css === cssEditor,
            onAction: () => {
              setIsModalOpen(false);
              setCssEditor(css);
              values.cssEditor = css;
            },
          }}
        >
          <Editor
            height="50vh"
            defaultLanguage="css"
            value={css}
            onChange={(value) => setCss(value || "")}
            onValidate={(marker) => setMarkers(marker)}
          />
          <br></br>
          <hr></hr>
          <div style={{ padding: 10 }}>
            {markers.length > 0 ? (
              <h1 style={{ color: "red" }}>There are error with your css</h1>
            ) : (
              <h1 style={{ color: "green" }}>No Errors!</h1>
            )}
            {markers.map((marker) => (
              <p style={{ color: "red" }}>
                {"Line " + marker.startLineNumber + ": " + marker.message}
              </p>
            ))}
            <br></br>
            <Text as="p" variant="bodySm" tone="subdued">
              Your Lantern quiz is highly customizable. You can see a full list
              of class names here{" "}
              <Link
                external
                url="https://help.trylantern.com/en/articles/9804975-how-to-add-custom-css-to-your-quiz"
              >
                CSS styling
              </Link>
              .
            </Text>
            <Text as="p" variant="bodySm" tone="subdued">
              If you need 1-on-1 assistance with custom CSS, please upgrade to
              the <b>Enterprise plan</b> and we'll personally help you design
              your quiz.
            </Text>
          </div>
        </Modal>
      </Section>

      <Section>
        <BlockStack gap="100">
          <Controller
            control={control}
            name="language"
            render={({ field: { ref, ...field } }) => (
              <Select
                label="Default Language"
                options={languageOptions}
                {...field}
              />
            )}
          />
          <p>
            <Link
              external
              url={`https://${shop}/admin/apps/${process.env.REACT_APP_SHOPIFY_APP_NAME}/translations`}
            >
              Add additional translations.
            </Link>
          </p>
        </BlockStack>
      </Section>

      <Section>
        <Box>
          <UIText as="label" htmlFor="font-picker" mb="0.4rem" display="block">
            Typography
          </UIText>

          <Controller
            control={control}
            name="fontFamily"
            render={({ field }) => <FontInput {...field} />}
          />
        </Box>
      </Section>

      <Section>
        <Box>
          <UIText as="label" htmlFor="logo" mb="0.4rem" display="block">
            Logo
          </UIText>

          <FileUpload
            label="Logo"
            flowId={currentFlow.id}
            src={currentFlow.logo as File}
          />
        </Box>
      </Section>

      <Section>
        <BlockStack gap="050">
          <Controller
            control={control}
            name="hasProgressBar"
            render={({ field: { value, ...field } }) => (
              <Checkbox
                label="Display progress bar"
                checked={value}
                {...field}
              />
            )}
          />

          <Controller
            control={control}
            name="hasStepIndicator"
            render={({ field: { value, ...field } }) => (
              <Checkbox
                label="Display question indicator"
                checked={value}
                {...field}
              />
            )}
          />
        </BlockStack>
      </Section>
    </>
  );
};
